import { takeEvery, call, put } from 'redux-saga/lib/effects.js';

import { fixturesActions } from '../dispatchers/fixtures.js';
import { apiGet } from '../../services/requests.ts';

function* doFetchSalutations() {
  try {
    yield put(fixturesActions.setFetchingSalutations(true));
    const salutations = yield call(apiGet, '/api/meta-data/users/salutations');
    const sanitizedSalutations = salutations.map((item) => ({
      label: item.salutation,
      value: item.salutation,
    }));
    yield put(fixturesActions.setSalutations(sanitizedSalutations));
    yield put(fixturesActions.setFetchingSalutations(false));
  } catch (e) {
    yield put(fixturesActions.setFetchingSalutations(false));
  }
}

function* doFetchRoles() {
  try {
    yield put(fixturesActions.setFetchingRoles(true));
    const roles = yield call(apiGet, '/api/meta-data/users/roles');
    const sanitizedRoles = roles.map((item) => ({
      label: item.userRoleName,
      value: item.userRoleId,
    }));
    yield put(fixturesActions.setRoles(sanitizedRoles));
    yield put(fixturesActions.setFetchingRoles(false));
  } catch (e) {
    yield put(fixturesActions.setFetchingRoles(false));
  }
}

function* doFetchSubjectAreas() {
  try {
    yield put(fixturesActions.setFetchingSubjectAreas(true));
    const subjectAreas = yield call(apiGet, '/api/meta-data/users/subjectAreas');
    const sanitizedSubjectAreas = subjectAreas.map((item) => ({
      label: item.subjectAreaDisplayName,
      value: item.subjectAreaId,
    }));
    yield put(fixturesActions.setSubjectAreas(sanitizedSubjectAreas));
    yield put(fixturesActions.setFetchingSubjectAreas(false));
  } catch (e) {
    yield put(fixturesActions.setFetchingSubjectAreas(false));
  }
}

export default function* fixturesSaga() {
  yield takeEvery(fixturesActions.fetchSalutations, doFetchSalutations);
  yield takeEvery(fixturesActions.fetchRoles, doFetchRoles);
  yield takeEvery(fixturesActions.fetchSubjectAreas, doFetchSubjectAreas);
}
