import { useState } from 'react';
import axios, { Method, AxiosProgressEvent, CreateAxiosDefaults } from 'axios';

import { API_ENDPOINT } from '@fi/constants';

export const AxiosDefaults: CreateAxiosDefaults = {
  baseURL: API_ENDPOINT,
  timeout: 13000,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'max-age=0',
    Pragma: 'no-cache',
  },
};

const req = axios.create(AxiosDefaults);

req.interceptors.request.use(function (config) {
  const isFormData = !!config.data && config.data instanceof FormData;
  if (isFormData) {
    delete config.headers['Content-Type'];
  }
  return config;
});

interface UserRequestProps {
  url: string;
  method: Method;
  data?: any;
}

type onUploadProgressEventCallback = (event: AxiosProgressEvent) => void;

export const useRequest = ({ url, method, data }: UserRequestProps) => {
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);

  const doListOfRequests = async (requests: UserRequestProps[]) => {
    const promises = requests.map(async (request) => {
      return req({ url: request.url, method: request.method, data: request.data });
    });
    setIsLoading(true);
    try {
      const res = await Promise.allSettled(promises);
      setResponse(res);
      setError(false);
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const doRequest = async () => {
    setIsLoading(true);
    try {
      const res = await req({ url, method, data });
      setResponse(res.data);
      setError(false);
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const doLazyRequest = async (obj: FormData | string, onUploadProgress?: onUploadProgressEventCallback) => {
    setIsLoading(true);
    try {
      const res = await req({ url, method, data: obj, onUploadProgress });
      setError(false);
      return res.data;
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    doRequest,
    doLazyRequest,
    doListOfRequests,
    error,
    isLoading,
    response,
    setResponse,
  };
};
