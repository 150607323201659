import { Typography } from '@fi/typography';

const InitialLoadingScreen = () => {
  return (
    <main>
      <div className="initial-loading-screen">
        <Typography renderAs="span">Loading...</Typography>
      </div>
    </main>
  );
};

export default InitialLoadingScreen;
