import { takeEvery, call, put } from 'redux-saga/lib/effects.js';
import { Link } from 'react-router-dom';

import { savedSearchesActions } from '../dispatchers/savedSearches.js';
import { logActions } from '../dispatchers/log.js';
import { pageActions } from '../dispatchers/page.js';
import { apiDelete, apiPost } from '../../services/requests.ts';
import { getSavedSearchesList, createSavedSearch, markSavedSearchAsSeen, getSavedSearchById } from '../../services/api/user.ts';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { mapSavedSearchOptions } from '@fi/util/mapSavedSearchOptions';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { replaceUrlParam } from '@fi/util/replaceUrlParam';
import { deleteUrlParam } from '@fi/util/deleteUrlParam';
import { PAGE_SIZE, ROUTES, LOCALE, EVENT_NAMES, RELEVANCE_SORTING } from '@fi/constants';

function* doFetchSavedSearches(action) {
  const { payload: { page = 1 } = {} } = action;

  try {
    yield put(savedSearchesActions.setIsLoadingResults(true));

    const savedSearches = yield getSavedSearchesList(PAGE_SIZE, page - 1);

    yield put(savedSearchesActions.loadResultsData(savedSearches));
    yield put(savedSearchesActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(savedSearchesActions.setIsLoadingResults(false));
    const alertOptions = getDefaultAlertOptions('savedSearches', LOCALE.ERRORS.ERROR_OCCURRED_REFRESH);
    yield put(pageActions.createAlert(alertOptions));
  }
}

function* doUpdateSearch(action) {
  const data = action.payload;
  try {
    yield put(savedSearchesActions.setIsSaving(true));
    const newSavedSearch = yield call(apiPost, '/api/user/searches', data);
    yield put(savedSearchesActions.setDeleteModalOpen(false));
    yield put(savedSearchesActions.setEditModalOpen(false));
    yield put(savedSearchesActions.setSaveStatus({ status: true }));
    yield put(savedSearchesActions.updateResultsData(newSavedSearch));
  } catch (e) {
    const alertOptions = getDefaultAlertOptions('savedSearches', LOCALE.ERRORS.ERROR_OCCURRED_REFRESH);
    yield put(pageActions.createAlert(alertOptions));
    yield put(savedSearchesActions.setSaveStatus({ status: false }));
  } finally {
    yield put(savedSearchesActions.setIsSaving(false));
  }
}

function* doSaveSearch(action) {
  const data = action.payload;
  try {
    data.webUri = deleteUrlParam(data.webUri, 'savedSearchId');
    delete data.apiQuery.page;
    delete data.apiQuery.pageSize;
    data.apiQuery = JSON.stringify(data.apiQuery);

    const searchData = yield createSavedSearch(data);
    yield put(savedSearchesActions.setActiveSavedSearch(searchData));
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.SAVE_SEARCH,
        resourceName: 'opportunities',
        searchid: searchData.id,
      }),
    );

    yield put(savedSearchesActions.fetchSavedSearches());

    const alertOptions = {
      type: 'info',
      timeout: 4000,
      context: 'search',
      content: (
        <div>
          Your search has been saved in{' '}
          <Link to={ROUTES.MYFUNDING_SAVED_SEARCHES} className="button-anchor">
            My funding
          </Link>
          {' > '}
          <Link to={ROUTES.MYFUNDING_SAVED_SEARCHES} className="button-anchor">
            Saved searches
          </Link>
        </div>
      ),
    };
    yield put(pageActions.createAlert(alertOptions));
  } catch (e) {
    const alertOptions = getDefaultAlertOptions('search', 'An error has occurred while saving the search, try refreshing the page.');
    yield put(pageActions.createAlert(alertOptions));
  } finally {
    yield put(savedSearchesActions.setSaveModalOpen(false));
    yield put(savedSearchesActions.setOverwriteConfirmModalOpen(false));
  }
}

function* doDeleteSavedSearch(action) {
  const { id } = action.payload;
  const { currentPage } = action.payload;
  let apiUrl = '/api/user/searches/';
  if (id && typeof id === 'number') {
    apiUrl += id;
  }
  try {
    yield put(savedSearchesActions.setIsLoadingResults(true));
    yield call(apiDelete, apiUrl);
    yield put(savedSearchesActions.removeItemFromResults(id));
    yield put(savedSearchesActions.fetchSavedSearches({ page: currentPage }));
    yield put(savedSearchesActions.setDeleteModalOpen(false));
    yield put(savedSearchesActions.setIsLoadingResults(false));
    yield put(savedSearchesActions.setDeleteStatus({ status: true }));
  } catch (e) {
    yield put(savedSearchesActions.setDeleteModalOpen(false));
    yield put(savedSearchesActions.setIsLoadingResults(false));
    yield put(savedSearchesActions.setDeleteStatus({ status: false }));
    const alertOptions = getDefaultAlertOptions('savedSearches', LOCALE.ERRORS.ERROR_OCCURRED_REFRESH);

    yield put(pageActions.createAlert(alertOptions));
  }
}

function* doUnsubscribeFromAlert(action) {
  const { searchId, user, unsubscribeId } = action.payload;
  const apiUrl = `/api/user/searches/notification?searchId=${searchId}&user=${user}&unsubscribeId=${unsubscribeId}`;
  try {
    yield put(savedSearchesActions.setIsUnsubscribingFromAlert(true));
    yield call(apiDelete, apiUrl);
    // This is how the component detects the change
    yield put(savedSearchesActions.setIsUnsubscribingFromAlert(false));
    yield put(savedSearchesActions.setIsUnsubscribedFromAlert(true));
  } catch (e) {
    if (e.status && e.status === 500) {
      const message = 'An unknown error occured. Please refresh the page to try again';
      const alertOptions = getDefaultAlertOptions('unsubscribe', message);
      yield put(pageActions.createAlert(alertOptions));
      yield put(savedSearchesActions.setIsUnsubscribingFromAlert(false));
    } else {
      // This is how the component detects the change
      yield put(savedSearchesActions.setIsUnsubscribingFromAlert(false));
      yield put(savedSearchesActions.setIsUnsubscribedFromAlert(true));
    }
  }
}

function* doFetchSavedSearch(action) {
  const { savedSearchId, history } = action.payload;
  try {
    yield put(savedSearchesActions.setIsLoadingResults(true));

    const activeSavedSearch = yield getSavedSearchById(savedSearchId);
    activeSavedSearch.id = parseInt(savedSearchId, 10);

    yield put(savedSearchesActions.setActiveSavedSearch(activeSavedSearch));
    yield put(savedSearchesActions.fetchSavedSearches());
    const savedSearchOptions = mapSavedSearchOptions(activeSavedSearch);

    const webUriParams = getUrlParameters(savedSearchOptions.webUri);
    // as we removed -lastupdateDate so exsiting saved search should use relevance.
    if (webUriParams.orderBy === '-lastUpdateDate') {
      savedSearchOptions.webUri = replaceUrlParam(savedSearchOptions.webUri, 'orderBy', RELEVANCE_SORTING.value);
    }

    let savedSearchQuery = savedSearchOptions.webUri;

    if (!savedSearchQuery.includes('savedSearchId=')) {
      savedSearchQuery += `&savedSearchId=${savedSearchId}`;
    }
    const urlParams = getUrlParameters(history.location.search);

    if (urlParams && urlParams.page) {
      // load the specific page if it already is setted to url
      savedSearchQuery = replaceUrlParam(savedSearchQuery, 'page', urlParams.page);
    }
    if (urlParams && urlParams.dgcid) {
      // preserve the dgcid params
      savedSearchQuery += `&dgcid=${urlParams.dgcid}`;
    }
    history.replace(savedSearchQuery);
    yield put(savedSearchesActions.setIsLoadingResults(false));
    yield markSavedSearchAsSeen(savedSearchId);
  } catch (e) {
    yield put(savedSearchesActions.setIsLoadingResults(false));
  }
}

export default function* savedSearchesSaga() {
  yield takeEvery(savedSearchesActions.fetchSavedSearches, doFetchSavedSearches);
  yield takeEvery(savedSearchesActions.saveSearch, doSaveSearch);
  yield takeEvery(savedSearchesActions.updateSearch, doUpdateSearch);
  yield takeEvery(savedSearchesActions.deleteSavedSearch, doDeleteSavedSearch);
  yield takeEvery(savedSearchesActions.unsubscribeFromAlert, doUnsubscribeFromAlert);
  yield takeEvery(savedSearchesActions.fetchSavedSearch, doFetchSavedSearch);
}
