import React from 'react';
import classnames from 'classnames';

import { LinkProps } from './LinkInterfaces';

const getLinkProps = ({ className, isExternal, size, href, title, onMouseLeave, onMouseEnter, children, testId }: LinkProps) => {
  const hasChildren = React.Children.count(children) > 0;
  return {
    className: classnames('fca-link', className, {
      'fca-link--hasNoChildren': !hasChildren,
      'fca-link--large': size === 'large',
      'fca-link--small': size === 'small',
    }),

    href,
    title,
    target: isExternal ? '_blank' : '_top',
    onMouseLeave,
    onMouseEnter,
    'data-testid': testId,
  };
};

export default getLinkProps;
