import { takeEvery, takeLatest, call, put, select } from 'redux-saga/lib/effects.js';
import download from 'downloadjs';

import { trackOpportunityActions } from '../dispatchers/trackOpportunity.js';
import { trackModalActions } from '../dispatchers/trackModal.js';
import { opportunitiesActions } from '../dispatchers/opportunities.js';
import { groupsActions } from '../dispatchers/groups.js';
import { pageActions } from '../dispatchers/page.js';
import { logActions } from '../dispatchers/log.js';

import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { alphabeticalSortingCompare } from '@fi/util/alphabeticalSortingCompare';
import { apiGet, apiPost, apiPut, apiDelete } from '../../services/requests.ts';

import { PAGE_SIZE, LOCALE, SEARCH_CATEGORIES, EVENT_NAMES, MAX_SELECTED_ITEMS, API_ROUTES } from '@fi/constants';
import { exportFile } from '../../services/api/exportToFile.js';
import { createOpportunitySearchQueryObject } from '../../query/opportunitySearchQuery.ts';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

const TRACKALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchGroupOpportunities(params) {
  const { groupId, apiUrl, queryObject, isPure } = params;
  if (isPure) {
    queryObject['labelId'] = groupId;
  } else {
    queryObject['groupId'] = groupId;
  }
  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    const groupOportunities = yield call(apiPost, apiUrl, queryObject);
    yield put(trackOpportunityActions.loadOpportunities(groupOportunities));
  } catch (e) {
    // 404 group not found if the group doesn't exist
    if (e.status && e.status === 404) {
      yield put(pageActions.set404());
    } else {
      const alertOptions = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
        timeout: 4000,
      };
      yield put(pageActions.createAlert(alertOptions));
    }
  } finally {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
  }
}

function* doFetchTrackedOpportunities(params) {
  const { apiUrl, queryObject } = params;
  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    const opportunities = yield call(apiPost, apiUrl, queryObject);
    yield put(trackOpportunityActions.loadOpportunities(opportunities));
    yield put(trackOpportunityActions.setAllCardinality(opportunities.hits.total.value));
  } catch (e) {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
  }
}

function* doFetchOpportunities(action) {
  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    const groupId = action && action.payload ? action.payload.groupId : null;
    const isPure = yield select((state) => state.trackOpportunity.isPureIntegrated);
    const searchQuery = action && action.payload ? action.payload.searchQuery : null;
    const queryObject = isPure
      ? {
          orderBy: ['recent'],
          pageSize: PAGE_SIZE,
        }
      : {
          isTracked: true,
          pageSize: PAGE_SIZE,
        };
    const apiUrl = API_ROUTES.opportunities_search;
    if (searchQuery && typeof searchQuery === 'string') {
      const urlParams = getUrlParameters(searchQuery);
      Object.assign(queryObject, urlParams);
    }
    // the api start from page 0
    queryObject['page'] = parseInt(queryObject.page, 10) - 1;
    if (groupId !== null && groupId > -1) {
      // fetch group opportunities
      yield doFetchGroupOpportunities({
        apiUrl,
        groupId,
        queryObject,
        isPure,
      });
    } else {
      // fetch all tracked opportunities
      yield doFetchTrackedOpportunities({
        apiUrl,
        queryObject,
      });
    }
  } catch (e) {
    // we shouldn't break saga
  } finally {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
  }
}

function* doUntrackOpportunity(action) {
  const { opportunityId, origin } = action.payload;
  try {
    yield call(apiDelete, '/api/user/opportunities/', [opportunityId]);
    yield put(
      trackOpportunityActions.setUntrackStatus({
        status: true,
        ids: [opportunityId],
        origin,
      }),
    );
    yield put(groupsActions.fetchList());
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.REMOVE_FROM_MY_LIST,
        opportunityIds: [opportunityId],
      }),
    );
    yield put(opportunitiesActions.setOpportunityIsUnTracked([opportunityId]));
  } catch (e) {
    yield put(
      trackOpportunityActions.setUntrackStatus({
        status: false,
      }),
    );
    yield put(trackModalActions.setIsModalOpen(false));
  }
}

function* doUntrackOpportunities(action) {
  const { ids, origin } = action.payload;
  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    yield call(apiDelete, '/api/user/opportunities/', ids);
    yield put(trackOpportunityActions.deselectAll());
    yield put(trackOpportunityActions.setIsLoadingResults(false));
    yield put(
      trackOpportunityActions.setUntrackStatus({
        status: true,
        ids,
        origin,
      }),
    );
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.REMOVE_FROM_MY_LIST,
        opportunityIds: ids,
      }),
    );
  } catch (e) {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
    yield put(
      trackOpportunityActions.setUntrackStatus({
        status: false,
      }),
    );
  }
}

function* doUnlabelOpportunities(action) {
  const { opportunityIds, labelIds } = action.payload;
  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    yield call(apiPost, '/api/labels/_unassign_opportunities?apiConsumerType=PURE', {
      opportunityIds,
      labelIds,
    });
    yield put(trackOpportunityActions.deselectAll());
    yield put(
      trackOpportunityActions.setUnlabelStatus({
        status: true,
        ids: opportunityIds,
      }),
    );
  } catch (e) {
    const alertOptions = {
      ...getDefaultAlertOptions('send-to-pure', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
      timeout: 4000,
    };
    yield put(pageActions.createAlert(alertOptions));
    yield put(
      trackOpportunityActions.setUnlabelStatus({
        status: false,
      }),
    );
  } finally {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
  }
}

function* doSelectAllSearchResult(action) {
  const groupId = action && action.payload ? action.payload.groupId : null;
  const isPure = yield select((state) => state.trackOpportunity.isPureIntegrated);

  try {
    yield put(trackOpportunityActions.setIsLoadingResults(true));
    const searchQuery = yield select((state) => state.trackOpportunity.searchQuery);
    const apiUrl = '/api/opportunities/ids';
    const apiQueryOptions = {
      status: null,
      scope: SEARCH_CATEGORIES.OPPORTUNITIES,
    };
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;

    if (isPure) {
      // Note: Pure opportunities list do not have a sort options dropdown in the toolbar, hence appending the default order for pure.
      queryObject['orderBy'] = ['recent'];
    } else {
      queryObject['isTracked'] = true;
    }

    if (groupId !== null && groupId > -1) {
      queryObject[isPure ? 'labelId' : 'groupId'] = groupId;
    }
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(trackOpportunityActions.setSelectedItems(ids));
    yield put(trackOpportunityActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(trackOpportunityActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(TRACKALERTOPTIONS));
  }
}

function* doExportFile(action) {
  try {
    yield put(trackOpportunityActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);

    yield put(trackOpportunityActions.exportFileFailed(false));
    yield put(trackOpportunityActions.isFileExporting(false));
  } catch (e) {
    yield put(trackOpportunityActions.exportFileFailed(true));
    yield put(trackOpportunityActions.isFileExporting(false));
  }
}

function* doRenameGroup(group) {
  const apiGroupUrl = `/api/groups/${group.id}`;
  yield call(apiPut, apiGroupUrl, { name: group.name });
  // Update the current store list
  const groups = yield select((state) => state.groups.list);
  const ungrouped = groups.find((item) => item.id === 0);
  const filteredGroups = groups.filter((item) => item.id !== 0);
  const index = filteredGroups.findIndex((item) => item.id === group.id);
  if (index > -1) {
    const oldGroup = filteredGroups[index];
    // replace the group with the updated one
    filteredGroups[index] = {
      ...oldGroup,
      ...group,
    };
  }
  // sort groups alphabetically
  filteredGroups.sort(alphabeticalSortingCompare('name'));
  // put ungrouped at the top of the list
  if (ungrouped) {
    filteredGroups.unshift(ungrouped);
  }
  yield put(
    groupsActions.setList({
      groups: filteredGroups,
    }),
  );
}

function* doSetNewGroupName(action) {
  const { name, id } = action.payload;
  try {
    yield put(trackOpportunityActions.setIsSettingNewGroupName(true));
    const updatedGroup = {
      name,
      id,
    };
    yield doRenameGroup(updatedGroup);
    yield put(pageActions.setPageTitle(name));
    yield put(
      trackOpportunityActions.setRenameGroupStatus({
        status: true,
      }),
    );
  } catch (e) {
    yield put(
      trackOpportunityActions.setRenameGroupStatus({
        status: false,
      }),
    );
  } finally {
    yield put(trackOpportunityActions.setIsSettingNewGroupName(false));
  }
}

function* doShareGroup(action) {
  const { shareData } = action.payload;
  try {
    yield put(trackOpportunityActions.setIsSharingInProgress(true));
    yield call(apiPost, '/api/user/share/groups', shareData);
    yield put(trackOpportunityActions.setSharingStatus({ status: true }));
    yield put(trackOpportunityActions.setShareGroupModalIsOpen(false));
  } catch (e) {
    const alertOptions = {
      ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
      timeout: 4000,
    };
    yield put(pageActions.createAlert(alertOptions));
    yield put(
      trackOpportunityActions.setSharingStatus({
        status: false,
      }),
    );
  } finally {
    yield put(trackOpportunityActions.setIsSharingInProgress(false));
  }
}

function* fetchSharedEmailsByGroup(action) {
  const { groupId } = action.payload;
  try {
    const sharedList = yield call(apiGet, `/api/user/share/groups/${groupId}`);
    yield put(trackOpportunityActions.setSharedList(sharedList));
  } catch (e) {
    yield put(trackOpportunityActions.setSharedList([]));
  }
}

export default function* trackOpportunitySaga() {
  yield takeLatest(trackOpportunityActions.fetchTrackedOpp, doFetchOpportunities);
  yield takeEvery(trackOpportunityActions.untrackOpportunity, doUntrackOpportunity);
  yield takeEvery(trackOpportunityActions.untrackOpportunities, doUntrackOpportunities);
  yield takeEvery(trackOpportunityActions.unlabelOpportunities, doUnlabelOpportunities);
  yield takeEvery(trackOpportunityActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(trackOpportunityActions.exportFile, doExportFile);
  yield takeLatest(trackOpportunityActions.setNewGroupName, doSetNewGroupName);
  yield takeLatest(trackOpportunityActions.shareGroup, doShareGroup);
  yield takeLatest(trackOpportunityActions.fetchSharedEmailsByGroup, fetchSharedEmailsByGroup);
}
