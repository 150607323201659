export const formatStringToSnakeCase = (s: string) => {
  const maxLength = 28;
  s = s.replace(/[^0-9a-z ]/gi, '');
  s = s.replace(/ +/gi, '_');
  if (s.length > maxLength) {
    s = s.substring(0, maxLength);
  }
  s = s.replace(/_$/gi, '');
  s = s.toLowerCase();
  return s;
};
