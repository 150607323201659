import { createAction, createReducer } from 'redux-act';

export const fixturesActions = {
  fetchSalutations: createAction('fetch salutations'),
  setFetchingSalutations: createAction('set fetching salutations'),
  setSalutations: createAction('set salutations'),
  setCountries: createAction('set countries'),
  setCountriesMap: createAction('set countries map'),
  fetchRoles: createAction('fetch roles'),
  fetchSubjectAreas: createAction('fetch subject areas'),
  setFetchingRoles: createAction('set fetching roles'),
  setFetchingSubjectAreas: createAction('set fetching subject areas'),
  setRoles: createAction('set roles'),
  setSubjectAreas: createAction('set subject areas'),
};

const setFetchingSalutations = (state, isFetchingSalutations) => ({
  ...state,
  isFetchingSalutations,
});
const setSalutations = (state, salutations) => ({
  ...state,
  salutations,
});

const setFetchingRoles = (state, isFetchingRoles) => ({
  ...state,
  isFetchingRoles,
});

const setRoles = (state, roles) => ({
  ...state,
  roles,
});

const setFetchingSubjectAreas = (state, isFetchingSubjectAreas) => ({
  ...state,
  isFetchingSubjectAreas,
});

const setSubjectAreas = (state, subjectAreas) => ({
  ...state,
  subjectAreas,
});

const setCountries = (state, countries) => ({
  ...state,
  countries,
});

const setCountriesMap = (state, countriesMap) => ({
  ...state,
  countriesMap,
});

/* Reducers */
export const fixturesReducer = createReducer(
  {
    [fixturesActions.setFetchingSalutations]: setFetchingSalutations,
    [fixturesActions.setSalutations]: setSalutations,
    [fixturesActions.setFetchingRoles]: setFetchingRoles,
    [fixturesActions.setRoles]: setRoles,
    [fixturesActions.setFetchingSubjectAreas]: setFetchingSubjectAreas,
    [fixturesActions.setSubjectAreas]: setSubjectAreas,
    [fixturesActions.setCountries]: setCountries,
    [fixturesActions.setCountriesMap]: setCountriesMap,
  },
  {
    isFetchingSalutations: false,
    salutations: [],
    roles: [],
    subjectAreas: [],
    countries: {
      items: [],
    },
    countriesMap: null,
  },
);
