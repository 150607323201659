import { createAction, createReducer } from 'redux-act';

export const shareActions = {
  shareOpportunitiesByEmail: createAction('Share opportunities by email'),
  setIsSharing: createAction('Set is sharing flag'),
  setIsShared: createAction('Set is shared flag'),
  toggleModal: createAction('Toggle modal'),
  toggleNewModal: createAction('Toggle new modal'),
  closeModal: createAction('Close share opportunities modal'),
  loadSharedList: createAction('Load shared email list'),
  fetchSharedList: createAction('Fetching list', (payload) => payload),
  setSelectedOpportunities: createAction('Set list of selected opportunities for share'),
  setError: createAction('Set error flag'),
};

const initialState = {
  isSharingInProgress: false,
  isShared: false,
  isModalOpen: false,
  isNewModalOpen: false,
  sharedList: [],
  selectedOpportunities: [],
  error: false,
};

const setIsSharing = (state, isSharingInProgress) => ({
  ...state,
  isSharingInProgress,
});

const setIsShared = (state, isShared) => ({
  ...state,
  isShared,
});

const loadSharedList = (state, sharedList) => ({
  ...state,
  sharedList,
});

const toggleModal = (state) => ({
  ...state,
  isModalOpen: !state.isModalOpen,
  sharedList: [],
});

const toggleNewModal = (state, selectedOppIds) => ({
  ...state,
  isNewModalOpen: !state.isNewModalOpen,
  sharedList: [],
  selectedOpportunities: selectedOppIds,
});

const setSelectedOpportunities = (state, selectedOpportunities) => ({
  ...state,
  selectedOpportunities,
});

const closeModal = (state) => ({
  ...state,
  isModalOpen: false,
  isNewModalOpen: false,
});

const setError = (state, error) => ({
  ...state,
  error,
});

/* Reducers */
export const shareReducer = createReducer(
  {
    [shareActions.setIsSharing]: setIsSharing,
    [shareActions.setIsShared]: setIsShared,
    [shareActions.toggleModal]: toggleModal,
    [shareActions.toggleNewModal]: toggleNewModal,
    [shareActions.loadSharedList]: loadSharedList,
    [shareActions.setSelectedOpportunities]: setSelectedOpportunities,
    [shareActions.closeModal]: closeModal,
    [shareActions.setError]: setError,
  },
  initialState,
);
