import { apiGet, apiPost } from '../requests.ts';

function getFunderNote(funderId: string) {
  return apiGet(`/api/funders/${funderId}/note`);
}

function getFunderNotes(searchQuery = ''): Promise<any> {
  return apiGet(`/api/account/funder-notes${searchQuery}`);
}

function getFunders(searchQuery = {}): Promise<any> {
  return apiPost(`/api/funders`, searchQuery);
}

export { getFunderNote, getFunderNotes, getFunders };
