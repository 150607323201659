import * as React from 'react';

function SvgCheck(props: any) {
  return (
    <svg viewBox="0 0 88 128" height={24} width={17} {...props}>
      <path d="M0 58.08l7.86-6.18 29.18 37.14 42.24-67.7 8.48 5.3-49.78 79.8z" />
    </svg>
  );
}

export default SvgCheck;
