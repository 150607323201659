import { takeEvery, call, put } from 'redux-saga/lib/effects.js';

import { authActions } from '../dispatchers/auth.js';
import { userActions } from '../dispatchers/user.js';
import { pageActions } from '../dispatchers/page.js';
import { apiPost, apiGet, apiPut } from '../../services/requests.ts';

function* doSendAdminInvitation(action) {
  const { email } = action.payload;
  try {
    yield put(authActions.setAdminInvitationIsLoading(true));
    yield call(apiPost, '/api/account/user-roles/_invite-admin', { email });
    yield put(
      authActions.setAdminInvitationStatus({
        isSuccess: true,
      }),
    );
    yield put(
      pageActions.createAlert({
        type: 'info',
        content: `An activation email was sent to ${email}`,
        timeout: 8000,
        context: 'admin-activation',
      }),
    );
  } catch (e) {
    yield put(
      authActions.setAdminInvitationStatus({
        isSuccess: false,
      }),
    );
    yield put(
      pageActions.createAlert({
        type: 'warning',
        content: 'Something went wrong… Try again later.',
        timeout: 4000,
        context: 'admin-activation',
      }),
    );
  } finally {
    yield put(authActions.setAdminInvitationIsLoading(false));
  }
}

function* getFirstAdminContact() {
  try {
    const contacts = yield call(apiGet, '/api/account/user-roles/_first?role=ADMIN');
    if (contacts && contacts.user) {
      return {
        name: `${contacts.user.firstName} ${contacts.user.lastName}`,
        email: contacts.user.email,
      };
    }
    return null;
  } catch (e) {
    return null;
  }
}

function* getContactDetails() {
  try {
    const contacts = yield call(apiGet, '/api/account/contact-details');
    return contacts;
  } catch (e) {
    return null;
  }
}

function* doAcceptAdminInvitation(userData) {
  try {
    yield put(authActions.setAdminInvitationIsLoading(true));
    yield call(apiPut, '/api/account/user-roles/_accept-admin-invitation');
    // update the current user role
    yield put(
      userActions.setUserData({
        ...userData,
        role: 'ADMIN',
      }),
    );
    yield put(
      authActions.setAdminInvitationStatus({
        isSuccess: true,
        code: 200,
      }),
    );
  } catch (e) {
    if (e.status === 400) {
      let contactPerson = yield call(getContactDetails);
      if (!contactPerson) {
        contactPerson = yield call(getFirstAdminContact);
      }
      yield put(
        authActions.setAdminInvitationStatus({
          isSuccess: false,
          code: e.status,
          contactPerson,
        }),
      );
    } else {
      yield put(
        authActions.setAdminInvitationStatus({
          isSuccess: false,
          code: e.status,
        }),
      );
    }
  } finally {
    yield put(authActions.setAdminInvitationIsLoading(false));
  }
}

export default function* authSaga() {
  yield takeEvery(authActions.sendAdminInvitation, doSendAdminInvitation);
  yield takeEvery(authActions.acceptAdminInvitation, doAcceptAdminInvitation);
}
