import { combineReducers } from 'redux';

import { opportunitiesReducer } from './dispatchers/opportunities.js';
import { awardsReducer } from './dispatchers/awards.js';
import { fundersReducer } from './dispatchers/funders.js';
import { authReducer } from './dispatchers/auth.js';
import { userReducer } from './dispatchers/user.js';
import { fixturesReducer } from './dispatchers/fixtures.js';
import { savedSearchesReducer } from './dispatchers/savedSearches.js';
import { pageReducer } from './dispatchers/page.js';
import { trackOpportunityReducer } from './dispatchers/trackOpportunity.js';
import { opportunitiesAlertReducer } from './dispatchers/opportunitiesAlert.js';
import { shareReducer } from './dispatchers/share.js';
import { groupsReducer } from './dispatchers/groups.js';
import { labelsReducer } from './dispatchers/labels.js';
import { publicGroupReducer } from './dispatchers/publicGroup.js';
import { trackModalReducer } from './dispatchers/trackModal.js';
import { recommendationsReducer } from './dispatchers/recommendations.js';
import { recommendedOpportunitiesReducer } from './dispatchers/recommendedOpportunities.js';
import { recommendationModalReducer } from './dispatchers/recommendationModal.js';
import { usersRolesReducer } from './dispatchers/usersRoles.js';
import { opportunityNoteModalReducer } from './dispatchers/opportunityNoteModal.js';
import { opportunityNotesReducer } from './dispatchers/opportunityNotes.js';
import { funderNotesReducer } from './dispatchers/funderNotes.js';
import { funderNoteModalReducer } from './dispatchers/funderNoteModal.js';
import { modalReducer } from './dispatchers/modal.js';
import { internalOpportunitiesReducer } from './dispatchers/internalOpportunities.js';

export default combineReducers({
  opportunities: opportunitiesReducer,
  opportunitiesAlert: opportunitiesAlertReducer,
  awards: awardsReducer,
  funders: fundersReducer,
  auth: authReducer,
  user: userReducer,
  fixtures: fixturesReducer,
  savedSearches: savedSearchesReducer,
  page: pageReducer,
  trackOpportunity: trackOpportunityReducer,
  share: shareReducer,
  groups: groupsReducer,
  labels: labelsReducer,
  publicGroup: publicGroupReducer,
  trackModal: trackModalReducer,
  recommendations: recommendationsReducer,
  recommendationModal: recommendationModalReducer,
  recommendedOpportunities: recommendedOpportunitiesReducer,
  usersRoles: usersRolesReducer,
  opportunityNotes: opportunityNotesReducer,
  opportunityNoteModal: opportunityNoteModalReducer,
  funderNotes: funderNotesReducer,
  funderNoteModal: funderNoteModalReducer,
  modal: modalReducer,
  internalOpportunities: internalOpportunitiesReducer,
});
