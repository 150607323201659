import { createAction, createReducer } from 'redux-act';
import { opportunityNoteModalActions } from './opportunityNoteModal.js';
import { funderNoteModalActions } from './funderNoteModal.js';
import { shareActions } from './share.js';

export const modalActions = {
  openModal: createAction('show modal'),
  closeModal: createAction('hide modal'),
};

const initialState = {
  openModalType: null,
};

const openModal = (state, { openModalType, modalProps }) => ({
  ...state,
  openModalType,
  modalProps,
});

const closeModal = () => initialState;

export const modalReducer = createReducer(
  {
    [modalActions.openModal]: openModal,
    [modalActions.closeModal]: closeModal,

    // Listed to closeModal actions from other dispatchers
    [opportunityNoteModalActions.closeModal]: closeModal,
    [funderNoteModalActions.closeModal]: closeModal,
    [shareActions.closeModal]: closeModal,
  },
  initialState,
);
