import React from 'react';
import DeleteIcon from '../SVGIcons/Delete';

interface SnackBarCloseButtonProps {
  closeToast?: () => void;
}

const SnackBarCloseButton = ({ closeToast }: SnackBarCloseButtonProps) => (
  <button onClick={closeToast} className="fca-snackbar__close-button">
    <DeleteIcon />
  </button>
);

export default SnackBarCloseButton;
