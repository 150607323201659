export class NumberUtils {
  static getCommaFormattedNumber(val) {
    if (val !== null && val !== undefined) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
      }
      return val;
    }
    return '';
  }

  static formatToFixed(number) {
    return NumberUtils.getCommaFormattedNumber(Number(number).toFixed());
  }

  static commaSeparatedStringToNumber(val) {
    if (typeof val === 'string' || val instanceof String) {
      if (val === '') {
        return 0;
      }
      return parseInt(val.trim().replace(/,/g, ''), 10);
    }
    return 0;
  }

  static toNumber(val) {
    return NumberUtils.commaSeparatedStringToNumber(val);
  }

  static maskedInputValue(initialValue) {
    if (initialValue) {
      let value = initialValue;
      value = value.toString();
      if (!value) {
        return '';
      }
      const digits = value.match(/\d/g) || ['0'];

      if (digits.length === 1) {
        digits.unshift('0');
      }
      return NumberUtils.formatToFixed(Number(digits.join('')));
    }
    return '';
  }
}
