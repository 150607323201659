// guessing type, feel free to refactor
interface ActiveSavedSearch {
  name: string;
  totalDataCount: number;
  newDataCount: number;
  apiQuery: string;
  webUri: string;
}

// guessing type, feel free to refactor
interface SavedSearchOptions {
  name: string;
  totalOpp: number;
  totalNewOpp: number;
  apiQuery: string;
  webUri: string;
  lastRunDate?: string;
  lastRunDay?: string;
}

export const mapSavedSearchOptions = (item: ActiveSavedSearch) => {
  const savedSearchOptions: SavedSearchOptions = {
    name: item.name,
    totalOpp: item.totalDataCount,
    totalNewOpp: item.newDataCount,
    apiQuery: item.apiQuery,
    webUri: item.webUri,
  };
  return savedSearchOptions;
};
