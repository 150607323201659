/**
 * Deletes the key-value pair from the object where the value matches the provided value.
 * @param obj - The input object from which to delete the key-value pair.
 * @param valueToDelete - The value to be matched for deletion.
 * @returns Returns the modified object after deleting the key-value pair.
 */
export function deleteKeyValuePairByValue(obj: Record<number, number>, valueToDelete: number) {
  for (const key in obj) {
    if (obj[key] === valueToDelete) {
      delete obj[key];
      break; // Assume there is only one matching value
    }
  }
  return obj;
}
