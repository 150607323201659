import { createAction, createReducer } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const groupsActions = {
  setIsLoadingList: createAction('is loading list'),
  setFetchingListStatus: createAction('set fetching list status success or error'),
  fetchList: createAction('fetch list of groups'),
  setList: createAction('set all groups list'),
  deleteGroup: createAction('delete a group'),
  setIsDeletingGroup: createAction('deleting group'),
  renameGroup: createAction('renaming a group'),
  setDeleteGroupStatus: createAction('set Delete Group Status success or error'),

  /* delete confirmation modal */
  openDeleteConfirmationModal: createAction('open delete confirmation modal'),
  closeDeleteConfirmationModal: createAction('close delete confirmation modal'),
  setStatusDeleteConfirmationModal: createAction('open deleting status for confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while group is deleting'),
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
};

const initialState = {
  isLoadingList: false,
  fetchingListStatus: null,
  list: [],
  isDeleting: false,
  deleteGroupStatus: null,
  searchQuery: SEARCH_QUERY_DEFAULT,
  deleteConfirmationModal: {
    selectedGroup: null,
    isDeleting: false,
    status: null,
    isOpen: false,
  },
};

const setIsLoadingList = (state, isLoadingList) => ({
  ...state,
  isLoadingList,
});

const setFetchingListStatus = (state, fetchingListStatus) => ({
  ...state,
  fetchingListStatus,
});

const setIsDeletingGroup = (state, isDeleting) => ({
  ...state,
  isDeleting,
});

const setDeleteGroupStatus = (state, deleteGroupStatus) => ({
  ...state,
  deleteGroupStatus,
});

const setList = (state, data) => {
  const autocomplete = data.groups
    .map((group) => ({ value: group.name, key: group.id }))
    .filter((group) => group.key !== 0 && group.key !== -1);
  return {
    ...state,
    list: data.groups,
    autocomplete,
  };
};

const openDeleteConfirmationModal = (state, selectedGroup) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedGroup,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    selectedGroup: null,
    status: null,
    isDeleting: false,
    isOpen: false,
  },
});

const setStatusDeleteConfirmationModal = (state, status) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    status,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

/* Reducers */
export const groupsReducer = createReducer(
  {
    [groupsActions.setIsLoadingList]: setIsLoadingList,
    [groupsActions.setFetchingListStatus]: setFetchingListStatus,
    [groupsActions.setList]: setList,
    [groupsActions.setIsDeletingGroup]: setIsDeletingGroup,
    [groupsActions.setDeleteGroupStatus]: setDeleteGroupStatus,

    /* delete confirmation modal */
    [groupsActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [groupsActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [groupsActions.setStatusDeleteConfirmationModal]: setStatusDeleteConfirmationModal,
    [groupsActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
  },
  initialState,
);
