import { Funder } from '../../connectors/Pages/Details/Funder/interfaces.ts';
import { apiGet } from '../requests.ts';

interface AutocompleteResponse<T> {
  suggestions: T[];
}

function getFunders(phrase: string): Promise<AutocompleteResponse<Funder>> {
  return apiGet(`/api/autocompletion/funders?text=${encodeURIComponent(phrase)}`);
}

function getKeywords(phrase: string): Promise<AutocompleteResponse<string>> {
  return apiGet(`/api/autocompletion/keywords?text=${encodeURIComponent(phrase)}`);
}

export { getFunders, getKeywords };
