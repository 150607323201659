import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import classNames from 'classnames';
import SnackBarCloseButton from './SnackBarCloseButton';

const SnackBarContainer: React.FC<ToastContainerProps> = (props) => (
  <ToastContainer {...props} className={classNames('fca-snackbar', props.className)} closeButton={<SnackBarCloseButton />} />
);

export default SnackBarContainer;
