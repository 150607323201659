import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/lib/effects.js';
import download from 'downloadjs';

import { modalActions } from '../dispatchers/modal.js';
import { opportunityNotesActions } from '../dispatchers/opportunityNotes.js';
import { opportunityNoteModalActions } from '../dispatchers/opportunityNoteModal.js';
import { pageActions } from '../dispatchers/page.js';
import { apiGet, apiDelete, apiPost } from '../../services/requests.ts';
import { getOpportunityNote, getOpportunityNotes } from '../../services/api/opportunities.ts';
import { PAGE_SIZE, SEARCH_CATEGORIES, LOCALE, MAX_SELECTED_ITEMS, MODALS } from '@fi/constants';

import { replaceUrlParam } from '@fi/util/replaceUrlParam';
import { QueryManager } from '@fi/util/QueryManager';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { displayGeneralErrorAlert } from './alerts.js';
import { opportunitiesActions } from '../dispatchers/opportunities.js';
import { exportFile } from '../../services/api/exportToFile.js';
import { showNotification } from '@fi/components/Notify';

function* doFetchOpportunityNotesList(action) {
  try {
    yield put(opportunityNotesActions.setIsLoadingResults(true));
    const searchQuery = action && action.payload ? action.payload.searchQuery : null;
    const constantParams = `pageSize=${PAGE_SIZE}`;
    let query;
    if (searchQuery && typeof searchQuery === 'string') {
      query = `${searchQuery}&${constantParams}`;
    } else {
      query = `?${constantParams}`;
    }
    const urlParams = getUrlParameters(query);
    query = replaceUrlParam(query, 'page', parseInt(urlParams.page, 10) - 1);

    const opportunityNotes = yield getOpportunityNotes(query);

    yield put(opportunityNotesActions.loadOpportunities(opportunityNotes));
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'notes');
  } finally {
    yield put(opportunityNotesActions.setIsLoadingResults(false));
  }
}

function* fetchNotesForOpportunity(opportunityId, throw404Error = false) {
  try {
    const opportunityNote = yield getOpportunityNote(opportunityId);

    if (throw404Error && !opportunityNote) {
      // Any race condition in the editor page is prevented by this error
      const error = new Error('Opportunity notes do not exist');
      error.name = 'Notes404';

      throw error;
    }

    yield put(
      opportunitiesActions.setOpportunityNote({
        opportunityId,
        opportunityNote,
      }),
    );
  } catch (e) {
    if (e.name === 'Notes404') {
      throw e;
    }

    yield call(displayGeneralErrorAlert, 'notes');
  }
}

function* doSelectAllSearchResult() {
  try {
    yield put(opportunityNotesActions.setIsLoadingResults(true));
    const searchQuery = yield select((state) => state.opportunityNotes.searchQuery);
    const apiUrl = '/api/account/opportunity-notes/ids';
    const apiQueryOptions = {
      status: null,
      scope: SEARCH_CATEGORIES.OPPORTUNITY_NOTES,
    };
    let queryString = QueryManager.generateApiQuery(searchQuery, apiQueryOptions);
    queryString = replaceUrlParam(queryString, 'pageSize', MAX_SELECTED_ITEMS);
    queryString = replaceUrlParam(queryString, 'page', 0);
    const ids = yield call(apiGet, `${apiUrl}${queryString}`);
    yield put(opportunityNotesActions.setSelectedItems(ids));
    yield put(opportunityNotesActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(displayGeneralErrorAlert('search'));
  } finally {
    yield put(opportunityNotesActions.setIsLoadingResults(false));
  }
}

function* doDeleteOpportunityNote(action) {
  const { opportunityIds } = action.payload;
  try {
    yield put(opportunityNotesActions.setIsDeletingConfirmationModal(true));
    const alertMsg = 'Opportunity notes have been deleted.';
    if (opportunityIds.length > 1) {
      yield call(apiPost, '/api/account/opportunity-notes/_bulk-delete', opportunityIds);
    } else {
      yield call(apiDelete, `/api/opportunities/${opportunityIds[0]}/note`);
    }
    const alertOptions = {
      type: 'info',
      timeout: 4000,
      context: 'notes',
      content: alertMsg,
    };
    yield put(pageActions.createAlert(alertOptions));
    yield put(opportunityNotesActions.deselectAll());
    yield put(opportunityNotesActions.removeNoteFromStore(opportunityIds));
    yield put(
      opportunityNotesActions.setDeleteNoteStatus({
        success: true,
      }),
    );
    yield call(showNotification, { message: 'Opportunity notes have been deleted successfully.' });
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.DELETE_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield put(displayGeneralErrorAlert('search'));
    }

    yield put(
      opportunityNotesActions.setDeleteNoteStatus({
        success: false,
      }),
    );
  } finally {
    yield put(opportunityNotesActions.setIsDeletingConfirmationModal(false));
  }
}

function* saveOpportunityNote(noteData) {
  try {
    yield put(opportunityNoteModalActions.setIsSaving(true));
    const selectedOpportunities = yield select((state) => state.opportunityNoteModal.selectedOpportunities);

    const payload = {
      opportunityIds: selectedOpportunities,
      ...noteData.payload,
    };

    yield call(apiPost, '/api/account/opportunity-notes', payload);

    yield put(opportunityNoteModalActions.setNoteWasSavedSuccessfully(true));

    // Dispatch action that notes for opportunities were added
    yield put(opportunityNoteModalActions.updateOpportunitiesWithNotes(selectedOpportunities));

    yield put(
      pageActions.createAlert({
        type: 'info',
        timeout: 4000,
        context: 'notes',
        content: 'Opportunity notes have been saved.',
      }),
    );
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'notes');
  } finally {
    yield put(opportunityNoteModalActions.closeModal());
  }
}

function* checkOpportunitiesWithNotes(opportunitiesIds) {
  try {
    const opportunitiesNotesCount = yield call(apiPost, '/api/account/opportunity-notes/_count', opportunitiesIds);

    const amountOfOpportunitiesWithNotes = opportunitiesNotesCount && opportunitiesNotesCount.count ? opportunitiesNotesCount.count : null;
    yield put(opportunityNoteModalActions.setOpportunitiesWithNotesAmount(amountOfOpportunitiesWithNotes));
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'notes');
  }
}

function* openNewOpportunityNoteModal(data) {
  yield call(openOpportunityNoteModal, { ...data, modalType: 'new' });
}

function* openOpportunityNoteModal(data) {
  const { opportunitiesIds, showAlertOn404 = false } = data.payload;

  if (!opportunitiesIds || opportunitiesIds.length === 0) {
    yield call(displayGeneralErrorAlert, 'notes');

    return;
  }

  try {
    yield put(opportunityNoteModalActions.setIsFetchingOpportunityNote(opportunitiesIds));

    if (opportunitiesIds.length === 1) {
      yield call(fetchNotesForOpportunity, opportunitiesIds[0], showAlertOn404);
    } else {
      yield call(checkOpportunitiesWithNotes, opportunitiesIds);
    }

    yield all([
      yield put(opportunityNoteModalActions.setOpportunitiesIds(opportunitiesIds)),
      yield put(
        modalActions.openModal({
          openModalType: data.modalType === 'new' ? MODALS.NEW_OPPORTUNITY_NOTE_MODAL : MODALS.OPPORTUNITY_NOTE_MODAL,
        }),
      ),
    ]);
  } catch (e) {
    if (e.name === 'Notes404' && showAlertOn404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.EDIT_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield call(displayGeneralErrorAlert, 'notes');
    }
  } finally {
    yield put(opportunityNoteModalActions.setIsFetchingOpportunityNote(null));
  }
}

function* doExportFile(action) {
  try {
    yield put(opportunityNotesActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);
    yield put(opportunityNotesActions.exportFileFailed(false));
    yield put(opportunityNotesActions.isFileExporting(false));
  } catch (e) {
    yield put(opportunityNotesActions.exportFileFailed(true));
    yield put(opportunityNotesActions.isFileExporting(false));
  }
}

export default function* opportunityNotesSaga() {
  yield takeLatest(opportunityNotesActions.fetchOpportunityNotes, doFetchOpportunityNotesList);
  yield takeEvery(opportunityNoteModalActions.saveOpportunityNote, saveOpportunityNote);
  yield takeLatest(opportunityNoteModalActions.openOpportunityNoteModal, openOpportunityNoteModal);
  yield takeLatest(opportunityNoteModalActions.openNewOpportunityNoteModal, openNewOpportunityNoteModal);
  yield takeEvery(opportunityNotesActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(opportunityNotesActions.deleteNotes, doDeleteOpportunityNote);
  yield takeLatest(opportunityNotesActions.exportFile, doExportFile);
}
