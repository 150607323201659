import { getUrlParameters } from './getUrlParameters.js';
import { replaceUrlParam } from './replaceUrlParam.ts';

export const deleteUrlParam = (url, paramName, value) => {
  if (value) {
    const params = getUrlParameters(url);
    const splitValues = params[paramName].split(',');
    if (splitValues.length > 1) {
      const filteredValues = splitValues.filter((item) => {
        const sanitizedItem = typeof value === 'number' ? parseInt(item, 10) : item;
        return sanitizedItem !== value;
      });
      const newValues = filteredValues.join(',');
      return replaceUrlParam(url, paramName, newValues);
    }
  }
  let newUrl = url;
  if (newUrl.charAt(0) === '?') {
    newUrl = newUrl.substr(1);
  }
  const pattern = `&${paramName}(=[^&]*)?(?=&|$)|^${paramName}(=[^&]*)?(&|$)`;
  const regExp = new RegExp(pattern);
  newUrl = newUrl.replace(regExp, '');
  return `?${newUrl}`;
};
