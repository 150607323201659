import { compareAlphabetically } from './compareAlphabetically.ts';

export const createResearchAreas = (classifications) => {
  const map = {};
  classifications.forEach((item) => {
    if (item.parentId === 0) {
      if (map[item.id]) {
        map[item.id].parent = item;
      } else {
        map[item.id] = {
          parent: item,
          children: [],
        };
      }
    } else {
      const childItem = Object.assign({}, item);
      if (!map[item.parentId]) {
        map[item.parentId] = {
          parent: {},
          children: [childItem],
        };
      } else {
        map[item.parentId].children.push(childItem);
      }
    }
  });

  const result = [];
  Object.keys(map).forEach((key) => {
    result.push(map[key]);
    map[key].topLevelDescription = 'All in this category';
    map[key].children.sort((a, b) => compareAlphabetically(a.description, b.description));
  });
  result.sort((a, b) => compareAlphabetically(a.parent.description, b.parent.description));
  return result;
};
