import { matchPath } from 'react-router-dom';

import { NumberUtils } from '@fi/util/NumberUtils';

export const API_ENDPOINT = '/';

export const PAGE_DESCRIPTIONS = {
  AWARDS_ANALYTICS: 'Analytics awards',
};

export const PAGE_SIZE = 20;
export const TOTAL_OF_PAGES = 500;
export const MAX_RESULTS = 10000;
export const MAX_SELECTED_ITEMS = 10000;
export const MAX_AMOUNT = 9000000000000000;

export type SearchTargetTabs = 'awards' | 'funders' | 'opportunities';
export const SEARCHBOX_TARGETS = {
  AWARDED_GRANTS: 'awards',
  FUNDERS: 'funders',
  OPPORTUNITIES: 'opportunities',
} as const; // this lets typescript infer types from key/value

export const EVENT_NAMES = {
  CHANGE_PAGE: 'CHANGE_PAGE',
  SAVED_SEARCH_MODAL: 'SAVED_SEARCH_MODAL',
  EXPORT_FILE: 'EXPORT_FILE',
  CLICK_ANNOUNCEMENT_LINK: 'CLICK_ANNOUNCEMENT_LINK',
  RUN_SEARCH: 'RUN_SEARCH',
  CLICK_ON_SEARCH_RESULT: 'CLICK_ON_SEARCH_RESULT',
  SAVE_SEARCH: 'SAVE_SEARCH',
  CONTENT_SHARE: 'CONTENT_SHARE',
  SAVE_TO_LIST: 'SAVE_TO_LIST',
  REMOVE_FROM_MY_LIST: 'REMOVE_FROM_MY_LIST',
  ADD_INTERNAL_OPPORTUNITY: 'ADD_INTERNAL_OPPORTUNITY',
  EDIT_INTERNAL_OPPORTUNITY: 'EDIT_INTERNAL_OPPORTUNITY',
  DELETE_INTERNAL_OPPORTUNITY: 'DELETE_INTERNAL_OPPORTUNITY',
  CLICK_SUGGESTION: 'CLICK_SUGGESTION',
  CLICK_SIMILAR_OPPORTUNITY: 'CLICK_SIMILAR_OPPORTUNITY',
  CONTENT_EXPORT_START_PURE: 'CONTENT_EXPORT_START_PURE',
  CONTENT_EXPORTED_TO_PURE: 'CONTENT_EXPORTED_TO_PURE',
};

export const LOCALE = {
  ERRORS: {
    GENERAL: 'Oops! something went wrong. Please try again later.',
    FILL_IN_REQUIRED: 'Please fill in all the required fields.',
    ACCEPT_TERMS: 'Please accept our terms of use.',
    INVALID_EMAIL: 'Please enter a valid email address.',
    INVALID_PASSWORD: "The password doesn't reflect the guidelines.",
    PASSWORD_CONFIRM_NOT_MATCHING: "The password confirmation doesn't match.",
    WRONG_CREDENTIALS: `
      You have entered an invalid username/password combination. Please re-enter your username and password.
    `,
    ERROR_OCCURRED_REFRESH: 'An error has occurred; try refreshing the page.',
    ERROR_OCCURRED_TRY_LATER: 'Something went wrong… Try again later.',
    SEARCH_ERROR: 'Invalid query.',
    EDIT_DELETED_ENTITY: 'The feature you are trying to edit may have been deleted. Refresh the page and try again.',
    DELETE_DELETED_ENTITY: 'The feature you are trying to delete may have been deleted. Refresh the page and try again.',
    SHARE_ERROR: 'Something went wrong… Try to share again later.',
    API_KEY_CREATED: 'API key created.',
  },
};

export const RELEVANCE_SORTING = {
  label: 'Relevance',
  value: 'relevance',
};

export const EMAIL_VALIDATOR =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_VALIDATOR = /^[-0-9+;()\s]+$/;

export const SAVE_SEARCH_FREQUENCY = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
];

export const OPPORTUNITIES_SORT_OPTIONS = [
  RELEVANCE_SORTING,
  {
    label: 'Opportunity title (A-Z)',
    value: 'title',
  },
  {
    label: 'Opportunity title (Z-A)',
    value: '-title',
  },
  {
    label: 'Funder name (A-Z)',
    value: 'funderName',
  },
  {
    label: 'Funder name (Z-A)',
    value: '-funderName',
  },
  {
    label: 'Deadline (soonest first)',
    value: 'deadline',
  },
  {
    label: 'Deadline (latest first)',
    value: '-deadline',
  },
  {
    label: 'Amount (highest first)',
    value: '-amount',
  },
];

export const FUNDERS_SORT_OPTIONS = [
  RELEVANCE_SORTING,
  {
    label: 'Opportunities (highest first)',
    value: '-opportunities',
  },
  {
    label: 'Grants (highest first)',
    value: '-awards',
  },
];

export const AWARDS_SORT_OPTIONS = [
  RELEVANCE_SORTING,
  {
    label: 'Grant title (A-Z)',
    value: 'title',
  },
  {
    label: 'Grant title (Z-A)',
    value: '-title',
  },
  {
    label: 'Funder name (A-Z)',
    value: 'funderName',
  },
  {
    label: 'Funder name (Z-A)',
    value: '-funderName',
  },
  {
    label: 'Amount (highest first)',
    value: '-amount',
  },
  {
    label: 'Start year (nearest first)',
    value: '-startYear',
  },
];

export enum UserIdentityColor {
  GREY = 'GREY',
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export const USER_COLOR_STATE = {
  [UserIdentityColor.GREY]: {
    color: 'gray',
    label: 'Anonymous (Free Access)',
  },
  [UserIdentityColor.BLUE]: {
    color: 'blue',
    label: 'Anonymous IP Access (Anonymous Entitled Access)',
  },
  [UserIdentityColor.YELLOW]: {
    color: 'yellow',
    label: 'Signed in (Personal & Social Free Access)',
  },
  [UserIdentityColor.GREEN]: {
    color: 'green',
    label: 'Registered Shibboleth (Personal & Social Entitled Access)',
  },
};

/**
 * Map of parameters used in the global state versus those used in the URL search params
 *
 * Example: asjcCategories in backend --> "sub-discipline" in frontend URL
 *
 * { [key: param name in object for POST /api/opportunities]: url search param }
 */
export const PARAM_NAMES_MAP = {
  activityCountries: 'ac',
  applicationDeadline: 'deadline',
  countries: 'citizenship',
  degreeRequirements: 'dr',
  fundingOrganizations: 'funder',
  fundingTypes: 'type',
  includeExpired: 'expired',
  individualApplicantTypes: 'iat',
  lastUpdateDate: 'lastUpdateDate',
  organizationalApplicantTypes: 'oat',
  restrictions: 'rst',
};

export const BREAKPOINTS = {
  XS: 400,
  S: 600,
  M: 992,
  L: 1279,
};

export const SEARCH_CATEGORIES = {
  OPPORTUNITIES: 'opportunities',
  NEW_OPPORTUNITIES: 'new-opportunities',
  TRACKEDOPPORTUNITIES: 'trackedOpportunities',
  PUBLIC_GROUP: 'publicGroup',
  FUNDERS: 'funders',
  AWARDS: 'awards',
  GROUPS: 'groups',
  OPPORTUNITY_NOTES: 'opportunityNotes',
  FUNDER_NOTES: 'funderNotes',
  INTERNAL_OPPORTUNITIES: 'internalOpportunities',
  LABELS: 'labels',
} as const;

export const EXTERNAL_ROUTES = {
  SUPPORT_CENTER: 'https://service.elsevier.com/app/home/supporthub/funding-inst/',
  QUICK_START_GUIDE: 'https://supportcontent.elsevier.com/RightNow%20Next%20Gen/Funding%20Institutional/FI_QuickGuideSept2024.pdf',
} as const;

const INITIAL_ROUTES = {
  OPPORTUNITIES: '/opportunities',
  FUNDERS: '/funders',
  MYFUNDING: '/my-funding',
  SENT_TO_PURE: '/sent-to-pure',
} as const;

const BASE_ROUTES = {
  ...INITIAL_ROUTES,
  OPPORTUNITY_DETAILS: `${INITIAL_ROUTES.OPPORTUNITIES}/:id`,
  FUNDER_DETAILS_PAGE: `${INITIAL_ROUTES.FUNDERS}/:id`,
  MYFUNDING_SENT_TO_PURE: `${INITIAL_ROUTES.MYFUNDING}${INITIAL_ROUTES.SENT_TO_PURE}`,

  SUGGESTIONS: '/suggestions',
  REMOTE_LOGIN: '/remote-login',
  AWARDS: '/awards',
  SEARCH: '/search',
  PROFILE: '/profile',
  SAVED_SEARCHES: '/saved-searches',
  TRACKED_OPPORTUNITIES: '/tracked-opportunities',
  GROUP: '/group',
  ACCOUNT: '/account',
  ROLES: '/roles',
  LISTS: '/lists',
  GROUPS: '/groups',
  UPDATE: '/update',
  UNSUBSCRIBE_SAVED_SEARCH: '/unsubscribe',
  SUCCESSFULLY_UNSUBSCRIBED_SAVED_SEARCH: '/unsubscribe/successful',
  UNSUBSCRIBE_ALERTS: '/unsubscribe/alert',
  SUCCESSFULLY_UNSUBSCRIBE_ALERTS: '/unsubscribe/alert/successful',
  PUBLIC: '/public',
  ROOT: '/',
  ADMIN_INVITATION: '/admin-invitation',
  ACCEPT_ADMIN_INVITATION: '/accept-admin-invitation',

  EDITORS: '/editors',
  OPPORTUNITY_NOTES: `/notes${INITIAL_ROUTES.OPPORTUNITIES}`,
  FUNDER_NOTES: `/notes${INITIAL_ROUTES.FUNDERS}`,
  INSTITUTIONAL_RESEARCH_AREAS: '/institutional-research-areas',
  INTERNAL_OPPORTUNITIES: '/internal-opportunities',

  SIGN_IN: '/session/sign-in',
  SIGN_OUT: '/session/sign-out',

  REGISTER: '/session/register',

  GET_STARTED: '/session/get-started',
  CHECK_ACCESS: '/session/check-access',

  USER_SETTINGS: '/user/settings',

  CHANGE_ORGANIZATION: '/session/change-organization',
  INSTITUTION_ASSOCIATION: '/session/inst-assoc',
  AWARDS_ANALYTICS: '/search/analytics/awards',
  ACKNOWLEDGEMENTS: '/acknowledgements',
  PAGE_NOT_FOUND: '/404',

  /* TESTING ID+ */
  NO_PROMPT_SIMULATION: '/test/page/no-prompt-simulation',
  LOGIN_PROMPT_SIMULATION: '/test/page/login-prompt-simulation',
  USER_ID_SIMULATION: '/test/page/user-id-simulation',
  TEST_CALLBACK: '/test/session/callback',
} as const;

const EDITORS_PAGE = `${BASE_ROUTES.EDITORS}/:tab(${[
  BASE_ROUTES.OPPORTUNITY_NOTES,
  BASE_ROUTES.FUNDER_NOTES,
  BASE_ROUTES.INSTITUTIONAL_RESEARCH_AREAS,
  `${BASE_ROUTES.INTERNAL_OPPORTUNITIES}/edit`,
  `${BASE_ROUTES.INTERNAL_OPPORTUNITIES}/create`,
  BASE_ROUTES.INTERNAL_OPPORTUNITIES,
]
  .map((r) => r.substring(1)) // remove the leading slash
  .join('|')})`;

export const ROUTES = {
  ...BASE_ROUTES,
  SEARCH_OPPORTUNITIES: `${BASE_ROUTES.SEARCH}${BASE_ROUTES.OPPORTUNITIES}`,
  SEARCH_FUNDERS: `${BASE_ROUTES.SEARCH}${BASE_ROUTES.FUNDERS}`,
  SEARCH_CHARTS: `${BASE_ROUTES.AWARDS_ANALYTICS}`,
  SEARCH_AWARDS: `${BASE_ROUTES.SEARCH}${BASE_ROUTES.AWARDS}`,
  OPPORTUNITY_DETAILS_PAGE_TAB: `${BASE_ROUTES.OPPORTUNITY_DETAILS}/:tab(details|similar-opps|should-apply)`,
  OPPORTUNITY_DETAILS_PAGE_TAB_DETAILS: `${BASE_ROUTES.OPPORTUNITY_DETAILS}/details`,
  AWARD_DETAILS: `${BASE_ROUTES.AWARDS}/:id`,
  MYFUNDING_SAVED_SEARCHES: `${BASE_ROUTES.MYFUNDING}${BASE_ROUTES.SAVED_SEARCHES}`,
  MYFUNDING_TRACKED_OPPORTUNITIES: `${BASE_ROUTES.MYFUNDING}${BASE_ROUTES.TRACKED_OPPORTUNITIES}`,
  MYFUNDING_SENT_TO_PURE: `${BASE_ROUTES.MYFUNDING}${BASE_ROUTES.SENT_TO_PURE}`,
  MYFUNDING_LISTS: `${BASE_ROUTES.MYFUNDING}${BASE_ROUTES.LISTS}`,
  MYFUNDING_GROUPS: `${BASE_ROUTES.MYFUNDING}${BASE_ROUTES.TRACKED_OPPORTUNITIES}/:groupId`,
  MYFUNDING_SENT_TO_PURE_LABELS: `${BASE_ROUTES.MYFUNDING_SENT_TO_PURE}/:labelId`,
  MYFUNDING_PAGE: `${BASE_ROUTES.MYFUNDING}/:tab(saved-searches|tracked-opportunities|sent-to-pure)`,
  PROFILE_ACCOUNT: `${BASE_ROUTES.PROFILE}${BASE_ROUTES.ACCOUNT}`,
  PROFILE_ROLES: `${BASE_ROUTES.PROFILE}${BASE_ROUTES.ROLES}`,
  FUNDER_DETAILS_PAGE: `${BASE_ROUTES.FUNDERS}/:id`,
  FUNDER_DETAILS_PAGE_TAB: `${BASE_ROUTES.FUNDER_DETAILS_PAGE}/:tab(details|should-apply)`,
  FUNDER_DETAILS_PAGE_TAB_DETAILS: `${BASE_ROUTES.FUNDER_DETAILS_PAGE}/details`,
  PUBLIC_GROUP: `${BASE_ROUTES.PUBLIC}${BASE_ROUTES.GROUP}/:groupId`,
  PUBLIC_ADMIN_INVITATION: `${BASE_ROUTES.PUBLIC}${BASE_ROUTES.ADMIN_INVITATION}`,
  SUGGESTIONS_DETAILS_PAGE: `${BASE_ROUTES.SUGGESTIONS}/:id`,
  SUGGESTIONS: `${BASE_ROUTES.SUGGESTIONS}`,
  EDITORS_PAGE,
  EDITOR_OPPORTUNITY_NOTES: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.OPPORTUNITY_NOTES}`,
  EDITOR_FUNDER_NOTES: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.FUNDER_NOTES}`,
  EDITOR_INSTITUTIONAL_RESEARCH_AREAS: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.INSTITUTIONAL_RESEARCH_AREAS}`,
  EDITOR_INTERNAL_OPPORTUNITIES: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.INTERNAL_OPPORTUNITIES}`,
  EDITOR_CREATE_INTERNAL_OPPORTUNITIES: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.INTERNAL_OPPORTUNITIES}/create`,
  EDITOR_EDIT_INTERNAL_OPPORTUNITIES: `${BASE_ROUTES.EDITORS}${BASE_ROUTES.INTERNAL_OPPORTUNITIES}/edit/:id`,
} as const;

export const STATIC_HTML_PAGE_TITLES = {
  [ROUTES.SEARCH_OPPORTUNITIES]: 'Funding Institutional [Opportunities]',
  [ROUTES.SEARCH_FUNDERS]: 'Funding Institutional [Funders]',
  [ROUTES.SEARCH_AWARDS]: 'Funding Institutional [Awarded grants - Grants]',
  [ROUTES.SEARCH_CHARTS]: 'Funding Institutional [Awarded grants - Charts]',
  [ROUTES.MYFUNDING_SAVED_SEARCHES]: 'Funding Institutional [My funding - Saved searches]',
  [ROUTES.REGISTER]: 'Funding Institutional [Register]',
  [ROUTES.ROOT]: 'Funding Institutional',
  [ROUTES.EDITOR_OPPORTUNITY_NOTES]: 'Funding Institutional [Editors - Opportunity Notes]',
  [ROUTES.EDITOR_FUNDER_NOTES]: 'Funding Institutional [Editors - Funder Notes]',
  [ROUTES.EDITOR_INTERNAL_OPPORTUNITIES]: 'Funding Institutional [Editors - Internal Opportunities]',
  [ROUTES.PROFILE_ROLES]: 'Funding Institutional [Roles]',
  [ROUTES.PROFILE_ACCOUNT]: 'Funding Institutional [Account]',
};

type TDynamicPageTitle = { path: string; pageTitle: string; exact?: boolean };
export const DYNAMIC_HTML_PAGE_TITLES: TDynamicPageTitle[] = [
  {
    path: ROUTES.OPPORTUNITY_DETAILS,
    pageTitle: 'Funding Institutional [Opportunity details]',
  },
  { path: ROUTES.AWARD_DETAILS, pageTitle: 'Funding Institutional [Grant details]', exact: true },
  { path: ROUTES.FUNDER_DETAILS_PAGE, pageTitle: 'Funding Institutional [Funder details]' },
  { path: ROUTES.MYFUNDING_TRACKED_OPPORTUNITIES, pageTitle: 'Funding Institutional [My funding - Tracked opportunities]' },
  { path: ROUTES.MYFUNDING_SENT_TO_PURE, pageTitle: 'Funding Institutional [My funding - Sent to Pure]' },
  { path: ROUTES.SUGGESTIONS, pageTitle: 'Funding Institutional [Suggestions]' },
];

export const getDynamicHtmlPageTitle = (path: string, pageTitleMap: TDynamicPageTitle[]) => {
  const defaultHtmlTitle = 'Funding Institutional';

  const matchedTitle = pageTitleMap.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact || false,
    }),
  );

  const title = matchedTitle?.pageTitle || defaultHtmlTitle;
  return title;
};

export const API_ROUTES = {
  opportunities_search: '/api/opportunities',
  opportunities_meta: '/api/opportunities/.meta',
} as const;

export const ALERTS = {
  EXPORT: {
    FAIL: 'Something went wrong… Try to export again later.',
  },
  SELECT: {
    LIMIT_EXCEEDED: (searchTarget: string) =>
      `You can only select up to ${NumberUtils.getCommaFormattedNumber(MAX_SELECTED_ITEMS)} ${searchTarget} at once.`,
  },
};

export enum USER_ROLES {
  ALMIGHTY = 'ALMIGHTY',
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
}

export type TRoles = 'ALMIGHTY' | 'ADMIN' | 'EDITOR' | 'VIEWER';

export const USER_ROLES_TITLES = [
  { value: USER_ROLES.ALMIGHTY, label: 'Super admin' },
  { value: USER_ROLES.ADMIN, label: 'Admin' },
  { value: USER_ROLES.EDITOR, label: 'Editor' },
];
export const DEFAULT_USER_ROLE = USER_ROLES.VIEWER;

export const ADMIN_ROLES = [USER_ROLES.ADMIN, USER_ROLES.ALMIGHTY];
export const EDITING_ROLES = [USER_ROLES.ADMIN, USER_ROLES.ALMIGHTY, USER_ROLES.EDITOR];

export const KEYCODES = {
  UP: 38,
  DOWN: 40,
  ESC: 27,
  ENTER: 13,
  BACKSPACE: 8,
};

export const DEADLINE_TYPES = {
  DATE: 'OPPORTUNITY_WITH_DEADLINE',
  ONGOING: 'ONGOING_OPPORTUNITY',
};

export const DEFAULT_DEADLINE_TYPE = {
  label: 'Application',
  value: 'DueDate',
};

export const OPPORTUNITY_ACTIVE = 'active';
export const OPPORTUNITY_INACTIVE = 'inactive';

export const OPPORTUNITY_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED_AND_ACTIVE: OPPORTUNITY_ACTIVE,
  PUBLISHED_AND_INACTIVE: OPPORTUNITY_INACTIVE,
} as const;

export const OPPORTUNITY_STATUSES_TITLES = {
  [OPPORTUNITY_STATUSES.DRAFT]: 'Unpublished draft',
  [OPPORTUNITY_STATUSES.PUBLISHED_AND_ACTIVE]: 'Published & active',
  [OPPORTUNITY_STATUSES.PUBLISHED_AND_INACTIVE]: 'Published & inactive',
};

export enum EXTERNAL_PRODUCT_TYPE {
  PURE = 'PURE',
}

export enum ALERT_CONFIG {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  DANGER = 'danger',
  TIME_OUT = 4000,
}
export const ACCESS_POLICIES = {
  FULL_ACCESS: 'full-access',
  INSTITUTION_ACCESS: 'institution-access',
};

export enum INTEGRATION_PRODUCTS {
  PURE = 'PURE',
}

export const FACETS = {
  OPPORTUNITIES: {
    onlyInternal: 'Internal opportunities',
    includeExpired: 'Expired deadlines',
    forthcoming: 'Forthcoming opportunities',
    fundingTypes: 'Funding type',
    funderCountries: 'Funder country/region',
    organizationalApplicantTypes: 'Organizational applicant type',
    activityCountries: 'Activity country/region',
    degreeRequirements: 'Degree requirement',
    restrictions: 'Applicant restriction',
    applicationDeadline: 'Application deadline',
    amount: 'Amount',
    funderType: 'Funder type',
    countries: 'Applicant citizenship',
    fundingOrganizations: 'Funder',
    researchAreas: 'Research discipline',
    limitedSubmission: 'Limited submission',
    individualApplicantType: 'Individual applicant type',
    applicantCountry: 'Applicant country/region affiliation',
    onlyRecurring: 'Recurring opportunities',
    lastUpdateDate: 'Opportunities updated since this date',
    excludeFunder: 'Exclude funder',
    excludeFunderCountry: 'Exclude funder country',
    excludeApplicantCountry: 'Exclude applicant country',
    excludeApplicantCitizenship: 'Exclude applicant citizenship',
    excludeActivityCountry: 'Exclude activity country',
  },
  AWARDS: {
    awardedInstitutions: 'Awardee institution',
    awardedAuthors: 'Awarded researcher',
    awardeeCountries: 'Awardee institution country/region',
    grantTypes: 'Funding type',
    fundingOrganizations: 'Funder',
    funderCountries: 'Funder country/region',
    funderType: 'Funder type',
    startYear: 'Award start year',
    amount: 'Amount',
    researchAreas: 'Research discipline',
    excludeFunderName: 'Exclude funder',
    excludeFunderCountry: 'Exclude funder country',
    excludeRecipientCountry: 'Exclude recipient country',
  },
  FUNDERS: {
    funderCountries: 'Funder country/region',
    funderType: 'Funder type',
    excludeFunderCountry: 'Exclude funder country',
  },
};

export enum FEATURE {
  EXPORT = 'EXPORT',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
}
export enum PERMISSION {
  DENY = 'DENY',
  ALLOW = 'ALLOW',
}
export const ADVANCE_SEARCH_COMMAND = {
  ALL: 'all',
  ANY: 'any',
  OPTIONAL: 'optional',
  NONE: 'none',
};

export const DeadLineTypes = {
  LoiDate: 'Letter of intent',
  DueDate: 'Application',
  OtherDate: 'Other',
};

// small utility function for typescript to get object's props value by passing string index.
export const getPropertyByValue = function getPropertyByValue<T, K extends keyof T>(obj: T, propertyName: K): T[K] {
  return obj[propertyName];
};

export const MODALS = {
  OPPORTUNITY_NOTE_MODAL: 'OPPORTUNITY_NOTE',
  FUNDER_NOTE_MODAL: 'FUNDER_NOTE',
  ERROR_MODAL: 'ERROR_MODAL',
  SHARE_OPPORTUNITY_MODAL: 'SHARE_OPPORTUNITY_MODAL',
  DELETE_OPPORTUNITY_MODAL: 'DELETE_OPPORTUNITY_MODAL',
  NEW_OPPORTUNITY_NOTE_MODAL: 'NEW_OPPORTUNITY_NOTE',
};

export const allowedHTMLTags = '<a><br><p><b><strong><ul><ol><li><h3><mark>';
