import { createAction, createReducer } from 'redux-act';

export const opportunitiesAlertActions = {
  unsubscribeFromAlert: createAction('unsubscribe from alert', (payload) => ({
    type: payload.type,
    unsubscribeId: payload.unsubscribeId,
    user: payload.user,
    opportunityId: payload.opportunityId,
  })),
  setLoading: createAction('set isLoading'),
  unsubscribeFromAlertSuccess: createAction('unsubscribed succesfully'),
};

const setLoading = (state, isLoading) => ({
  ...state,
  isLoading,
});

const unsubscribeFromAlertSuccess = (state, isUnsubscribed) => ({
  ...state,
  unsubscribedFromAlert: isUnsubscribed,
});

export const opportunitiesAlertReducer = createReducer(
  {
    [opportunitiesAlertActions.setLoading]: setLoading,
    [opportunitiesAlertActions.unsubscribeFromAlertSuccess]: unsubscribeFromAlertSuccess,
  },
  {
    isLoading: false,
    unsubscribedFromAlert: false,
  },
);
