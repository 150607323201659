import { createAction, createReducer } from 'redux-act';

import { BREAKPOINTS } from '@fi/constants';

/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const pageActions = {
  setPageTitle: createAction('set page title'),
  openFilters: createAction('open filters'),
  closeFilters: createAction('close filters'),
  toggleFilters: createAction('toggle filters'),
  createAlert: createAction('create alert'),
  removeAlert: createAction('remove alert'),
  resetAlerts: createAction('reset alerts'),
  setWindowSize: createAction('set window size'),
  set404: createAction('setFourOFour'),
  set500: createAction('set 500'),
  set200: createAction('set 200'),
  setIsStickyToolbar: createAction('set sticky toolbar'),
};

export const getAlerts = (state, context) => state.page.alerts.find((alert) => alert.context === context);

const setPageTitle = (state, title) => {
  return {
    ...state,
    title,
  };
};

const openFilters = (state) => ({
  ...state,
  filtersExpanded: true,
});
const closeFilters = (state) => ({
  ...state,
  filtersExpanded: false,
});
const toggleFilters = (state) => ({
  ...state,
  filtersExpanded: !state.filtersExpanded,
});
const createAlert = (state, options) => {
  const alertOptions = {
    ...options,
    id: Date.now(),
  };
  return {
    ...state,
    alerts: [...state.alerts.filter((alert) => alert.context !== options.context), alertOptions],
  };
};
const removeAlert = (state, id) => ({
  ...state,
  alerts: state.alerts.filter((alert) => alert.id !== id),
});
const resetAlerts = (state) => ({
  ...state,
  alerts: [],
});
const setWindowSize = (state, windowSize) => ({
  ...state,
  windowSize,
});

const set200 = (state) => ({
  ...state,
  status: 200,
});

const set404 = (state) => {
  return {
    ...state,
    status: 404,
  };
};

const set500 = (state) => ({
  ...state,
  status: 500,
});

const setInitialFiltersExpanded = () => {
  return window.innerWidth > BREAKPOINTS.L;
};

const setIsStickyToolbar = (state, isStickyToolbar) => {
  return {
    ...state,
    isStickyToolbar,
  };
};

/* Reducers */
export const pageReducer = createReducer(
  {
    [pageActions.setPageTitle]: setPageTitle,
    [pageActions.openFilters]: openFilters,
    [pageActions.closeFilters]: closeFilters,
    [pageActions.toggleFilters]: toggleFilters,
    [pageActions.createAlert]: createAlert,
    [pageActions.removeAlert]: removeAlert,
    [pageActions.resetAlerts]: resetAlerts,
    [pageActions.setWindowSize]: setWindowSize,
    [pageActions.set200]: set200,
    [pageActions.set404]: set404,
    [pageActions.set500]: set500,
    [pageActions.setIsStickyToolbar]: setIsStickyToolbar,
  },
  {
    title: 'Search for funding opportunities',
    filtersExpanded: setInitialFiltersExpanded(),
    windowSize: 'xl', // enum: xs, s, m, l, xl,
    alerts: [],
    status: 200, // enum: 404, 500, 200
    isStickyToolbar: false,
    rightContainerScrollTop: 0,
  },
);
