import { takeEvery, call, put, select, takeLatest } from 'redux-saga/lib/effects.js';
import download from 'downloadjs';

import { publicGroupActions } from '../dispatchers/publicGroup.js';
import { pageActions } from '../dispatchers/page.js';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { apiGet, apiPost } from '../../services/requests.ts';
import { PAGE_SIZE, LOCALE, SEARCH_CATEGORIES, MAX_SELECTED_ITEMS, ROUTES, API_ROUTES } from '@fi/constants';
import { exportFile } from '../../services/api/exportToFile.js';
import { createOpportunitySearchQueryObject } from '../../query/opportunitySearchQuery.ts';

const TRACKALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchGroupOpportunities(params) {
  const { groupId, apiUrl, queryObject } = params;
  queryObject['groupId'] = groupId;
  const apiGroupDetailUrl = `/api/groups/${groupId}`;
  try {
    yield put(publicGroupActions.setIsLoadingResults(true));
    const groupDetail = yield call(apiGet, apiGroupDetailUrl);
    // if the user is the group owner, it should redirect to My Funding group page
    const userData = yield select((state) => state.user.userData);
    const { accountId, userId } = groupDetail;
    if (userId === userData.userId && accountId === userData.account.id) {
      window.location.replace(`${ROUTES.MYFUNDING_TRACKED_OPPORTUNITIES}/${groupId}`);
    }
    const groupOpportunities = yield call(apiPost, apiUrl, queryObject);
    yield put(publicGroupActions.setGroup(groupDetail));
    yield put(publicGroupActions.loadOpportunities(groupOpportunities));
  } catch (e) {
    // 404 group not found if the group doesn't exist
    if (e.status && e.status === 404) {
      yield put(pageActions.set404());
    } else {
      const alertOptions = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
        timeout: 4000,
      };
      yield put(pageActions.createAlert(alertOptions));
    }
  } finally {
    yield put(publicGroupActions.setIsLoadingResults(false));
  }
}

function* doFetchOpportunities(action) {
  const groupId = action && action.payload ? action.payload.groupId : null;
  const searchQuery = action && action.payload ? action.payload.searchQuery : null;
  const queryObject = {
    pageSize: PAGE_SIZE,
  };
  const apiUrl = API_ROUTES.opportunities_search;
  if (searchQuery && typeof searchQuery === 'string') {
    const queries = getUrlParameters(searchQuery);
    Object.assign(queryObject, queries);
    if (queries && queries.orderBy && queries.orderBy === '-lastUpdateDate') {
      queryObject['orderBy'] = ['relevance'];
    }
  }
  queryObject['page'] = parseInt(queryObject.page, 10) - 1;
  yield doFetchGroupOpportunities({
    apiUrl,
    groupId,
    queryObject,
  });
}

function* doSelectAllSearchResult(action) {
  const groupId = action && action.payload ? action.payload.groupId : null;
  try {
    yield put(publicGroupActions.setIsLoadingResults(true));
    const searchQuery = yield select((state) => state.trackOpportunity.searchQuery);
    const apiUrl = '/api/opportunities/ids';
    const apiQueryOptions = {
      status: null,
      scope: SEARCH_CATEGORIES.OPPORTUNITIES,
    };
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;
    if (groupId) {
      queryObject['groupId'] = groupId;
    }
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(publicGroupActions.setSelectedItems(ids));
    yield put(publicGroupActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(publicGroupActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(TRACKALERTOPTIONS));
  }
}

function* doExportFile(action) {
  try {
    yield put(publicGroupActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);
    yield put(publicGroupActions.exportFileFailed(false));
    yield put(publicGroupActions.isFileExporting(false));
  } catch (e) {
    yield put(publicGroupActions.exportFileFailed(true));
    yield put(publicGroupActions.isFileExporting(false));
  }
}

function* doShareGroup(action) {
  const { shareData } = action.payload;
  try {
    yield put(publicGroupActions.setIsSharingInProgress(true));
    yield call(apiPost, '/api/user/share/groups', shareData);
    yield put(publicGroupActions.setSharingStatus({ status: true }));
  } catch (e) {
    const alertOptions = {
      ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
      timeout: 4000,
    };
    yield put(pageActions.createAlert(alertOptions));
  } finally {
    yield put(publicGroupActions.setIsSharingInProgress(false));
    yield put(publicGroupActions.setShareGroupModalIsOpen(false));
  }
}

export default function* publicGroupSaga() {
  yield takeLatest(publicGroupActions.fetchGroupOpportunities, doFetchOpportunities);
  yield takeEvery(publicGroupActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(publicGroupActions.exportFile, doExportFile);
  yield takeLatest(publicGroupActions.shareGroup, doShareGroup);
}
