import { getText } from '../text.ts';

export const applicationDeadlines = [
  {
    key: '14-30',
    value: getText('deadline14To30Days'),
    min: 14,
    max: 30,
  },
  {
    key: '30-90',
    value: getText('deadline30To90Days'),
    min: 30,
    max: 90,
  },
  {
    key: '90-',
    value: getText('deadlineMoreThan90Days'),
    min: 90,
    max: -1,
  },
];
