import { call, put, takeEvery } from 'redux-saga/lib/effects.js';
import { labelsActions } from '../dispatchers/labels.js';
import { apiDelete, apiGet } from '../../services/requests.ts';

const apiUrl = '/api/labels';

function* doFetchLabelList() {
  try {
    yield put(labelsActions.setIsLoadingLabels(true));
    const labels = yield call(apiGet, apiUrl);
    yield put(
      labelsActions.setLabels({
        labels,
      }),
    );
    yield put(
      labelsActions.setFetchingLabelsStatus({
        status: true,
      }),
    );
  } catch (e) {
    yield put(
      labelsActions.setFetchingLabelsStatus({
        status: false,
      }),
    );
  } finally {
    yield put(labelsActions.setIsLoadingLabels(false));
  }
}

function* deleteLabel(action) {
  const id = action.payload;
  try {
    put(labelsActions.setIsDeletingLabel(true));
    yield call(apiDelete, `${apiUrl}/${id}`);
    yield put(
      labelsActions.setDeleteLabelStatus({
        status: true,
      }),
    );
    put(labelsActions.setIsDeletingLabel(false));
    yield doFetchLabelList();
  } catch (e) {
    yield put(
      labelsActions.setDeleteLabelStatus({
        status: false,
      }),
    );
    put(labelsActions.setIsDeletingLabel(false));
  }
}

export default function* labelSaga() {
  yield takeEvery(labelsActions.fetchLabels, doFetchLabelList);
  yield takeEvery(labelsActions.deleteLabel, deleteLabel);
}
