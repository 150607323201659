import { call } from 'redux-saga/lib/effects.js';
import { apiPost } from '../../../services/requests.ts';

const referrerList = [
  {
    pathname: /^\/$/g,
    data: {
      referrer: 'HOME_PAGE',
    },
  },
  {
    pathname: /^\/search\/opportunities$/g,
    data: {
      referrer: 'SEARCH_PAGE',
    },
  },
  {
    pathname: /^\/my-funding\/tracked-opportunities\/\S+$/g,
    data: {
      referrer: 'TRACK_LIST_PAGE',
    },
  },
  {
    pathname: /^\/public\/group\/\S+$/g,
    data: {
      referrer: 'SHARE_GROUP',
    },
  },
  {
    pathname: /^\/funders\/\S+$/g,
    data: {
      referrer: 'FUNDER_PAGE',
    },
  },
  {
    dgcid: 'raven_fi_funding_search_email',
    data: {
      referrer: 'SAVED_SEARCH_EMAIL',
    },
  },
  {
    dgcid: 'raven_fi_funding_share_email',
    data: {
      referrer: 'SHARE_EMAIL',
    },
  },
  {
    dgcid: 'raven_fi_funding_share_group',
    data: {
      referrer: 'SHARE_EMAIL',
    },
  },
  {
    dgcid: 'similar_opportunity',
    data: {
      referrer: 'SUGGESTION',
    },
  },
];

function getPresentationReferrer(pathname, dgcid) {
  return referrerList.find((referrerItem) => {
    if (dgcid) {
      if (referrerItem.dgcid && referrerItem.dgcid === dgcid) {
        return true;
      }
      return false;
    } else {
      if (pathname.match(referrerItem.pathname)) {
        return true;
      }
      return false;
    }
  });
}

function* sendPresentationLog(action) {
  try {
    const { opportunityId, dgcid, referrerSource, evaluation, rank, query } = action.payload;
    const config = getPresentationReferrer(window.location.pathname, dgcid);
    yield call(apiPost, `/api/opportunities/${opportunityId}/presentations`, {
      referrer: config.data.referrer,
      referrerSource,
      evaluation,
      rank,
      query,
    });
  } catch (e) {
    console.error(e);
  }
}

export default sendPresentationLog;
