interface EnvConfig {
  userPrivacyCenterUrl: string;
  /** URI for fetching Adobe Analytics script */
  siteCatalystScriptUri: string;
  /**
   * `dev` and `test` can be any value, all traffic will go to the Adobe dev suite.
   *
   * In the Adobe dashboard, the dimension `environment` can be used to segment traffic for specific environments or test runs.
   **/
  siteCatalystEnvironment: 'dev' | 'test' | 'prod';
  fragmentAppComposerApiKey: string;
  fragmentAppComposerUrl: string;
  analyticsInfo: AnalyticsInfo;
}

/**
 * set up env props, fragments and adobe analytics
 */
export const bootstrapFI = async () => {
  /** If `appData` from Adobe has not loaded yet, use this array as a buffer */
  window.appData = window.appData || [];
  window.appData.push({
    // hard technical requirement: first event sent out to Adobe MUST be a `pageLoad` event
    event: 'pageLoad',
  });

  // if the request fails we don't want the code after it to be executed
  const fiEnv: EnvConfig = await fetch('/env/config').then((response) => response.json());

  window.env = {
    userPrivacyCenterUrl: fiEnv.userPrivacyCenterUrl,
    fragmentAppComposerApiKey: fiEnv.fragmentAppComposerApiKey,
  };

  addScriptEle(fiEnv.siteCatalystScriptUri, 'async');
  addScriptEle(fiEnv.fragmentAppComposerUrl);

  // adobe analytics initialization
  const visitorInfo = fiEnv.analyticsInfo;

  window.pageData = {
    page: {
      businessUnit: 'els:rp:st',
      language: 'en',
      productName: 'fi',
      environment: fiEnv.siteCatalystEnvironment,
      /**
       * Signals loading the webpage and Adobe library for the first time.
       * Prevents duplicated page view events on other pages.
       * */
      name: 'page-init',
    },
    visitor: visitorInfo,
  };
};

const addScriptEle = (url: string, loadScript: 'defer' | 'async' = 'defer') => {
  const scriptEle = document.createElement('script');
  scriptEle.setAttribute('type', 'text/javascript');
  scriptEle.setAttribute(loadScript, '');
  scriptEle.setAttribute('src', url);
  document.body.appendChild(scriptEle);
};
