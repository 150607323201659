import { apiGet } from '../requests.ts';

function getAsjcClassifications() {
  return apiGet('/api/classifications/asjc');
}

function getFundingOrgTypes() {
  return apiGet('/api/descriptions/fundingOrgTypes');
}

function getOpportunitiesMeta() {
  return apiGet('/api/opportunities/.meta');
}

function getIndividualApplicantTypes(): Promise<any> {
  return apiGet('/api/eligibility/individual-applicant-types');
}

function getOrganizationalApplicantTypes(): Promise<any> {
  return apiGet('/api/eligibility/organization-applicant-types');
}

function getDegreeRequirements(): Promise<any> {
  return apiGet('/api/eligibility/degrees');
}

function getRestrictions(): Promise<any> {
  return apiGet('/api/eligibility/restrictions');
}

export {
  getAsjcClassifications,
  getFundingOrgTypes,
  getOpportunitiesMeta,
  getIndividualApplicantTypes,
  getOrganizationalApplicantTypes,
  getDegreeRequirements,
  getRestrictions,
};
