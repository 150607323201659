const text = {
  citizenshipNotSpecified: 'Citizenship not specified',
  citizenshipNotRestricted: 'No citizenship restrictions',
  amountNotSpecified: 'Opportunities with unspecified amount',
  ongoingOpportunities: 'Continuing/recurring opportunities',
  deadlineNotSpecified: 'Deadline not specified',
  deadline0To14Days: 'Within 14 days',
  deadline14To30Days: 'In 14 to 30 days',
  deadline30To90Days: 'In 30 to 90 days',
  deadlineMoreThan90Days: 'More than 90 days',
  unspecifiedDeadlines: 'Include opportunities with an unspecified date',
};

export const getText = (id: keyof typeof text) => text[id] || '';
