/**
 *
 * Note - there is also some copywriting in:
 * - src/main/app/constants/index.ts
 * - src/main/app/opportunities/modals/TrackModal/constants.ts
 */
export default {
  /** Pages in the project - follows URL structure */
  pages: {
    landing: {
      hero: {
        title: 'Funding Institutional',
        content: {
          bullet1: 'Discover relevant funding opportunities',
          bullet2: 'Gain actionable insights on the funding landscape',
        },
        trialButton: 'Request a 2-week free trial',
        getStartedButton: 'Get started',
        checkAccessButton: 'Check access',
      },
      keyFeatures: {
        discovery: {
          title: 'Discovery',
          content: 'Identify new funding sources through enhanced keyword or faceted searches based on eligibility and suggestions',
        },
        insights: {
          title: 'Insights',
          content: 'Get insights into the funding landscape by combining awarded grants, funder and funding opportunity data',
        },
        decisions: {
          title: 'Decisions',
          content: 'Increase grant success rates using data and intelligence, identifying the most eligible researchers and collaborators',
        },
      },
      contentStatistics: {
        sectionTitle: 'Funding Institutional offers access to the following content',
        activeOpportunitiesTotal: {
          title: '45,000+',
          content: 'Active and recurring funding opportunities',
        },
        activeOpportunityValue: {
          title: '$100+ Billion (USD)',
          content: 'Worth of active funding opportunities',
        },
        awardedGrantsTotal: {
          title: '9+ Million',
          content: 'Awarded grants',
        },
        awardedGrantsValue: {
          title: '$4.4+ Trillion (USD)',
          content: 'Worth of awarded grants',
        },
        fundersTotal: {
          title: '16,000+',
          content: 'Government and private funding organizations',
        },
        timeStamp: 'Data as of July 2024',
      },
      benefits: {
        researchers: {
          sectionTitle: 'Researcher Benefits',
          benefitsList: [
            'Discover funding',
            'Smart funding opportunity recommendations, email alerts on deadlines, and saved searches',
            'Map the funding landscape  in various areas of research and geographies',
            'Insights into funders and their previously awarded grants for competitive intelligence',
          ],
        },
        researchSupportOffice: {
          sectionTitle: 'Research Support Office Benefits',
          benefitsList: [
            'Coverage of international and national funding opportunities with limited submission indicators and submission deadlines',
            'Upload and share your own internal awards',
            'Move funding opportunity records into your preferred tracking system using available exports and programming APIs',
            'One-click transfer of Funding Opportunities directly into the Pure CRIS/Research Information Management system to save time tracking and setting up grant applications',
            "Administration supported by Elsevier for ad-hoc consultancy and to schedule researcher/student trainings through Elsevier's regional Customer Consultants",
          ],
        },
      },
      valueProposition: {
        sectionTitle: 'Funding Institutional offers a unique value to institutions',
        dataQuality: {
          title: 'High quality data',
          content: 'Combining funding opportunities, awarded grants and funder information',
        },
        singleSolution: {
          title: 'Single solution for funding',
          content: 'A single solution that supports discovery, funding insight and decision-making',
        },
        elsevierEcosystem: {
          title: 'The Elsevier research ecosystem',
          content: 'Integration with unique Elsevier assets, such as Pure, Scopus, Scival and  the Elsevier Fingerprint Engine',
        },
      },
    },
    home: {
      funderMap: {
        title: 'Funders Coverage',
        loadingPlaceholder: 'Loading funder coverage data...',
        loadingError: 'Sorry, something went wrong when loading the funder map.',
      },
      trendingResearch: {
        sectionTitle: 'Trending Research Areas',
        extraInfo: {
          infoText:
            "Funding Institutional offers predefined search queries to assist you in finding funding data related to specific research themes. Institutional Research Areas are defined by your institutions' administrators and editors, reflecting research priorities. The queries for UN Sustainable Development Goals and Curated Research Areas were developed in collaboration with Elsevier's Data Science teams and the research community.",
          linkText: 'Learn more',
        },
        tabs: {
          iras: {
            tabTitle: 'Institutional Research Areas',
            shortTabTitle: 'Institutional Research Areas',
            loadingPlaceholder: 'Loading Institutional Research Areas...',
            loadingError:
              'Sorry, something went wrong loading the information for the Institutional Research Areas. Please refresh the page.',
          },
          sdgs: {
            tabTitle: 'UN Sustainable Development Goals - SDGs',
            shortTabTitle: 'SDGs',
            loadingPlaceholder: 'Loading UN Sustainable Development Goals...',
            loadingError:
              'Sorry, something went wrong loading the information for the Sustainable Development Goals. Please refresh the page.',
          },
          curatedResearch: {
            tabTitle: 'Curated Research Areas',
            shortTabTitle: 'CRA',
            loadingPlaceholder: 'Loading Curated Research Areas...',
            loadingError: 'Sorry, something went wrong loading the information for the Curated Research Areas. Please refresh the page.',
          },
        },
      },
      searchBox: {
        hint: {
          fundingOpportunities: 'e.g. (civil OR soci*) AND technology',
          funders: 'e.g. NIH',
          awardedGrants: 'e.g. liver OR cirrhosis NOT cancer',
        },
        label: {
          fundingOpportunities: 'Search opportunities',
          funders: 'Search funders',
          awardedGrants: 'Search grants',
        },
        placeHolder: 'Use AND, OR and NOT to combine keywords ',
        searchTarget: {
          opportunities: 'Funding Opportunities',
          grants: 'Awarded Grants',
          funders: 'Funders',
        },
        searchButton: 'Search',
      },
      quickGuide: {
        sectionTitle: 'Quick Guide',
        content: 'Get a quick overview of Funding Institutional, how you can use it and how it can help you.',
        linkSupportCenter: 'Support center',
        linkQuickStartGuide: 'Quick Start Guide',
        linkUserManual: 'User manual',
      },
      shortcuts: {
        suggestions: {
          title: 'Suggestions',
          description: 'Connect your Scopus profile and view funding suggestions based on your publications.',
          linkText: 'View suggestions',
        },
        grantsData: {
          title: 'Analyze grants data',
          description: 'Access insightful information about millions of grants awarded all over the world.',
          linkText: 'Analyze grants data',
        },
        trackedOpportunities: {
          title: 'Tracked opportunities',
          description: 'Track relevant funding opportunities to easily monitor them for changes or approaching deadlines.',
          linkText: 'Tracked opportunities',
        },
      },
      reseacherAcademy: {
        sectionTitle: 'Researcher Academy',
        viewMore: 'View more',
        step1: {
          title: 'How to secure funding - ECR edition',
          content: 'The first step to funding your research or getting the dream fellowship is writing the perfect application.',
          videoDuration: '1.5 h',
          videoLabel: 'FUNDING',
        },
        step2: {
          title: 'Funding Hacks for Researchers',
          content: 'Top tips from the people who really understand what it takes to write a successful grant application.',
          videoDuration: '1 h',
          videoLabel: 'FUNDING',
        },
      },
      newsAndUpdates: {
        sectionTitle: 'News and Updates',
        viewMore: 'View more',
        contentUpdates: {
          title: 'Quarterly content updates',
          content1:
            'We have been continuously expanding our coverage of funding data by incorporating over 150 new funders globally in the past months. This quarter, we furthered our expansion by including more than 100 smaller funders from Australia and New Zealand. Additionally, we have added four new funders from India, 14 smaller local foundations from Denmark, and one new Romanian funder to our database.',
          content2: `We highly value your input and would love to hear which specific funders you would like to see included in Funding Institutional. For suggestions regarding funders or in case of any issues, please reach out to our Support Center!`,
        },
        featureUpdates: {
          title: 'Feature updates',
          update1: {
            content1: `We're excited to introduce the new Institutional Research Areas feature in Funding Institutional! This feature offers quick access to curated collections of active funding opportunities tailored to your institution's strategic interests.`,
            content2: `Institutional Admins or Editors can customize search criteria and publish them as Institutional Research Areas, exclusively visible to users within their institution's account. These areas can highlight key strategic research fields, showcase specific funders or programs, and more! Once the Institutional Admins or Editors set up these areas, they will be displayed on the start page within the Trending Research Areas section.`,
            content3: `Thank you for choosing Funding Institutional and stay tuned for more exciting features!`,
          },
        },
      },
    },
    search: {
      opportunities: {
        saveInstitutionalArea: {
          saveAsButton: 'Save as Institutional Research Area',
          modal: {
            modalTitle: 'Save as Institutional Research Area',
            editorsLinkText: 'Editors > Institutional research areas',
            homepageLinkText: 'Homepage',
            info: {
              1: 'You can manage all Institutional Research areas in',
              21: 'The areas you saved will be shown in the',
              22: '> Trending Research Areas > Institutional Research Areas',
              3: 'You can only add up to 20 areas.',
            },
            searchCriteria: 'Search criteria',
            errors: {
              alreadyExists: 'The saved area name already exists.',
              alreadyExistsHelp: 'You can manage all Institutional Research areas in LINK_PLACEHOLDER.',
              exceedsMaximum20: 'The number of areas cannot exceed 20',
              exceedsMaximum20Help: 'Go to LINK_PLACEHOLDER to edit or delete area.',
              noNameProvided: 'Please provide a name for the area',
            },
            feedbackNotifications: {
              success: 'Institutional Research Area saved.',
              error: 'Something went wrong saving your Institutional Research Area. Please try again.',
            },
          },
        },
        searchFilters: {
          forthcoming: 'Show only forthcoming opportunities',
          showExpired: 'Show opportunities with expired deadlines',
          submissionStatus: 'Submission status',
          lastUpdateDate: {
            filterTitle: 'Show only new opportunities added since:',
            tagTitle: 'New opportunities since',
          },
        },
      },
      // awardedGrants:
      // funders:
    },
    myFunding: {
      userFeedback: {
        deleted: {
          success: 'has been deleted successfully',
        },
      },
      trackedOpportunities: {
        title: {
          singular: 'Tracked opportunity',
          plural: 'Tracked opportunities',
        },
        groupActions: {
          share: {
            title: 'Share opportunities list',
            // modal after clicking on share button
            shareButton: 'Share',
            description: {
              1: 'Create a public page to the complete list of opportunities in this group.',
              2: 'The list is updated automatically, while only you can make changes to the group.',
            },
            feedback: {
              success: 'Shared successfully',
              successMessage: 'It may take a few minutes for this group to be received.',
            },
          },
          delete: {
            button: { title: 'Delete group', tooltipTitle: 'Delete group', tooltipText: 'Opportunities will remain tracked.' },
            // modal after clicking on delete button
            confirmDelete: {
              1: 'Are you sure you want to delete the group', // insert groupName
              2: 'The opportunities in that group will remain tracked and appear both under "All tracked" and "Not grouped" or other groups they are assigned to.',
            },
          },
          rename: {
            title: 'Rename group',
            success: 'Group has been renamed successfully',
            error: 'Please enter a different name',
            newGroupName: 'Group name',
          },
        },
        removeFromGroup: {
          removeButton: 'Remove from group',
          modalTitle: 'Remove from group',
          modalText1: 'Are you sure you want to remove',
          modalText2: 'from the',
          modalSubtext: '* The opportunity will be removed from this group only, it will remain tracked.',
          feedback: {
            successBody: 'Opportunity successfully removed from Tracked Group.',
            successHeading: 'Removal success',
            errorBody: 'Sorry, something went wrong removing the opportunity from the Tracked Group. Please try again.',
            errorHeading: 'Removal error',
          },
        },
        empty: {
          title: 'You have no tracked opportunities.',
          instructions: {
            1: 'You can track opportunities and set alerts to receive updates.',
            2: 'Tracked opportunities can be organized into groups.',
            3: 'Groups of tracked opportunities can be shared via a link.',
          },
          ctaButton: 'Search opportunities',
        },
        emptyGroup: 'This group is currently empty.\nTrack opportunities to assign them to groups.',
        sidebar: {
          allTracked: 'All tracked',
          notGrouped: 'Not grouped',
        },
      },
      savedSearches: {
        actions: {
          manageAlerts: {
            title: 'Manage alerts',
            action: 'Set alerts',
            actionDescription: 'Send new opportunities that match these criteria via email.',
            alertsFrequency: 'Frequency',
            feedback: {
              success1: 'Alerts of',
              success2: 'have been updated successfully.',
            },
          },
          delete: {
            title: 'Delete saved search',
            confirmation1: 'Are you sure you want to delete',
            confirmation2: 'from your saved searches?',
          },
          share: {
            title: 'Share saved search',
            description: 'Saved searches can be shared internally with other users in your institution.',
            close: 'Close',
          },
        },
        pageDescription: 'Saved searches',
        pageDescriptionSide:
          'Saved searches provide quick access to collections of active opportunities that match a set of search criteria.\n\nYou can also set an alert to receive email notifications about new opportunities or share your saved search via a link within your institution.',
        empty: {
          // when there are no saved searches yet
          createFirstSearch: 'Set up your first search',
          pageDescription: 'You have no saved searches.',
          instructions: {
            1: 'To create a saved search:',
            2: 'After running an opportunity search, click Save search on the top of the search results.',
            3: 'Name your search and save it.',
            4: 'You can set an alert to receive email notifications about new opportunities that match your search criteria.',
          },
        },
        tableHeaders: {
          actions: 'Actions',
          alert: 'Alert',
          opportunities: 'Opportunities',
          savedAs: 'Saved as',
        },
      },
      sentToPure: {
        emptyLabel: {
          // specific Pure label is empty
          1: "There's currently no opportunity with the label", // add label name
          2: 'Use labels when sending opportunities to Pure so that you can find them in Pure easily.',
        },
        empty: {
          // no opportunities sent to Pure yet
          title: 'You have not sent any opportunity to Pure yet.',
          instructions: {
            1: 'You can send opportunities from Funding Institutional to Pure.',
            2: 'Opportunities are organized via labels.',
          },
        },
        actions: {
          deleteLabel: {
            title: 'Delete label',
            confirmDelete: {
              1: 'Are you sure you want to permanently delete the label',
              2: "You won't be able to find this label in Pure anymore.",
            },
          },
          unlabel: {
            title: 'Unlabel',
            confirmUnlabel: {
              singular: 'Unlabel this opportunity from', // insert "name"
              plural: 'Unlabel selected opportunities from', // insert "name"
            },
            feedback: {
              success: {
                messageStart: 'You have successfully unlabelled', // insert number
                singular: 'opportunity.',
                plural: 'opportunities.',
              },
            },
          },
        },
      },
    },
    suggestions: {
      authorProfiles: {
        // recommended --> publication
        publication: 'View on Scopus',
      },
    },
    editors: {
      institutionalResearchAreas: {
        iras: 'Institutional Research Areas',
        irasTabTitle: 'Institutional research areas', // for consistency - tab titles only have their first letter uppercase
        areYouSureYouWantToDelete: 'Are you sure you want to delete',
        fromYourIRAs: 'from your Institutional Research Areas?',
        deleteItem: 'Delete Institutional Research Area?',
        deleteMultiple: 'Delete selected Institutional Research Areas?',
        delete: 'Delete',
        rename: 'Rename',
        renameInput: 'Area title',
        renameTitle: 'Rename Institutional Research Area',
        error: 'Error',
        success: 'Success',
        deleteSuccess: 'Institutional Research Area(s) has been deleted successfully.',
        deleteError: 'An error occurred while deleting the Institutional Research Area(s).',
        renameSuccess: 'Institutional Research Area has been renamed successfully.',
        renameError: 'An error occurred while renaming the Institutional Research Area.',
        reorderError: 'An error occurred while reordering the Institutional Research Areas.',
        noResults: {
          title: 'You have no Institutional Research Areas saved.',
          subtitle: 'To create an Institutional Research Area:',
          li1: 'After running an opportunity search, click "Save as Institutional Research Area" at the top right corner above the search results.',
          li2: 'Name your area to save it.',
          linkLabel: 'Set up your first area',
        },
        sidebar: {
          p1: 'Institutional Research Areas provide quick access to collections of active funding opportunities that match a set of search criteria set up by and Institutional Admin or Editor.',
          p2: "Institutional Research Areas will appear on the start page within the Trending Research Areas section and will be visible exclusively to users of your institution's account. These areas serve as a means to emphasize your institution's key strategic research fields, showcase specific funders or programs, and more!",
        },
      },
    },
  },

  /**
   * Components used across pages
   */
  components: {
    blueUserTooltip: {
      signInToUse: 'You need to sign in to use this feature',
      signIn: 'Sign in',
      createAccount: 'create account',
    },
    paginationAuth: {
      signInToUse: 'You can only view 20 items before logging in.',
      signIn: 'Log in now',
    },
    footer: {
      about: 'About Funding Institutional',
      terms: 'Terms and conditions',
      termsTitle: 'View the terms and conditions of Funding Institutional (opens in a new window)',
      privacy: 'Privacy statement',
      privacyTitle: 'View the privacy policy of Funding Institutional (opens in a new window)',
      support: 'Support',
      cookiePolicy: 'Cookie Policy',
      acknowledgements: 'Acknowledgements',
      elsevier: 'Elsevier B.V.',
      copyrightNotice: {
        start: 'All content on this site: Copyright © ',
        end: ', its licensors, and contributors. All rights are reserved, including those for text and data mining, AI training, and similar technologies. For all open access content, the Creative Commons licensing terms apply.',
      },
      cookies: 'We use cookies to help provide and enhance our service and tailor content.',
      cookieSettings: 'Cookie settings',
    },
    opportunitiesToolbar: {
      track: {
        success: 'Tracked successfully',
        error: {
          title: 'Tracking request failed',
          message: 'Please refresh the page and try again. If the problem persists, ',
          linkText: 'please contact the Support Center.',
        },
      },
      untrack: {
        success: { singular: 'Opportunity has been untracked successfully.', plural: 'Opportunities have been untracked successfully.' },
        warning: {
          singular:
            'The untracked opportunity will be removed from My Funding and you will no longer receive updates and deadline alerts for those opportunities.',
          plural:
            'Untracked opportunities will be removed from My Funding and you will no longer receive updates and deadline alerts for those opportunities.',
        },
      },
      share: {
        placeholder: 'Add a message (optional)',
        previouslySharedWith: 'Previously shared with: ',
        success: {
          title: 'Shared successfully',
          message1: 'It may take a few minutes for',
          message2: 'to be received.',
          singleOpportunity: 'this opportunity',
          multipleOpportunities: 'these opportunities',
        },
        error: {
          problem: 'Sorry, something went wrong.',
          suggestion: 'Please check the email address or try to share again later.',
        },
      },
      // sendToPure:
      // addNotes
      // exportXLS
      // exportCSV
    },
    modals: {
      /**
       * shared across multiple modals or 'common sense' buttons
       */
      common: {
        cancel: 'Cancel',
        delete: 'Delete',
        save: 'Save',
        rename: 'Rename',
        remove: 'Remove',
      },
    },
    toolbar: {
      selected: 'selected',
    },
  },
} as const;
