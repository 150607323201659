import { createAction, createReducer } from 'redux-act';

import {
  setIsLoadingResults,
  selectItem,
  deselectItems,
  selectAllPage,
  setSelectedItems,
  deselectAll,
  pushSearchUrlToHistory,
  isFileExporting,
  exportFileFailed,
  updateOpportunitiesWithNotes,
  setCurrentPage,
  setSelectAllContext,
} from './common.js';
import { opportunityNoteModalActions } from './opportunityNoteModal.js';
import { selectAllActions } from './selectAll.js';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const trackOpportunityActions = {
  fetchTrackedOpp: createAction('fetch tracked opportunities'),
  untrackOpportunity: createAction('untrack opportunity'),
  untrackOpportunities: createAction('untrack opportunities'),
  unlabelOpportunities: createAction('unlabel opportunities'),

  setIsLoadingResults: createAction('set loading results'),
  setCurrentPage: createAction('set current page'),
  setUntrackModalOpen: createAction('set untrack modal open'),
  setSelectedSortOption: createAction('set sort option'),
  setSortOptions: createAction('set sort options'),
  resetSearchQuery: createAction('reset SearchQuery'),

  loadOpportunities: createAction('Load opportunities'),
  resetResults: createAction('reset results'),

  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  setSelectAllContext: createAction('Set select all context'),

  pushSearchUrlToHistory: createAction('push search url to history'),

  setTrackedOpp: createAction('set tracked opportunities'),
  setUntrackStatus: createAction('set untrack success or fail'),
  setUnlabelStatus: createAction('set unlabel success or fail'),

  exportFile: createAction('exporting file'),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),

  setGroup: createAction('set group'),
  setNewGroupName: createAction('set new group name'),
  setIsSettingNewGroupName: createAction('set is loading when setting new name for group'),
  setRenameGroupStatus: createAction('set status of setting new name for group'),

  setShareGroupModalIsOpen: createAction('set share group modal open'),
  setIsSharingInProgress: createAction('set is shring group'),
  setSharedList: createAction('set shared email list for the group'),
  shareGroup: createAction('share the group'),
  fetchSharedEmailsByGroup: createAction('share the group'),
  setSharingStatus: createAction('share the group'),
  fetchGroupsByOppId: createAction('fetch groups by opp id'),
  setGroupsByOppId: createAction('fetch groups by opp id'),
  setAllCardinality: createAction('set all group cardinality'),
  setIsPureIntegrated: createAction('set if pure is integrated'),
  setIsPureLabelName: createAction('set if pure label'),
};

const RECENT_TRACKED_SORTING = {
  label: 'Recent tracked',
  value: 'recent',
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
  sortOptions: [
    {
      label: 'Deadline (soonest first)',
      value: 'deadline',
    },
    {
      label: 'Deadline (latest first)',
      value: '-deadline',
    },
    {
      label: 'Amount (highest first)',
      value: '-amount',
    },
    RECENT_TRACKED_SORTING,
  ],
  selectedSortOption: RECENT_TRACKED_SORTING,
};

const loadOpportunitiesReducer = (state, payload) => {
  const results = payload.hits.hits.map((item) => {
    return {
      ...item._source,
      score: item._score,
    };
  });
  return Object.assign({}, state, {
    totalResults: payload.hits.total.value,
    results,
  });
};

const resetResults = (state) => {
  return { ...state, totalResults: 0, results: [] };
};

const setUntrackModalOpen = (state, isUntrackModalOpen) => ({
  ...state,
  isUntrackModalOpen,
});

const setSelectedSortOption = (state, selectedSortOption) => ({
  ...state,
  searchQuery: {
    ...state.searchQuery,
    selectedSortOption,
  },
});

const setSortOptions = (state, sortOptions) => ({
  ...state,
  searchQuery: {
    ...state.searchQuery,
    sortOptions,
  },
});

const resetSearchQuery = (state) => ({
  ...state,
  searchQuery: SEARCH_QUERY_DEFAULT,
});

const setUntrackStatus = (state, untrackStatus) => ({
  ...state,
  untrackStatus,
});

const setUnlabelStatus = (state, unlabelStatus) => ({
  ...state,
  unlabelStatus,
});

const setTrackedOpp = (state, { trackedOpportunities, totalResults }) => ({
  ...state,
  trackedList: trackedOpportunities,
  totalResults,
});

const setIsSettingNewGroupName = (state, isSettingNewGroupName) => ({
  ...state,
  isSettingNewGroupName,
});

const setRenameGroupStatus = (state, renameGroupStatus) => ({
  ...state,
  renameGroupStatus,
});

const setShareGroupModalIsOpen = (state, isModalOpen) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    isModalOpen,
  },
});

const setIsSharingInProgress = (state, isSharingInProgress) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    isSharingInProgress,
  },
});

const setSharedList = (state, sharedList) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    sharedList,
  },
});

const setSharingStatus = (state, sharingStatus) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    sharingStatus,
  },
});

const setGroupsByOppId = (state, groupList) => ({
  ...state,
  groupList,
});

const setAllCardinality = (state, allCardinality) => ({
  ...state,
  allCardinality,
});

const setIsPureIntegrated = (state, isPureIntegrated) => ({
  ...state,
  isPureIntegrated,
});

const setIsPureLabelName = (state, pureLabelName) => ({
  ...state,
  pureLabelName,
});
/* Reducers */
export const trackOpportunityReducer = createReducer(
  {
    [trackOpportunityActions.loadOpportunities]: loadOpportunitiesReducer,
    [trackOpportunityActions.resetResults]: resetResults,
    [trackOpportunityActions.setIsLoadingResults]: setIsLoadingResults,
    [trackOpportunityActions.setCurrentPage]: setCurrentPage,
    [trackOpportunityActions.setUntrackModalOpen]: setUntrackModalOpen,
    [trackOpportunityActions.setSelectAllContext]: setSelectAllContext,
    [trackOpportunityActions.setSelectedSortOption]: setSelectedSortOption,
    [trackOpportunityActions.setSortOptions]: setSortOptions,
    [trackOpportunityActions.resetSearchQuery]: resetSearchQuery,

    [trackOpportunityActions.selectItem]: selectItem,
    [trackOpportunityActions.deselectItems]: deselectItems,
    [trackOpportunityActions.deselectAll]: deselectAll,
    [trackOpportunityActions.selectAllPage]: selectAllPage,
    [trackOpportunityActions.setSelectedItems]: setSelectedItems,

    [trackOpportunityActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,

    [trackOpportunityActions.setTrackedOpp]: setTrackedOpp,
    [trackOpportunityActions.setUntrackStatus]: setUntrackStatus,
    [trackOpportunityActions.setUnlabelStatus]: setUnlabelStatus,
    [trackOpportunityActions.isFileExporting]: isFileExporting,
    [trackOpportunityActions.exportFileFailed]: exportFileFailed,

    [trackOpportunityActions.setIsSettingNewGroupName]: setIsSettingNewGroupName,
    [trackOpportunityActions.setRenameGroupStatus]: setRenameGroupStatus,

    [trackOpportunityActions.setShareGroupModalIsOpen]: setShareGroupModalIsOpen,
    [trackOpportunityActions.setIsSharingInProgress]: setIsSharingInProgress,
    [trackOpportunityActions.setSharedList]: setSharedList,
    [trackOpportunityActions.setSharingStatus]: setSharingStatus,
    [trackOpportunityActions.setGroupsByOppId]: setGroupsByOppId,
    [trackOpportunityActions.setAllCardinality]: setAllCardinality,
    [trackOpportunityActions.setIsPureIntegrated]: setIsPureIntegrated,
    [trackOpportunityActions.setIsPureLabelName]: setIsPureLabelName,

    [opportunityNoteModalActions.updateOpportunitiesWithNotes]: updateOpportunitiesWithNotes,
    [selectAllActions.deselectAllOpportunities]: deselectAll,
  },
  {
    searchHistory: '',
    isLoadingResults: false,
    results: [],
    totalResults: 0,
    isUntrackModalOpen: false,
    selectedItems: {},
    selectAllContext: 'all',
    searchQuery: SEARCH_QUERY_DEFAULT,
    group: null,
    groupList: [],
    isLoadingGroupsByOppId: false,
    shareGroupModal: {
      isModalOpen: false,
      isSharingInProgress: false,
      sharedList: [],
      sharingStatus: null,
    },
    trackedList: [],
    currentPage: 0,
    untrackStatus: null,
    unlabelStatus: null,
    trackStatus: null,
    exportFailed: null,
    isSettingNewGroupName: false,
    renameGroupStatus: null,
    allCardinality: 0,
    isPureIntegrated: false,
    pureLabelName: '',
  },
);
