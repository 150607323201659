import { createAction, createReducer } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const opportunityNoteModalActions = {
  setOpportunitiesIds: createAction('populate opportunity note modal state'),
  closeModal: createAction('clear opportunity note modal'),
  saveOpportunityNote: createAction('save opportunity note'),
  setIsSaving: createAction('set opportunity note isSaving'),
  setNoteWasSavedSuccessfully: createAction('set opportunity note was saved successfully', (payload) => payload),
  updateOpportunitiesWithNotes: createAction('update opportunities that has notes after save'),

  openOpportunityNoteModal: createAction('open opportunity note modal', (payload) => payload),
  openNewOpportunityNoteModal: createAction('open new opportunity note modal'),
  setIsFetchingOpportunityNote: createAction('set data is fetching for opportunity note modal'),
  setOpportunitiesWithNotesAmount: createAction('set amount of opportunities with notes'),
};

export const initialState = {
  selectedOpportunities: null,
  isSaving: false,
  noteWasSavedSuccessfully: false,
  isFetchingOpportunityNote: null,
  opportunitiesWithNotesAmount: null,
};

const setOpportunitiesIds = (state, selectedOpportunities = []) => ({
  ...state,
  selectedOpportunities,
});

const closeModal = () => initialState;

const setIsSaving = (state, isSaving) => ({
  ...state,
  isSaving,
});

const setIsFetchingOpportunityNote = (state, isFetchingOpportunityNote) => ({
  ...state,
  isFetchingOpportunityNote,
});

const setOpportunitiesWithNotesAmount = (state, opportunitiesWithNotesAmount) => ({
  ...state,
  opportunitiesWithNotesAmount,
});

const setNoteWasSavedSuccessfully = (state, noteWasSavedSuccessfully) => ({
  ...state,
  noteWasSavedSuccessfully,
});

export const opportunityNoteModalReducer = createReducer(
  {
    [opportunityNoteModalActions.setOpportunitiesIds]: setOpportunitiesIds,
    [opportunityNoteModalActions.closeModal]: closeModal,
    [opportunityNoteModalActions.setIsSaving]: setIsSaving,
    [opportunityNoteModalActions.setNoteWasSavedSuccessfully]: setNoteWasSavedSuccessfully,
    [opportunityNoteModalActions.setIsFetchingOpportunityNote]: setIsFetchingOpportunityNote,
    [opportunityNoteModalActions.setOpportunitiesWithNotesAmount]: setOpportunitiesWithNotesAmount,
  },
  initialState,
);
