import { createAction, createReducer } from 'redux-act';

import {
  setIsLoadingResults,
  setCurrentPage,
  selectItem,
  deselectItems,
  deselectAll,
  selectAllPage,
  setSelectedItems,
  setSelectAllContext,
} from './common.js';
import { OPPORTUNITIES_SORT_OPTIONS } from '@fi/constants';
import { selectAllActions } from './selectAll.js';

export const internalOpportunitiesActions = {
  setCurrentPage: createAction('Set current page for internal opportunities', (payload) => payload),
  fetchOpportunities: createAction('Fetch internal opportunities'),
  loadOpportunities: createAction('Load internal opportunities'),
  setIsLoadingResults: createAction('set is loading internal opportunities'),

  // Select actions - common almost to all listing data sets
  selectItem: createAction('Select internal opportunity', (payload) => payload),
  deselectItems: createAction('Deselect internal opportunity', (payload) => payload),
  deselectAll: createAction('Deselect all internal opportunities'),
  selectAllPage: createAction('Select all internal opportunities on current page'),
  selectAllSearchResult: createAction('Select all internal opportunities'),
  setSelectedItems: createAction('set selected internal opportunities'),
  setSelectAllContext: createAction('Set select all context for internal opportunities'),

  // Opportunity actions
  updateStatus: createAction('Update internal opportunity status', (payload) => payload),
  addPendingStatusRequest: createAction('Add pending internal opportunity request'),
  removePendingStatusRequest: createAction('Remove pending internal opportunity request'),
  updateActivationStatus: createAction('Update internal opportunity active status in store'),
  updatePublishedStatus: createAction('Update internal opportunity published status in store'),
  deleteOpportunity: createAction('Delete internal opportunity', (payload) => payload),
  removeOpportunityFromStore: createAction('Remove internal opportunity from store', (payload) => payload),

  openDeleteConfirmationModal: createAction('open delete notes confirmation modal', (payload) => payload),
  closeDeleteConfirmationModal: createAction('close delete notes confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while note is deleting'),

  opportunityHasBeenDeleted: createAction('Set opportunityHasBeenDeleted flag value', (payload) => payload),
};

const loadInternalOpportunitiesReducer = (state, payload) => {
  const results = payload.hits.hits.map((item) => {
    return {
      id: item._source.id || item._id,
      ...item._source,
      score: item._score,
    };
  });

  return {
    ...state,
    totalResults: payload.hits.total.value,
    results,
  };
};

const addPendingStatusRequest = (state, opportunityId) => ({
  ...state,
  pendingStatusRequests: [...state.pendingStatusRequests, opportunityId],
});

const removePendingStatusRequest = (state, opportunityId) => ({
  ...state,
  pendingStatusRequests: state.pendingStatusRequests.filter((id) => id !== opportunityId),
});

const updateActivationStatus = (state, payload) => {
  const { opportunityId, status } = payload;

  const results = state.results.map((opportunity) => {
    if (opportunity.id !== opportunityId) {
      return opportunity;
    }

    return {
      ...opportunity,
      status,
    };
  });

  return {
    ...state,
    results,
  };
};

const updatePublishedStatus = (state, opportunityId) => {
  const results = state.results.map((opportunity) => {
    if (opportunity.id !== opportunityId) {
      return opportunity;
    }

    return {
      ...opportunity,
      extensions: {
        ...opportunity.extensions,
        published: true,
      },
    };
  });

  return {
    ...state,
    results,
  };
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
  sortOptions: OPPORTUNITIES_SORT_OPTIONS,
  selectedSortOption: OPPORTUNITIES_SORT_OPTIONS[0],
};

export const initialState = {
  isLoadingResults: false,
  results: [],
  totalResults: 0,
  searchQuery: SEARCH_QUERY_DEFAULT,
  page: {
    title: '',
    subjects: '',
  },
  pendingStatusRequests: [],
  opportunityHasBeenDeleted: false,
  deleteConfirmationModal: {
    opportunityId: null,
    isDeleting: false,
    isOpen: false,
  },

  selectedItems: {},
  selectedDraftItems: {},
  selectAllContext: 'all',
};

const getAllDraftsIds = (opportunities) =>
  opportunities.reduce((acc, opportunity) => {
    const { extensions } = opportunity;
    if (extensions && !extensions.published) {
      acc.push(opportunity.id);
    }
    return acc;
  }, []);

const selectItemMiddleware = (state, selectedId) => {
  const nextState = selectItem(state, selectedId);

  const draftIds = getAllDraftsIds(nextState.results);

  const isDraftSelected = draftIds.includes(selectedId);

  // add selected item to selectedDraftItems only if it's draft
  const selectedDraft = isDraftSelected ? { [selectedId]: selectedId } : undefined;

  return {
    ...nextState,
    selectedDraftItems: {
      ...nextState.selectedDraftItems,
      ...selectedDraft,
    },
  };
};

const deselectItemsMiddleware = (state, deselectedIds) => {
  const nextState = deselectItems(state, deselectedIds);

  const selectedDraftItems = Object.assign({}, state.selectedDraftItems);
  deselectedIds.forEach((deselectedId) => {
    if (selectedDraftItems[deselectedId]) {
      delete selectedDraftItems[deselectedId];
    }
  });

  return {
    ...nextState,
    selectedDraftItems,
  };
};

const deselectAllMiddleware = (state) => {
  const nextState = deselectAll(state);
  return {
    ...nextState,
    selectedDraftItems: {},
  };
};

const removeOpportunityFromStore = (state, opportunityId) => {
  const opportunities = state.results.filter((opportunity) => opportunity.id !== opportunityId);

  return {
    ...state,
    results: opportunities,
  };
};

const openDeleteConfirmationModal = (state, opportunityId) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    opportunityId,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    opportunityId: null,
    isOpen: false,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

const setOpportunityHasBeenDeleted = (state, opportunityHasBeenDeleted) => ({
  ...state,
  opportunityHasBeenDeleted,
});

export const internalOpportunitiesReducer = createReducer(
  {
    [internalOpportunitiesActions.setIsLoadingResults]: setIsLoadingResults,
    [internalOpportunitiesActions.loadOpportunities]: loadInternalOpportunitiesReducer,
    [internalOpportunitiesActions.setCurrentPage]: setCurrentPage,

    [internalOpportunitiesActions.selectItem]: selectItemMiddleware,
    [internalOpportunitiesActions.deselectItems]: deselectItemsMiddleware,
    [internalOpportunitiesActions.deselectAll]: deselectAllMiddleware,
    [internalOpportunitiesActions.selectAllPage]: selectAllPage,
    [internalOpportunitiesActions.setSelectedItems]: setSelectedItems,
    [internalOpportunitiesActions.setSelectAllContext]: setSelectAllContext,
    [internalOpportunitiesActions.addPendingStatusRequest]: addPendingStatusRequest,
    [internalOpportunitiesActions.removePendingStatusRequest]: removePendingStatusRequest,
    [internalOpportunitiesActions.updateActivationStatus]: updateActivationStatus,
    [internalOpportunitiesActions.updatePublishedStatus]: updatePublishedStatus,
    [internalOpportunitiesActions.removeOpportunityFromStore]: removeOpportunityFromStore,

    [internalOpportunitiesActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [internalOpportunitiesActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [internalOpportunitiesActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
    [internalOpportunitiesActions.opportunityHasBeenDeleted]: setOpportunityHasBeenDeleted,

    [selectAllActions.deselectAllOpportunities]: deselectAllMiddleware,
  },
  initialState,
);
