import dayjs from 'dayjs';

const DATE_FORMAT = 'DD-MMM-YYYY';
const API_DATE_FORMAT = 'YYYY-MM-DD';

export const formatDate = (date: string | number | Date | dayjs.Dayjs | null | undefined) => {
  return dayjs(date).format(DATE_FORMAT);
};

export const formatApiDate = (date: string | number | Date | dayjs.Dayjs | null | undefined) => {
  return dayjs(date).format(API_DATE_FORMAT);
};
