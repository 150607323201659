import { ROUTES } from '@fi/constants';

export const MODALS = {
  SEARCH: {
    SAVE_SEARCH: 'SAVE_SEARCH_MODAL',
  },
};

export const ADOBE_ANALYTICS_PAGETREE = [
  // Modals - for some modals we need to trigger page views
  {
    path: MODALS.SEARCH.SAVE_SEARCH,
    name: 'institutional:search:opportunities:saveSearchModal',
    type: 'SP-SS',
  },

  // Pages
  {
    path: '/',
    name: 'institutional:home',
    type: 'SP-SR',
  },
  {
    path: ROUTES.SEARCH,
    name: 'institutional:search:opportunities',
    type: 'SP-SD',
  },
  {
    path: ROUTES.SEARCH_OPPORTUNITIES,
    name: 'institutional:search:opportunities',
    type: 'SP-SD',
  },
  {
    path: ROUTES.OPPORTUNITIES,
    name: 'institutional:search:opportunities',
    type: 'SP-SD',
  },
  {
    path: ROUTES.SEARCH_AWARDS,
    name: 'institutional:search:awards',
    type: 'SP-SD',
  },
  {
    path: ROUTES.AWARDS,
    name: 'institutional:search:awards',
    type: 'SP-SD',
  },
  {
    path: ROUTES.FUNDERS,
    name: 'institutional:search:funders',
    type: 'SP-SD',
  },
  {
    path: ROUTES.SEARCH_FUNDERS,
    name: 'institutional:search:funders',
    type: 'SP-SD',
  },
  {
    path: `${ROUTES.OPPORTUNITY_DETAILS}/details`,
    name: 'institutional:opportunity',
    type: 'CP-CT',
  },
  {
    path: `${ROUTES.OPPORTUNITY_DETAILS}/similar-opps`,
    name: 'institutional:opportunity:similar-opportunities',
    type: 'CP-CT',
  },
  {
    path: `${ROUTES.OPPORTUNITY_DETAILS}/should-apply`,
    name: 'institutional:opportunity:should-apply',
    type: 'CP-CT',
  },
  {
    path: ROUTES.FUNDER_DETAILS_PAGE,
    name: 'institutional:funders',
    type: 'CP-CT',
  },
  {
    path: ROUTES.FUNDER_DETAILS_PAGE_TAB,
    name: 'institutional:funders',
    type: 'CP-CT',
  },
  {
    path: ROUTES.AWARD_DETAILS,
    name: 'institutional:awards',
    type: 'CP-CT',
  },
  {
    path: ROUTES.AWARDS_ANALYTICS,
    name: 'institutional:awards:analytics',
    type: 'CP-CT',
  },
  {
    path: ROUTES.MYFUNDING,
    name: 'institutional:my-funding:saved-search',
    type: 'SP-SS',
  },
  {
    path: ROUTES.MYFUNDING_SAVED_SEARCHES,
    name: 'institutional:my-funding:saved-search',
    type: 'SP-SS',
  },
  {
    path: ROUTES.MYFUNDING_TRACKED_OPPORTUNITIES,
    name: 'institutional:my-funding:favourite-opportunities',
    type: 'AP-MY',
  },
  {
    path: ROUTES.EDITORS,
    name: 'institutional:editors:notes:opportunities',
    type: 'NP-GP',
  },
  {
    path: ROUTES.EDITOR_OPPORTUNITY_NOTES,
    name: 'institutional:editors:notes:opportunities',
    type: 'NP-GP',
  },
  {
    path: ROUTES.EDITOR_FUNDER_NOTES,
    name: 'institutional:editors:notes:funders',
    type: 'NP-GP',
  },
  {
    path: ROUTES.EDITOR_INTERNAL_OPPORTUNITIES,
    name: 'institutional:editors:internalopportunities',
    type: 'NP-GP',
  },
  {
    path: ROUTES.EDITOR_CREATE_INTERNAL_OPPORTUNITIES,
    name: 'institutional:editors:internalopportunities:create',
    type: 'CP-CA',
  },
  {
    path: ROUTES.EDITOR_EDIT_INTERNAL_OPPORTUNITIES,
    name: 'institutional:editors:internalopportunities:edit',
    type: 'CP-CA',
  },
  {
    path: ROUTES.PUBLIC_GROUP,
    name: 'institutional:public:group',
    type: 'AP-MY',
  },
  {
    path: ROUTES.MYFUNDING_GROUPS,
    name: 'institutional:my-funding:favourite-opportunities',
    type: 'AP-MY',
  },
  {
    path: ROUTES.PROFILE,
    name: 'institutional:profile:personal-details:overview',
    type: 'AP-EN',
  },
  {
    path: ROUTES.PROFILE_ACCOUNT,
    name: 'institutional:profile:personal-details:overview',
    type: 'AP-EN',
  },
  {
    path: ROUTES.UNSUBSCRIBE_SAVED_SEARCH,
    name: 'institutional:unsubscribe',
    type: 'AP-AL',
  },
  {
    path: ROUTES.SUCCESSFULLY_UNSUBSCRIBED_SAVED_SEARCH,
    name: 'institutional:unsubscribe:success',
    type: 'AP-AL',
  },
  {
    path: ROUTES.UNSUBSCRIBE_ALERTS,
    name: 'institutional:unsubscribe:alerts',
    type: 'AP-AL',
  },
  {
    path: ROUTES.SUCCESSFULLY_UNSUBSCRIBE_ALERTS,
    name: 'institutional:unsubscribe:alerts:success',
    type: 'AP-AL',
  },
  {
    path: ROUTES.SUGGESTIONS,
    name: 'institutional:suggestions',
    type: 'NP-GP',
  },
  {
    path: ROUTES.SUGGESTIONS_DETAILS_PAGE,
    name: 'institutional:suggestions:detail',
    type: 'NP-GP',
  },
];
