import { apiGet, apiPost, apiPut } from '../requests.ts';
import { ROUTES } from '@fi/constants';

const getUser = (): Promise<any> => apiGet('/user/info');

const logout = () => {
  window.location.replace(ROUTES.SIGN_OUT);
};

const getSavedSearchesList = (pageSize: number, page: number): Promise<any> =>
  apiGet(`/api/user/searches?pageSize=${pageSize}&page=${page}`);

const markSavedSearchAsSeen = (savedSearchId: string): Promise<any> => apiPut(`/api/user/searches/${savedSearchId}/seen`);

const getSavedSearchById = (savedSearchId: string): Promise<any> => apiGet(`/api/user/searches/${savedSearchId}`);

const createSavedSearch = (payload: any): Promise<any> => apiPost('/api/user/searches', payload);

export { getUser, logout, getSavedSearchesList, markSavedSearchAsSeen, createSavedSearch, getSavedSearchById };
