type QueryObjectFunction = (searchQuery?: any, options?: any) => any;

export function createSearchQueryObject(...functions: QueryObjectFunction[]): QueryObjectFunction {
  return (searchQuery: any, options: any) => {
    const queryObject: any = {};

    functions.forEach((fn) => {
      const queryParamObject = fn(searchQuery, options);
      Object.assign(queryObject, queryParamObject);
    });

    return queryObject;
  };
}
