import { takeLatest, call, put } from 'redux-saga/lib/effects.js';

import { apiDelete } from '../../services/requests.ts';
import { getResearchersList } from '../../services/api/recommendations.ts';
import { recommendationsActions } from '../dispatchers/recommendations.js';
import { pageActions } from '../dispatchers/page.js';

import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { LOCALE } from '@fi/constants';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchRecommendations() {
  try {
    yield put(recommendationsActions.setIsLoadingResults(true));

    let recommendations = yield getResearchersList();

    recommendations = recommendations.map((recommendation) => ({
      ...recommendation,
      author: {
        ...recommendation.author,
        isActive: recommendation.authorProfileExists,
      },
    }));

    yield put(recommendationsActions.loadRecommendations(recommendations));
  } catch (e) {
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    yield put(recommendationsActions.setIsLoadingResults(false));
  }
}

function* doDeleteRecommendation(action) {
  const { id } = action.payload;
  const url = `/api/user/recommendation-entities/${id}`;
  try {
    yield put(recommendationsActions.setIsLoadingResults(true));
    put(recommendationsActions.setIsDeletingConfirmationModal(true));
    yield call(apiDelete, url);
    yield put(
      recommendationsActions.setStatusDeleteConfirmationModal({
        status: true,
      }),
    );
    yield doFetchRecommendations();
  } catch (e) {
    yield put(
      recommendationsActions.setStatusDeleteConfirmationModal({
        status: false,
      }),
    );
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    put(recommendationsActions.setIsDeletingConfirmationModal(false));
    yield put(recommendationsActions.setIsLoadingResults(false));
  }
}

export default function* recommendationsSaga() {
  yield takeLatest(recommendationsActions.fetch, doFetchRecommendations);
  yield takeLatest(recommendationsActions.deleteRecommendation, doDeleteRecommendation);
}
