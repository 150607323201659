import { createAction, createReducer } from 'redux-act';
import { FacetUtils } from '@fi/util/FacetUtils';

import { setIsLoadingResults, setCurrentPage } from './common.js';

export const funderNotesActions = {
  fetchFunderNotes: createAction('fetch funders with notes', (payload) => payload),
  loadFunders: createAction('Load funders'),
  setCurrentPage: createAction('set current page', (payload) => payload),
  setIsLoadingResults: createAction('set is loading results'),

  deleteNoteFromList: createAction('delete note from list of funder notes', (payload) => payload),
  deleteNoteFromDetailsPage: createAction('delete note from funder details page'),
  openDeleteConfirmationModal: createAction('open delete note confirmation modal', (payload) => payload),
  closeDeleteConfirmationModal: createAction('close delete note confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while note is deleting'),
  setDeleteNoteStatus: createAction('set delete status', (payload) => payload),
  removeNoteFromStore: createAction('Remove funder note from store', (payload) => payload),

  saveNote: createAction('save funder note'),
};

/* Reducers */
const loadFunders = (state, data) => {
  const funderType = FacetUtils.getFunderTypeWithCount(data, state.funderType, FacetUtils.fundersFunderTypeGetter);
  return {
    ...state,
    funderType,
    results: data.hits.hits,
    totalResults: data.hits.total.value,
    currentPage: state.currentPage,
  };
};

/* delete confirmation modal */

const openDeleteConfirmationModal = (state, selectedFunder) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedFunder,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedFunder: null,
    isOpen: false,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
};

const initialState = {
  searchHistory: '', // latest search query
  searchQuery: SEARCH_QUERY_DEFAULT,
  results: [],
  funderType: {
    items: [],
    countMap: {},
  },
  totalResults: 0,
  isLoadingResults: false,
  deleteConfirmationModal: {
    selectedFunder: null,
    isDeleting: false,
    isOpen: false,
  },
  deleteNoteStatus: null,
};

const setDeleteNoteStatus = (state, deleteNoteStatus) => ({
  ...state,
  deleteNoteStatus,
});

const removeNoteFromStore = (state, noteId) => {
  const notes = state.results.filter((note) => note._source.orgDbId !== noteId);
  return {
    ...state,
    results: notes,
  };
};

export const funderNotesReducer = createReducer(
  {
    [funderNotesActions.setIsLoadingResults]: setIsLoadingResults,
    [funderNotesActions.loadFunders]: loadFunders,
    [funderNotesActions.setCurrentPage]: setCurrentPage,

    [funderNotesActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [funderNotesActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [funderNotesActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
    [funderNotesActions.setDeleteNoteStatus]: setDeleteNoteStatus,
    [funderNotesActions.removeNoteFromStore]: removeNoteFromStore,
  },
  initialState,
);
