/**
 * @param {string} url
 * @returns {Object.<string, string>}
 */
export const getUrlParameters = (url) => {
  const urlParams = {};
  if (url) {
    url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
      urlParams[$1] = $3;
    });
  }

  return urlParams;
};
