export const objectUrlSerialization = (obj) => {
  let str = '';
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      if (str === '') {
        str += '?';
      } else {
        str += '&';
      }
      str += `${key}=${encodeURIComponent(obj[key])}`;
    }
  });
  return str;
};
