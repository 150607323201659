import { apiDelete, apiGet, apiPost } from '../requests.ts';

export interface Payload {
  type: string;
  accountId: string;
}

export interface AffiliateResponse {
  id: number;
  type: string;
  key: string;
  accountId: string;
}

const getAccounts = () => {
  return apiGet(`/api/admin/account/_listAll`);
};

const addClient = (payload: Payload): Promise<AffiliateResponse> => {
  return apiPost(`/api/admin/account/api-consumers`, payload);
};

const getClients = (): Promise<AffiliateResponse[]> => {
  return apiGet(`/api/admin/account/api-consumers`);
};

const deleteClient = (clientId: number | string) => {
  return apiDelete(`/api/admin/account/api-consumers/${clientId}`);
};

export { addClient, getClients, deleteClient, getAccounts };
