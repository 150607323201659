import { createAction, createReducer } from 'redux-act';

export const trackModalActions = {
  openModal: createAction('start fetching the resources and open the modal', (payload) => payload),
  openNewModal: createAction('start fetching the resources and open the NEW modal'),
  closeModal: createAction('reset the modal state and close the modal'),

  setSelectedOpportunities: createAction('set list of opportunities ids to track or edit'),

  setIsModalOpen: createAction('set modal open or close', (payload) => payload),
  setIsNewModalOpen: createAction('set new modal open or closed', (payload) => payload),
  setFetching: createAction('set is fetching flag'),
  setFetchStatus: createAction('set the fetch status'),

  setTracking: createAction('set is tracking flag'),
  setTrackStatus: createAction('set the track status'),

  setAlerts: createAction('set alerts'),
  setDeadlines: createAction('set deadlines'),
  setSelectedGroups: createAction('set selectedGroup'),
  setReceivingUpdateAlerts: createAction('set receivingUpdateAlerts'),

  resetModalState: createAction('reset modal state'),

  trackOpportunities: createAction('track selected opportunities and assign to group'),
};

const initialState = {
  isOpen: false,
  isNewOpen: false,
  isFetching: false,
  isTracking: false,
  fetchStatus: null,
  trackStatus: null,
  isReceivingUpdateAlerts: null,
  selectedOpportunities: [],
  alerts: [],
  deadlines: [],
  selectedGroups: [],
};

const setIsNewModalOpen = (state, isOpen) => ({
  ...state,
  isNewOpen: isOpen,
});

const setIsModalOpen = (state, isOpen) => ({
  ...state,
  isOpen,
});

const resetModalState = () => initialState;

const setFetching = (state, isFetching) => ({
  ...state,
  isFetching,
});

const setTracking = (state, isTracking) => ({
  ...state,
  isTracking,
});

const setTrackStatus = (state, trackStatus) => ({
  ...state,
  trackStatus,
});

const setDeadlines = (state, deadlines) => ({
  ...state,
  deadlines,
});

const setAlerts = (state, alerts) => ({
  ...state,
  alerts,
});

const setSelectedGroups = (state, selectedGroups) => ({
  ...state,
  selectedGroups,
});

const setSelectedOpportunities = (state, selectedOpportunities) => ({
  ...state,
  selectedOpportunities,
});

const setReceivingUpdateAlerts = (state, isReceivingUpdateAlerts) => {
  return {
    ...state,
    isReceivingUpdateAlerts,
  };
};

const setFetchStatus = (state, fetchStatus) => ({
  ...state,
  fetchStatus,
});

/* Reducers */
export const trackModalReducer = createReducer(
  {
    [trackModalActions.setIsModalOpen]: setIsModalOpen,
    [trackModalActions.setIsNewModalOpen]: setIsNewModalOpen,
    [trackModalActions.setFetching]: setFetching,
    [trackModalActions.setDeadlines]: setDeadlines,
    [trackModalActions.setAlerts]: setAlerts,
    [trackModalActions.setSelectedGroups]: setSelectedGroups,
    [trackModalActions.setReceivingUpdateAlerts]: setReceivingUpdateAlerts,
    [trackModalActions.setFetchStatus]: setFetchStatus,
    [trackModalActions.resetModalState]: resetModalState,
    [trackModalActions.setTracking]: setTracking,
    [trackModalActions.setTrackStatus]: setTrackStatus,
    [trackModalActions.setSelectedOpportunities]: setSelectedOpportunities,
  },
  initialState,
);
