import { createAction, createReducer } from 'redux-act';
import unionBy from 'lodash/unionBy.js';
import orderBy from 'lodash/orderBy.js';

import { AWARDS_SORT_OPTIONS } from '@fi/constants';

import { FacetUtils } from '@fi/util/FacetUtils';
import {
  setIsLoadingResults,
  updateSearchQueryReducer,
  selectItem,
  deselectItems,
  selectAllPage,
  setSelectedItems,
  deselectAll,
  paginateSearch,
  researchAreasReducer,
  setMetadataIsFetched,
  pushSearchUrlToHistory,
  acceptOpportunitiesFunderType,
  acceptFundingOrganizationsReducer,
  setInitialCountMap,
  isFileExporting,
  exportFileFailed,
  setSelectAllContext,
  acceptFundingOrganizationsCountsReducer,
  acceptFundingOrganizationsSearchResponseReducer,
} from './common.js';

function acceptGrantTypesReducer(state, payload) {
  const grantTypes = Object.assign({}, state.grantTypes, {
    items: payload.map((type) => {
      return {
        key: type.typeId,
        value: type.description,
      };
    }),
  });
  return Object.assign({}, state, {
    grantTypes,
  });
}
/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const awardsActions = {
  runSearch: createAction('run search'),
  fetchAwardAggregations: createAction('fetch award aggregations'),
  fetchAwardResults: createAction('fetch award results'),
  fetchAwardDetails: createAction('fetch details'),
  fetchMetadata: createAction('fetch metadata'),
  fetchAwardedInstitutions: createAction('fetch awardee institutions'),
  fetchAwardedInstitutionsMeta: createAction('fetch awardee institutions for meta data fetch'),
  clearAwardedInstitutions: createAction('clear awardee institutions'),
  fetchAwardedAuthors: createAction('fetch awarded authors'),
  setIsLoadingResults: createAction('set is loading results'),
  setIsLoadingDetails: createAction('set is loading details'),
  setIsFetchingAwardedInstitutions: createAction('set is fetching awardee institutions'),
  setIsFetchingAwardedInstitutionsMeta: createAction('set is fetching awardee institutions for meta data'),
  setIsAutoCompleteRequested: createAction('set is autocomplete requested awardee institutions'),
  setIsFetchingAwardedAuthors: createAction('set is fetching awarded authors'),
  setMetadataIsFetched: createAction('set metadata is fetched'),
  setAwardDetails: createAction('set award details'),
  loadAwardAggregations: createAction('Load awards'),
  loadAwardResults: createAction('load award results'),
  loadFundingOrganizations: createAction('Load funding organizations'),
  loadFundingOrganizationsCounts: createAction('Load funding organizations counts'),
  loadFundingOrganizationsSearchResponse: createAction('Load funding organizations from search response'),
  loadGrantTypes: createAction('Load grant types'),
  updateSearch: createAction('Update awards search'),
  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all awards'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  resetFilters: createAction('Reset filters'),
  paginateSearch: createAction('Paginating search results'),
  loadFunderType: createAction('Load funder type'),
  loadStartYears: createAction('Loading Start Years'),
  loadResearchAreas: createAction('Load research areas'),
  orderResearchAreas: createAction('Order research areas'),
  setAwardMap: createAction('set awards map'),
  setAwardedInstitutions: createAction('set awardee institutions'),
  setAwardedAuthors: createAction('set awarded authors'),
  pushSearchUrlToHistory: createAction('push search url to history'),
  setSelectAllContext: createAction('Set select all context'),
  setInitialCountMap: createAction('set initial count map'),
  resetSavedSearchOptions: createAction('reset saved search options'),
  exportFile: createAction('exporting file'),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),
};

/* Search state */
const SEARCH_QUERY_DEFAULT = {
  keyword: '',
  sortOptions: AWARDS_SORT_OPTIONS,
  selectedSortOption: AWARDS_SORT_OPTIONS[0],
  currentPage: 1,
  funderType: {
    selectedItems: [],
  },
  fundingOrganizations: {
    selectedItems: [],
  },
  startYear: {
    selectedItems: [],
  },
  grantTypes: {
    selectedItems: [],
  },
  awardedAuthors: {
    selectedItems: [],
  },
  awardedInstitutions: {
    selectedItems: [],
  },
  amount: {
    min: 0,
    max: 0,
    includeUnspecifiedAmounts: false,
  },
  awardeeCountries: {
    selectedItems: [],
  },
  countries: {
    selectedItems: [],
  },
  funderCountries: {
    selectedItems: [],
  },
  researchAreas: {
    parentMap: {},
    childMap: {},
  },
};

const loadAwardAggregationsReducer = (state, data) => {
  const grantTypes = FacetUtils.getGrantTypesWithCount(data, state.grantTypes);
  const funderType = FacetUtils.getFunderTypeCounts(data, state.funderType);
  const startYear = FacetUtils.getStartYearsWithCount(data, state.startYear);
  const researchAreas = FacetUtils.getResearchAreaCounts(data, state.researchAreas);
  const awardeeCountries = FacetUtils.getAwardeeCountriesWithCounter(data, state.awardeeCountries);

  return {
    ...state,
    currentPage: state.currentPage,
    isFacetLoading: false,
    grantTypes,
    funderType,
    startYear,
    researchAreas,
    awardeeCountries,
  };
};

/* awards results reducers */
const loadAwardResultsReducer = (state, data) => {
  return {
    ...state,
    results: data.hits.hits,
    totalResults: data.hits.total.value,
    currentPage: state.currentPage,
    isLoading: false,
  };
};

const resetFiltersReducer = (state) => {
  return {
    ...state,
    isLoadingResults: true,
    isMetadataFetched: false,
    searchQuery: SEARCH_QUERY_DEFAULT,
  };
};

function loadStartYears(state, data) {
  const items = data.map((item) => {
    const year = item.year.toString();
    return {
      key: year,
      value: year,
    };
  });
  const years = orderBy(items, ['key'], ['desc']);
  const startYear = {
    items: years,
    countMap: {},
  };
  return {
    ...state,
    startYear,
  };
}

const setAwardMap = (state, awardsMap) => ({
  ...state,
  awardsMap,
});
const setAwardDetails = (state, awardDetails) => ({
  ...state,
  awardDetails,
});
const setIsLoadingDetails = (state, isLoadingDetails) => ({
  ...state,
  isLoadingDetails,
});
const setIsFetchingAwardedAuthors = (state, isFetchingAwardedAuthors) => ({
  ...state,
  isFetchingAwardedAuthors,
});
const setIsFetchingAwardedInstitutions = (state, isFetchingAwardedInstitutions) => ({
  ...state,
  isFetchingAwardedInstitutions,
});
const setIsFetchingAwardedInstitutionsMeta = (state, isFetchingAwardedInstitutionsMeta) => ({
  ...state,
  isFetchingAwardedInstitutionsMeta,
});
const setIsAutoCompleteRequested = (state, isAutoCompleteRequested) => ({
  ...state,
  isAutoCompleteRequested,
});

const setAwardedInstitutions = (state, awardedInstitutions) => ({
  ...state,
  awardedInstitutions: {
    items: awardedInstitutions,
    cache: unionBy(state.awardedInstitutions.cache, awardedInstitutions, 'key'),
  },
});
const setAwardedAuthors = (state, awardedAuthors) => ({
  ...state,
  awardedAuthors: {
    items: awardedAuthors,
    cache: unionBy(state.awardedAuthors.cache, awardedAuthors, 'key'),
  },
});

export const awardsReducer = createReducer(
  {
    [awardsActions.setIsLoadingResults]: setIsLoadingResults,
    [awardsActions.setIsLoadingDetails]: setIsLoadingDetails,
    [awardsActions.setAwardDetails]: setAwardDetails,
    [awardsActions.setMetadataIsFetched]: setMetadataIsFetched,
    [awardsActions.loadAwardAggregations]: loadAwardAggregationsReducer,
    [awardsActions.loadAwardResults]: loadAwardResultsReducer,
    [awardsActions.loadFundingOrganizations]: acceptFundingOrganizationsReducer,
    [awardsActions.loadFundingOrganizationsCounts]: acceptFundingOrganizationsCountsReducer,
    [awardsActions.loadFundingOrganizationsSearchResponse]: acceptFundingOrganizationsSearchResponseReducer,
    [awardsActions.loadGrantTypes]: acceptGrantTypesReducer,
    [awardsActions.updateSearch]: updateSearchQueryReducer,
    [awardsActions.selectItem]: selectItem,
    [awardsActions.deselectItems]: deselectItems,
    [awardsActions.deselectAll]: deselectAll,
    [awardsActions.selectAllPage]: selectAllPage,
    [awardsActions.setSelectedItems]: setSelectedItems,
    [awardsActions.resetFilters]: resetFiltersReducer,
    [awardsActions.paginateSearch]: paginateSearch,
    [awardsActions.loadFunderType]: acceptOpportunitiesFunderType,
    [awardsActions.loadStartYears]: loadStartYears,
    [awardsActions.loadResearchAreas]: researchAreasReducer,
    [awardsActions.setAwardMap]: setAwardMap,
    [awardsActions.setAwardedInstitutions]: setAwardedInstitutions,
    [awardsActions.setIsFetchingAwardedInstitutions]: setIsFetchingAwardedInstitutions,
    [awardsActions.setIsFetchingAwardedInstitutionsMeta]: setIsFetchingAwardedInstitutionsMeta,
    [awardsActions.setIsAutoCompleteRequested]: setIsAutoCompleteRequested,
    [awardsActions.setIsFetchingAwardedAuthors]: setIsFetchingAwardedAuthors,
    [awardsActions.setAwardedAuthors]: setAwardedAuthors,
    [awardsActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,
    [awardsActions.setSelectAllContext]: setSelectAllContext,
    [awardsActions.setInitialCountMap]: setInitialCountMap,
    [awardsActions.isFileExporting]: isFileExporting,
    [awardsActions.exportFileFailed]: exportFileFailed,
  },
  {
    searchHistory: '', // latest search query
    searchQuery: SEARCH_QUERY_DEFAULT,
    results: [],
    funderType: {
      items: [],
      countMap: {},
    },
    selectedItems: {},
    totalResults: 0,
    isFacetLoading: true,
    isLoadingResults: true,
    isMetadataFetched: false,
    isLoadingDetails: true,
    isFetchingAwardedInstitutions: false,
    isFetchingAwardedInstitutionsMeta: false,
    isAutoCompleteRequested: false,
    isFetchingAwardedAuthors: false,
    selectAllContext: 'all',
    fundingOrganizations: {
      items: [],
      countMap: {},
      itemsMap: {},
    },
    grantTypes: {
      items: [],
      countMap: {},
    },
    researchAreas: {
      items: [],
      countMap: {},
      initialCountMap: {},
    },
    awardedInstitutions: {
      items: [],
      cache: [],
    },
    awardedAuthors: {
      items: [],
      cache: [],
    },
    awardeeCountries: {
      items: [],
    },
    startYear: {
      items: [],
      countMap: {},
    },
    awardDetails: {},
    awardsMap: [],
    exportFailed: null,
  },
);
