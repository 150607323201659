import { Provider } from 'react-redux';
import { AppStore } from '@fi/redux/store';

const ReduxProvider = ({ children }) => <Provider store={AppStore}>{children}</Provider>;

ReduxProvider.propTypes = {
  children: PropTypes.node,
};

export default ReduxProvider;
