import keyBy from 'lodash/keyBy.js';
import merge from 'lodash/merge.js';
import without from 'lodash/without.js';
import { createAction, createReducer } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => import('redux-act').EmptyActionCreator<null, null>>}
 */
export const usersRolesActions = {
  runSearch: createAction('run search', (payload) => payload),
  setSearchError: createAction('set search error'),
  setNotFound: createAction('set not found'),
  setSearchUsersResults: createAction('search user'),
  setIsLoadingSearchResults: createAction('set is loading search result'),
  clearSearchResult: createAction('clear users roles search results'),
  // ...
  loadUsers: createAction('load users roles'),
  setIsLoadingUsers: createAction('is loading users'),
  setUsersRolesList: createAction('set users roles'),
  resetUsersRolesList: createAction('reset users roles'),
  // ...
  assignRole: createAction('assign role'),
  removeRole: createAction('remove role'),
  setUserRole: createAction('set user role'),
  // ...
  setUpdatingUserId: createAction('set updating user id'),
  removeUpdatingUserId: createAction('remove updating user id'),
  // ...
  openModal: createAction('open modal'),
  closeModal: createAction('close modal'),
  // ...
  setRedirectAfterUpdate: createAction('redirect after role updated'),
  // ...
  fetchAccounts: createAction('fetch list of accounts'),
  setIsFetchingAccounts: createAction('set is fetching account list'),
  selectAccount: createAction('set selected account'),
  loadAccounts: createAction('load list of accounts'),
  changeAccount: createAction('change selected account'),
  resetAccount: createAction('reset account selection'),

  getAccountContactPerson: createAction('get account contact person'),
  saveAccountContactPerson: createAction('save account contact person'),
  deleteAccountContactPerson: createAction('delete account contact person'),
  storeContactPerson: createAction('store contact person data'),
  setIsUpdatingContactPerson: createAction('set isUpdating contact person'),
  changeContactPersonRole: createAction('change contact person role'),
  // ...
  setChangeRoleStatus: createAction('set status when role change'),
  resetChangeRoleStatus: createAction('set status when role change'),

  ///////////////////////////////////////////////////////
  // UI refresh: /profile/roles for Admin
  changeRole: createAction('change role', (payload) => payload),
  setIsLoadingChangeRole: createAction('set is loading for changeRole'),
  setFailureChangeRole: createAction('set isError and errorMessage for changeRole'),
  setSuccessChangeRole: createAction('set isSuccess and response data for changeRole'),
  resetChangeRoleStates: createAction('reset to initial state for changeRole'),
};

const setIsLoadingUsers = (state, isLoadingUsers) => {
  return {
    ...state,
    isLoadingUsers,
  };
};

const normalizeUsers = (users) => {
  return {
    allUsersIds: users.map((user) => user.user.id),
    byUserId: keyBy(users, 'user.id'),
  };
};

const resetUsersRolesListReducer = (state, users) => {
  const normalizedUsers = normalizeUsers(users);

  return {
    ...state,
    roles: {
      allUsersIds: normalizedUsers.allUsersIds,
      byUserId: normalizedUsers.byUserId,
    },
  };
};

const setUsersRolesListReducer = (state, users) => {
  if (users.length === 0) {
    return state;
  }

  const normalizedUsers = normalizeUsers(users);

  return {
    ...state,
    roles: {
      allUsersIds: state.roles.allUsersIds.concat(normalizedUsers.allUsersIds),
      byUserId: merge({}, state.roles.byUserId, normalizedUsers.byUserId),
    },
  };
};

const setUserRoleReducer = (state, { userId, role }) => {
  if (!userId || !state.roles.allUsersIds.includes(userId)) {
    return state;
  }

  const userById = state.roles.byUserId[userId];

  const newUsersRolesList = {
    ...state.roles,
    byUserId: {
      ...state.roles.byUserId,
      [userId]: {
        ...userById,
        role,
      },
    },
  };

  return {
    ...state,
    roles: newUsersRolesList,
  };
};

// Search users reducers
const setIsLoadingSearchResultsReducer = (state, isLoadingSearchResults) => {
  return {
    ...state,
    isLoadingSearchResults,
  };
};

const setUsersSearchResultsReducer = (state, userIds) => {
  if (userIds.length === 0) {
    return state;
  }

  return {
    ...state,
    searchResults: userIds,
  };
};

const setSearchErrorReducer = (state, searchError) => {
  return {
    ...state,
    searchError,
  };
};

const setNotFoundReducer = (state, notFound) => {
  return {
    ...state,
    notFound,
  };
};

const clearSearchResultReducer = (state) => {
  return {
    ...state,
    searchResults: [],
    searchError: false,
  };
};

const setUpdatingUserIdReducer = (state, userId) => {
  if (userId && state.updatingUsersIds.includes(userId)) {
    return state;
  }

  return {
    ...state,
    updatingUsersIds: [...state.updatingUsersIds, userId],
  };
};

const removeUpdatingUserIdReducer = (state, userId) => {
  return {
    ...state,
    updatingUsersIds: without(state.updatingUsersIds, userId),
  };
};

const openModalReducer = (state, { modalType, modalProps }) => {
  return {
    ...state,
    modal: {
      modalType,
      modalProps,
    },
  };
};

const closeModalReducer = (state) => {
  return {
    ...state,
    modal: {
      modalType: null,
      modalProps: {},
    },
  };
};

const setRedirectAfterUpdateReducer = (state, redirectAfterUpdate) => {
  return {
    ...state,
    redirectAfterUpdate,
  };
};

const setIsFetchingAccounts = (state, isFetching) => {
  return {
    ...state,
    accounts: {
      ...state.accounts,
      isFetching,
    },
  };
};

const loadAccounts = (state, list) => {
  return {
    ...state,
    accounts: {
      ...state.accounts,
      list,
    },
  };
};

const selectAccount = (state, account) => {
  return {
    ...state,
    accounts: {
      ...state.accounts,
      selected: account,
    },
  };
};

const storeContactPersonReducer = (state, data) => {
  return {
    ...state,
    accountContactPerson: data,
  };
};

const setIsUpdatingContactPersonReducer = (state, isUpdatingContactPerson) => {
  return {
    ...state,
    isUpdatingContactPerson,
  };
};

const setChangeRoleStatus = (state, changeRoleStatus) => {
  return {
    ...state,
    changeRoleStatus,
  };
};
const resetChangeRoleStatus = (state) => {
  return {
    ...state,
    changeRoleStatus: {
      type: null,
      success: null,
    },
  };
};

///////////////////////////////////////////////////////
// CHANGE ROLE
const changeRoleInitialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: undefined,
  error: {
    message: undefined,
  },
};

const resetChangeRoleStates = (state) => {
  // reset changeRole to initial state
  return {
    ...state,
    changeRole: changeRoleInitialState,
  };
};

const setIsLoadingChangeRole = (state, isLoading) => {
  return {
    ...state,
    changeRole: {
      ...state.changeRole,
      isLoading: isLoading,
    },
  };
};

const setFailureChangeRole = (state, error) => {
  const errorMessage = error?.message ? error.message : 'No error details specified';
  return {
    ...state,
    changeRole: {
      ...state.changeRole,
      isError: true,
      error: {
        message: errorMessage,
      },
    },
  };
};

const setSuccessChangeRole = (state, data) => {
  return {
    ...state,
    changeRole: {
      ...state.changeRole,
      isSuccess: true,
      data: data,
    },
  };
};

export const initialState = {
  searchResults: [],
  searchError: false,
  notFound: false,
  isLoadingSearchResults: false,
  accounts: {
    isFetching: false,
    list: [],
    selected: null,
  },
  roles: {
    allUsersIds: [],
    byUserId: {},
  },
  isLoadingUsers: true,
  updatingUsersIds: [],
  modal: {
    modalType: null,
    modalProps: {},
  },
  redirectAfterUpdate: false,
  accountContactPerson: null,
  isUpdatingContactPerson: false,
  changeRoleStatus: {
    type: null,
    success: null,
  },
  changeRole: changeRoleInitialState,
};

export const usersRolesReducer = createReducer(
  {
    [usersRolesActions.setSearchUsersResults]: setUsersSearchResultsReducer,
    [usersRolesActions.setSearchError]: setSearchErrorReducer,
    [usersRolesActions.setNotFound]: setNotFoundReducer,
    [usersRolesActions.clearSearchResult]: clearSearchResultReducer,
    [usersRolesActions.setIsLoadingSearchResults]: setIsLoadingSearchResultsReducer,
    // ...
    [usersRolesActions.setIsLoadingUsers]: setIsLoadingUsers,
    [usersRolesActions.setUsersRolesList]: setUsersRolesListReducer,
    [usersRolesActions.resetUsersRolesList]: resetUsersRolesListReducer,
    [usersRolesActions.setUserRole]: setUserRoleReducer,
    [usersRolesActions.setUpdatingUserId]: setUpdatingUserIdReducer,
    [usersRolesActions.removeUpdatingUserId]: removeUpdatingUserIdReducer,
    // ...
    [usersRolesActions.openModal]: openModalReducer,
    [usersRolesActions.closeModal]: closeModalReducer,
    [usersRolesActions.setRedirectAfterUpdate]: setRedirectAfterUpdateReducer,
    // ...
    [usersRolesActions.setIsFetchingAccounts]: setIsFetchingAccounts,
    [usersRolesActions.loadAccounts]: loadAccounts,
    [usersRolesActions.selectAccount]: selectAccount,
    [usersRolesActions.storeContactPerson]: storeContactPersonReducer,
    [usersRolesActions.setIsUpdatingContactPerson]: setIsUpdatingContactPersonReducer,
    // ...
    [usersRolesActions.setChangeRoleStatus]: setChangeRoleStatus,
    [usersRolesActions.resetChangeRoleStatus]: resetChangeRoleStatus,
    // CHANGE ROLE
    [usersRolesActions.resetChangeRoleStates]: resetChangeRoleStates,
    [usersRolesActions.setIsLoadingChangeRole]: setIsLoadingChangeRole,
    [usersRolesActions.setFailureChangeRole]: setFailureChangeRole,
    [usersRolesActions.setSuccessChangeRole]: setSuccessChangeRole,
  },
  initialState,
);
