import * as React from 'react';

function SvgDelete(props: any) {
  return (
    <svg viewBox="0 0 70 128" height={24} width={14} {...props}>
      <path d="M68.94 36.12L62 29 35 56 8 29l-7 7 27 27L1 90l7 7 27-27 27 27 7-7-27-27 26.94-26.88" />
    </svg>
  );
}

export default SvgDelete;
