import flattenDeep from 'lodash/flattenDeep.js';
import { PAGE_SIZE, FACETS } from '@fi/constants';

function getASJCCategories(selectedResearchAreas) {
  let values = [];
  const { childMap, parentMap } = selectedResearchAreas;
  if (childMap) {
    // push all the description for the single selected category
    const childFacets = Object.entries(childMap).map((item) => {
      return item[1].description;
    });
    values.push(childFacets);
  }
  if (parentMap) {
    // push all the description for the entire selected category
    const parentFacets = Object.entries(parentMap).map((item) => {
      return Object.entries(item[1].children).map((child) => {
        return child[1].description;
      });
    });
    values.push(parentFacets);
  }
  values = flattenDeep(values);
  return values.length
    ? {
        name: FACETS.OPPORTUNITIES.researchAreas,
        values,
      }
    : null;
}

function getFacets(params) {
  const { name, selectedItems } = params;
  const values = selectedItems.map((item) => {
    return item.value;
  });
  return values.length ? { name, values } : null;
}

export function getSavedSearchParams() {
  return getSearchTerms();
}

function getSearchTerms() {
  const urlParams = new URLSearchParams(window.location.search);
  const queries = [];
  if (urlParams.has('queryType')) {
    const keys = ['anyQuery', 'allQuery', 'noneQuery', 'optionalQuery', 'basicQuery'];
    urlParams.forEach((v, k) => {
      if (keys.indexOf(k) > -1) {
        queries.push(`${k}=${v}`);
      }
    });
    const type = urlParams.get('queryType') === 'basic' ? 'criteria' : 'advancedCriteria';
    return {
      [type]: queries.join('&'),
    };
  }
  return null;
}

function getSearchType(section, facets) {
  const searchTerms = getSearchTerms();
  let suffix = 'default';
  if (facets.length !== 0) {
    suffix = 'facet';
  }

  if (searchTerms) {
    const type = Object.keys(searchTerms)[0];
    if (type === 'advancedCriteria') {
      suffix = 'advanced';
    } else {
      suffix = 'basic';
    }
  }

  const searchType = `fi:${section}:${suffix}`;

  return searchType;
}

export function getRunSearchParams({ searchQuery, totalResults, resourceName }) {
  if (resourceName === 'funders') {
    const {
      keyword: criteria,
      currentPage,
      selectedSortOption: { value: sortType },
    } = searchQuery;
    const excludeFunderCountry = searchQuery.xfnCn
      ? {
          name: FACETS.FUNDERS.excludeFunderCountry,
          values: [searchQuery.xfnCn.toString()],
        }
      : null;
    const facets = [
      getFacets({
        name: FACETS.FUNDERS.funderCountries,
        selectedItems: searchQuery.funderCountries.selectedItems,
      }),
      getFacets({
        name: FACETS.FUNDERS.funderType,
        selectedItems: searchQuery.funderType.selectedItems,
      }),
      excludeFunderCountry,
    ].filter((item) => item);

    return {
      criteria,
      resultsPerPage: PAGE_SIZE,
      currentPage,
      sortType,
      facets,
      resultsByType: [
        {
          name: 'Funders',
          results: totalResults,
        },
      ],
      type: getSearchType('funders', facets),
    };
  }
  if (resourceName === 'opportunities') {
    const {
      currentPage,
      selectedSortOption: { value: sortType },
    } = searchQuery;
    const onlyInternalOpportunties = searchQuery.onlyInternal
      ? {
          name: FACETS.OPPORTUNITIES.onlyInternal,
          values: [searchQuery.onlyInternal.toString()],
        }
      : null;
    const onlyRecurringOpportunities = searchQuery.onlyRecurring
      ? {
          name: FACETS.OPPORTUNITIES.onlyRecurring,
          values: [searchQuery.onlyRecurring.toString()],
        }
      : null;
    const includeExpired = searchQuery.includeExpired
      ? {
          name: FACETS.OPPORTUNITIES.includeExpired,
          values: [searchQuery.includeExpired.toString()],
        }
      : null;
    const forthcoming = searchQuery.forthcoming
      ? {
          name: FACETS.OPPORTUNITIES.forthcoming,
          values: [searchQuery.forthcoming.toString()],
        }
      : null;
    const facetAmount =
      searchQuery.amount.min > 0 || searchQuery.amount.max > 0
        ? {
            name: FACETS.OPPORTUNITIES.amount,
            values: [searchQuery.amount.min.toString(), searchQuery.amount.max.toString()].filter((item) => item),
          }
        : null;
    let facetApplicationDeadLine = null;
    if (searchQuery.applicationDeadline.selectedItems.length > 0) {
      facetApplicationDeadLine = getFacets({
        name: FACETS.OPPORTUNITIES.applicationDeadline,
        selectedItems: searchQuery.applicationDeadline.selectedItems,
      });
    } else if (searchQuery.applicationDeadline.startDate || searchQuery.applicationDeadline.endDate) {
      facetApplicationDeadLine = {
        name: FACETS.OPPORTUNITIES.applicationDeadline,
        values: [searchQuery.applicationDeadline.startDate, searchQuery.applicationDeadline.endDate].filter((item) => item),
      };
    }
    const limitedSubmission = searchQuery.limitedSubmission
      ? {
          name: FACETS.OPPORTUNITIES.limitedSubmission,
          values: [searchQuery.limitedSubmission.toString()],
        }
      : null;
    const excludeFunder = searchQuery.xfn
      ? {
          name: FACETS.OPPORTUNITIES.excludeFunder,
          values: [searchQuery.xfn.toString()],
        }
      : null;
    const excludeFunderCountry = searchQuery.xfnCn
      ? {
          name: FACETS.OPPORTUNITIES.excludeFunderCountry,
          values: [searchQuery.xfnCn.toString()],
        }
      : null;
    const excludeApplicantCountry = searchQuery.xApCn
      ? {
          name: FACETS.OPPORTUNITIES.excludeApplicantCountry,
          values: [searchQuery.xApCn.toString()],
        }
      : null;
    const excludeApplicantCitizenship = searchQuery.xApCz
      ? {
          name: FACETS.OPPORTUNITIES.excludeApplicantCitizenship,
          values: [searchQuery.xApCz.toString()],
        }
      : null;
    const excludeActivityCountry = searchQuery.xOlCn
      ? {
          name: FACETS.OPPORTUNITIES.excludeActivityCountry,
          values: [searchQuery.xOlCn.toString()],
        }
      : null;
    const facets = [
      getASJCCategories(searchQuery.researchAreas),
      getFacets({
        name: FACETS.OPPORTUNITIES.fundingTypes,
        selectedItems: searchQuery.fundingTypes.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.funderCountries,
        selectedItems: searchQuery.funderCountries.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.funderType,
        selectedItems: searchQuery.funderType.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.individualApplicantType,
        selectedItems: searchQuery.individualApplicantTypes.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.organizationalApplicantTypes,
        selectedItems: searchQuery.organizationalApplicantTypes.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.countries,
        selectedItems: searchQuery.countries.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.fundingOrganizations,
        selectedItems: searchQuery.fundingOrganizations.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.activityCountries,
        selectedItems: searchQuery.activityCountries.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.degreeRequirements,
        selectedItems: searchQuery.degreeRequirements.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.restrictions,
        selectedItems: searchQuery.restrictions.selectedItems,
      }),
      getFacets({
        name: FACETS.OPPORTUNITIES.applicantCountry,
        selectedItems: searchQuery.applicantCountry.selectedItems,
      }),
      facetAmount,
      facetApplicationDeadLine,
      limitedSubmission,
      onlyRecurringOpportunities,
      excludeFunder,
      excludeFunderCountry,
      excludeApplicantCountry,
      excludeApplicantCitizenship,
      excludeActivityCountry,
      onlyInternalOpportunties,
      includeExpired,
      forthcoming,
    ].filter((item) => item);

    return {
      ...getSearchTerms(),
      resultsPerPage: PAGE_SIZE,
      currentPage,
      sortType,
      facets,
      resultsByType: [
        {
          name: 'Opportunities',
          results: totalResults,
        },
      ],
      type: getSearchType('opportunity', facets),
    };
  }
  if (resourceName === 'awards') {
    const {
      currentPage,
      selectedSortOption: { value: sortType },
    } = searchQuery;
    const facetAmount =
      searchQuery.amount.min > 0 || searchQuery.amount.max > 0
        ? {
            name: FACETS.AWARDS.amount,
            values: [searchQuery.amount.min.toString(), searchQuery.amount.max.toString()].filter((item) => item),
          }
        : null;
    const excludeRecipientCountry = searchQuery.xRcCn
      ? {
          name: FACETS.AWARDS.excludeRecipientCountry,
          values: [searchQuery.xRcCn.toString()],
        }
      : null;
    const excludeFunderName = searchQuery.xfn
      ? {
          name: FACETS.AWARDS.excludeFunderName,
          values: [searchQuery.xfn.toString()],
        }
      : null;
    const excludeFunderCountry = searchQuery.xfnCn
      ? {
          name: FACETS.AWARDS.excludeFunderCountry,
          values: [searchQuery.xfnCn.toString()],
        }
      : null;
    const facets = [
      getASJCCategories(searchQuery.researchAreas),
      getFacets({
        name: FACETS.AWARDS.awardedInstitutions,
        selectedItems: searchQuery.awardedInstitutions.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.awardedAuthors,
        selectedItems: searchQuery.awardedAuthors.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.awardeeCountries,
        selectedItems: searchQuery.awardeeCountries.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.funderCountries,
        selectedItems: searchQuery.funderCountries.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.funderType,
        selectedItems: searchQuery.funderType.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.fundingOrganizations,
        selectedItems: searchQuery.fundingOrganizations.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.grantTypes,
        selectedItems: searchQuery.grantTypes.selectedItems,
      }),
      getFacets({
        name: FACETS.AWARDS.startYear,
        selectedItems: searchQuery.startYear.selectedItems,
      }),
      facetAmount,
      excludeRecipientCountry,
      excludeFunderName,
      excludeFunderCountry,
    ].filter((item) => item);

    return {
      resultsPerPage: PAGE_SIZE,
      currentPage,
      sortType,
      ...getSearchTerms(),
      facets,
      resultsByType: [
        {
          name: 'Awards',
          results: totalResults,
        },
      ],
      type: getSearchType('awards', facets),
    };
  }
  return null;
}
