import { createAction, createReducer } from 'redux-act';

import {
  setIsLoadingResults,
  selectItem,
  setSelectedItems,
  selectAllPage,
  deselectAll,
  deselectItems,
  isFileExporting,
  exportFileFailed,
  setCurrentPage,
  setSelectAllContext,
} from './common.js';
import { selectAllActions } from './selectAll.js';

export const opportunityNotesActions = {
  fetchOpportunityNotes: createAction('fetch opportunities with notes', (payload) => payload),
  loadOpportunities: createAction('load opportunities with notes', (payload) => payload),
  setIsLoadingResults: createAction('set isLoading for opportunities with notes'),
  setCurrentPage: createAction('set current page for opportunities with notes', (payload) => payload),
  resetSearchQuery: createAction('reset SearchQuery'),

  selectItem: createAction('Select item', (payload) => payload),
  deselectItems: createAction('Deselect items', (payload) => payload),
  deselectAll: createAction('Deselect all'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  setSelectAllContext: createAction('set selection context for opportunities with notes'),

  setOpportunityIsTracked: createAction('set opportunity tracked', (payload) => payload),

  exportFile: createAction('exporting file', (payload) => payload),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),

  deleteNotes: createAction('delete notes', (payload) => payload),
  openDeleteConfirmationModal: createAction('open delete notes confirmation modal', (payload) => payload),
  closeDeleteConfirmationModal: createAction('close delete notes confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while note is deleting'),
  setDeleteNoteStatus: createAction('set delete status', (payload) => payload),
  removeNoteFromStore: createAction('Remove opportunity note from store', (payload) => payload),
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
};

const loadOpportunitiesReducer = (state, payload) => {
  let results = [];
  let totalResults = 0;
  if (payload?.hits) {
    results = payload.hits.hits.map((item) => {
      return {
        ...item._source,
        score: item._score,
      };
    });
    totalResults = payload.hits.total.value;
  }
  return {
    ...state,
    totalResults,
    results,
  };
};

const resetSearchQuery = (state) => ({
  ...state,
  searchQuery: SEARCH_QUERY_DEFAULT,
});

const setOpportunityIsTracked = (state, ids) => {
  const results = state.results.map((item) => {
    if (ids.includes(item.id)) {
      return {
        ...item,
        isTracked: true,
      };
    }
    return item;
  });
  return {
    ...state,
    results,
  };
};

/* delete confirmation modal */

const openDeleteConfirmationModal = (state, selectedOpportunities) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedOpportunities,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedOpportunities: [],
    isOpen: false,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

const setDeleteNoteStatus = (state, deleteNoteStatus) => ({
  ...state,
  deleteNoteStatus,
});

const removeNoteFromStore = (state, noteIds) => {
  const idsObject = {};
  for (const i in noteIds) {
    idsObject[noteIds[i]] = true;
  }
  const notes = state.results.filter((item) => !idsObject[item.id]);
  const totalNotes = state.totalResults - noteIds.length;

  return {
    ...state,
    results: notes,
    totalResults: totalNotes,
  };
};

export const opportunityNotesReducer = createReducer(
  {
    [opportunityNotesActions.loadOpportunities]: loadOpportunitiesReducer,
    [opportunityNotesActions.setIsLoadingResults]: setIsLoadingResults,
    [opportunityNotesActions.setCurrentPage]: setCurrentPage,
    [opportunityNotesActions.resetSearchQuery]: resetSearchQuery,

    [opportunityNotesActions.selectItem]: selectItem,
    [opportunityNotesActions.deselectItems]: deselectItems,
    [opportunityNotesActions.deselectAll]: deselectAll,
    [opportunityNotesActions.selectAllPage]: selectAllPage,
    [opportunityNotesActions.setSelectedItems]: setSelectedItems,
    [opportunityNotesActions.setSelectAllContext]: setSelectAllContext,
    [opportunityNotesActions.setOpportunityIsTracked]: setOpportunityIsTracked,

    [opportunityNotesActions.isFileExporting]: isFileExporting,
    [opportunityNotesActions.exportFileFailed]: exportFileFailed,

    [opportunityNotesActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [opportunityNotesActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [opportunityNotesActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
    [opportunityNotesActions.setDeleteNoteStatus]: setDeleteNoteStatus,
    [opportunityNotesActions.removeNoteFromStore]: removeNoteFromStore,

    [selectAllActions.deselectAllOpportunities]: deselectAll,
  },
  {
    searchHistory: '',
    searchQuery: SEARCH_QUERY_DEFAULT,
    isLoadingResults: false,
    results: [],
    totalResults: 0,
    selectedItems: {},
    selectAllContext: 'all',
    trackStatus: null,
    deleteNoteStatus: null,
    exportFailed: null,
    isFetchingNote: null,
    deleteConfirmationModal: {
      selectedOpportunities: [],
      isDeleting: false,
      isOpen: false,
    },
  },
);
