import { matchPath } from 'react-router';
import { ADOBE_ANALYTICS_PAGETREE } from './adobeAnalyticsPageTree.ts';

/** rough collection of event types from `log.js` */
type AdobeAnalyticsEventParams = {
  content?: any[];
  export?: any;
  link?: {
    location: string;
    name: string; // v119 label of link, e.g. "Go to publisher"
    type?: 'exit'; // always for external links
    destination?: string; // v121 full external URL
  };
  conversionDriver?: {
    name: 'fi:signin' | 'fi:create account';
  };
  search?: any;
};

/**
 * Due to a limitation in the Adobe Analytics library, we need to reset the `pageData` object every time we send out an event.
 *
 * Otherwise, stale events pile up in the pageData object and are sent out again and again.
 */
const resetPageDataObject = () => {
  if (!window.pageData) {
    return;
  }
  window.pageData = {
    visitor: window.pageData.visitor,
    // keep page information, page.name and page.type will be overridden later
    page: window.pageData.page,
  };
};

/**
 * When leaving the page via an external link (`<a/>`), we need to wait for the Adobe Analytics call.
 * `setTimeout` waits for a little bit, to prevent the network call from being cancelled.
 *
 * A more elegant solution, not available in our current Adobe implementation, would follow this concept:
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
 */
export const waitForAnalytics = (url: string) => {
  setTimeout(() => {
    window.location.href = url;
  }, 100);
};

/**
 * Sends `newPage` event to Adobe Analytics for the specified page or modal
 */
export const sendAnalyticsPageView = (pathname: string) => {
  resetPageDataObject();

  const pageInfo = getPageInfo(pathname);

  //`appData.push` triggers network request to report Adobe Analytics event
  window.appData.push({
    event: 'newPage',
    // update page info
    page: { ...window.appData[0].page, name: pageInfo.name, type: pageInfo.type },
  });
};

export const sendAnalyticsEvent = (event: AdobeAnalyticsEvent, params: AdobeAnalyticsEventParams) => {
  resetPageDataObject();

  // Previusly: window.pageDataTracker.trackEvent(event, params);
  window.appData.push({
    event,
    ...params,
  });
};

/**
 * Gets information for Adobe Analytics Page Views for the current route
 *
 * When no match is found, returns a `not found` result for Adobe Analytics
 */
function getPageInfo(route: string) {
  const pageMatch = ADOBE_ANALYTICS_PAGETREE.find((page) =>
    matchPath(route, {
      path: page.path,
      exact: true,
      strict: false,
    }),
  );

  if (!pageMatch) {
    return {
      name: 'institutional:page-not-found',
      type: 'NP-GP',
    };
  }

  const pageInfo = {
    name: pageMatch.name,
    type: pageMatch.type,
  };

  return pageInfo;
}
