import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './AuthContext/AuthContext';
import ReduxProvider from './ReduxProvider.jsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';

interface AppProvidersProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import(/* webpackChunkName: "ReactQueryDevtools"*/ '@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const AppProviders = ({ children }: AppProvidersProps) => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ReduxProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <Suspense fallback={null}>
            {/*
            - devtools are excluded by default for production builds - this includes them for debugging
            - toggle them in the production from the browser console: `window.toggleDevtools()`
            */}
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </QueryClientProvider>
    </ReduxProvider>
  );
};

export default AppProviders;
