import { Classification } from '../opportunities/opportunity.types.ts';
import { NumberUtils } from './NumberUtils.js';

export const getClassificationCounts = (classifications: Array<Classification & { count: number }>) => {
  const countMap: Record<string, number> = {};
  classifications.forEach((classification) => {
    countMap[classification.code] = parseInt(NumberUtils.formatToFixed(classification.count), 10);
  });

  return countMap;
};
