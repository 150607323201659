import { createAction, createReducer } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const labelsActions = {
  setIsLoadingLabels: createAction('is loading labels'),
  setFetchingLabelsStatus: createAction('set fetching labels status success or error'),
  fetchLabels: createAction('fetch list of Pure labels'),
  setLabels: createAction('set all labels list'),
  deleteLabel: createAction('delete a label'),
  setIsDeletingLabel: createAction('deleting label'),
  renameLabel: createAction('renaming a label'),
  setDeleteLabelStatus: createAction('set Delete Label Status success or error'),

  /* delete confirmation modal */
  openDeleteConfirmationModal: createAction('open delete confirmation modal'),
  closeDeleteConfirmationModal: createAction('close delete confirmation modal'),
  setStatusDeleteConfirmationModal: createAction('open deleting status for confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while label is deleting'),
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
};

const initialState = {
  isLoadingLabels: false,
  fetchingLabelsStatus: null,
  labels: [],
  isDeleting: false,
  deleteLabelStatus: null,
  searchQuery: SEARCH_QUERY_DEFAULT,
  deleteConfirmationModal: {
    selectedLabel: null,
    isDeleting: false,
    status: null,
    isOpen: false,
  },
};

const setIsLoadingLabels = (state, isLoadingLabels) => ({
  ...state,
  isLoadingLabels,
});

const setFetchingLabelsStatus = (state, fetchingLabelsStatus) => ({
  ...state,
  fetchingLabelsStatus,
});

const setIsDeletingLabel = (state, isDeleting) => ({
  ...state,
  isDeleting,
});

const setDeleteLabelStatus = (state, deleteLabelStatus) => ({
  ...state,
  deleteLabelStatus,
});

const setLabels = (state, data) => {
  return {
    ...state,
    labels: data.labels,
  };
};

const openDeleteConfirmationModal = (state, selectedLabel) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedLabel,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    selectedLabel: null,
    status: null,
    isDeleting: false,
    isOpen: false,
  },
});

const setStatusDeleteConfirmationModal = (state, status) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    status,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

/* Reducers */
export const labelsReducer = createReducer(
  {
    [labelsActions.setIsLoadingLabels]: setIsLoadingLabels,
    [labelsActions.setFetchingLabelsStatus]: setFetchingLabelsStatus,
    [labelsActions.setLabels]: setLabels,
    [labelsActions.setIsDeletingLabel]: setIsDeletingLabel,
    [labelsActions.setDeleteLabelStatus]: setDeleteLabelStatus,

    /* delete confirmation modal */
    [labelsActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [labelsActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [labelsActions.setStatusDeleteConfirmationModal]: setStatusDeleteConfirmationModal,
    [labelsActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
  },
  initialState,
);
