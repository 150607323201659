import { createAction, createReducer } from 'redux-act';

export const recommendationModalActions = {
  open: createAction('open the modal'),
  close: createAction('close the modal and set the initial state'),
  fetchScopusProfiles: createAction('fetch list of scopus profiles'),
  setProfiles: createAction('set scopus profiles'),
  setFetchingStatus: createAction('set fetching status for profiles'),
  setIsFethingProfiles: createAction('set loading list of profile'),
  setSearchForm: createAction('set search form of a profile'),
  setModalRoute: createAction('set modal route'),

  createRecommendation: createAction('create a new recommendation list'),
  setIsCreatingRecommendation: createAction('set is creating flag'),
  setCreateRecommendationStatus: createAction('set status for create recommendation'),

  fetchProfilePublications: createAction('fetch list of scopus profiles publications'),
  setPublications: createAction('set user profile publications'),
  setIsFetchingPublications: createAction('set isFetching publications'),
  setFetchingStatusPublications: createAction('set status for fetching publications'),
  fetchAuthorHints: createAction('fetch author hints'),
  setAuthorHints: createAction('set author hints'),
};

const MODALROUTE = {
  SEARCHPROFILE: 'SEARCHPROFILE',
  PROFILELIST: 'PROFILELIST',
};

const initialState = {
  isOpen: false,
  route: MODALROUTE.SEARCHPROFILE,
  profiles: {
    isFetching: false,
    fetchingStatus: null,
    data: null,
  },
  createRecommendation: {
    isCreating: false,
    status: null,
  },
  publications: {
    isFetching: false,
    status: null,
    id: null,
  },
  searchForm: {
    searchBy: 'name',
    lastname: '',
    firstname: '',
    affiliation: '',
    authorid: '',
    orcid: '',
    entityId: null,
  },
  authorHints: null,
};

const open = (state, payload) => {
  if (payload) {
    const { route, searchForm } = payload;
    return {
      ...state,
      isOpen: true,
      route: route || initialState.route,
      searchForm: {
        ...initialState.searchForm,
        ...searchForm,
      },
    };
  }
  return {
    ...state,
    isOpen: true,
  };
};

const close = (state) => ({
  ...initialState,
  createRecommendation: state.createRecommendation,
});

const setProfiles = (state, data) => ({
  ...state,
  profiles: {
    ...state.profiles,
    data,
  },
});

const setPublications = (state, { id, publications }) => {
  const profiles = [...state.profiles.data];
  const profileIndex = profiles.findIndex((profile) => profile.id === id && !profile.publications);
  if (profileIndex > -1) {
    profiles[profileIndex] = {
      ...profiles[profileIndex],
      publications,
    };
    return {
      ...state,
      profiles: {
        ...state.profiles,
        data: profiles,
      },
    };
  }
  return state;
};

const setModalRoute = (state, route) => ({
  ...state,
  route,
});

const setIsFethingProfiles = (state, isFetching) => ({
  ...state,
  profiles: {
    ...state.profiles,
    isFetching,
  },
});

const setFetchingStatus = (state, fetchingStatus) => ({
  ...state,
  profiles: {
    ...state.profiles,
    fetchingStatus,
  },
});

const setSearchForm = (state, searchForm) => ({
  ...state,
  searchForm,
});

const setIsCreatingRecommendation = (state, isCreating) => ({
  ...state,
  createRecommendation: {
    ...state.createRecommendation,
    isCreating,
  },
});

const setCreateRecommendationStatus = (state, status) => ({
  ...state,
  createRecommendation: {
    ...state.createRecommendation,
    status,
  },
});

const setIsFetchingPublications = (state, payload) => ({
  ...state,
  publications: {
    ...state.publications,
    isFetching: payload.isFetching,
    id: payload.id,
  },
});

const setFetchingStatusPublications = (state, status) => ({
  ...state,
  publications: {
    ...state.publications,
    status,
  },
});

const setAuthorHints = (state, hints) => ({
  ...state,
  authorHints: hints,
});

/* Reducers */
export const recommendationModalReducer = createReducer(
  {
    [recommendationModalActions.open]: open,
    [recommendationModalActions.close]: close,

    [recommendationModalActions.setProfiles]: setProfiles,
    [recommendationModalActions.setPublications]: setPublications,
    [recommendationModalActions.setFetchingStatus]: setFetchingStatus,
    [recommendationModalActions.setIsFethingProfiles]: setIsFethingProfiles,
    [recommendationModalActions.setSearchForm]: setSearchForm,
    [recommendationModalActions.setModalRoute]: setModalRoute,
    [recommendationModalActions.setIsCreatingRecommendation]: setIsCreatingRecommendation,
    [recommendationModalActions.setCreateRecommendationStatus]: setCreateRecommendationStatus,

    [recommendationModalActions.setIsFetchingPublications]: setIsFetchingPublications,
    [recommendationModalActions.setFetchingStatusPublications]: setFetchingStatusPublications,

    [recommendationModalActions.setAuthorHints]: setAuthorHints,
  },
  initialState,
);
