import { createSearchQueryObject } from './apiSearchQuery.ts';
import {
  createAdvancedSearchQueryObject,
  createAmountQueryObject,
  createDisciplinesQueryObject,
  createListQueryObject,
  createPageQueryObject,
  createPageSizeQueryObject,
  createSortQueryObject,
  createBooleanQueryObject,
} from './commonSearchQuery.ts';

const createSearchQueryObjectFn = createSearchQueryObject(
  createPageSizeQueryObject,
  createPageQueryObject,
  createSortQueryObject,
  createAdvancedSearchQueryObject,
  createDisciplinesQueryObject,
  createAmountQueryObject,
  createListQueryObject('fundingOrganizations', 'subSponsor'),
  createListQueryObject('funderType', 'funderTypeGroupId', 'types'),
  createListQueryObject('startYear', 'years', 'value'),
  createListQueryObject('awardeeCountries', 'countryCodes'),
  createListQueryObject('grantTypes', 'type'),
  createListQueryObject('awardedInstitutions', 'awardedInstitutionId'),
  createListQueryObject('awardedAuthors', 'awardedAuthorName', 'key', false, true),
  createListQueryObject('funderCountries', 'funderCountry'),
  createBooleanQueryObject('xRcCn', 'xRcCn'),
  createBooleanQueryObject('xfn', 'xfn'),
  createBooleanQueryObject('xfnCn', 'xfnCn'),
);

export function createAwardSearchQueryObject(searchQuery: any, options: any) {
  return createSearchQueryObjectFn(searchQuery, options);
}
