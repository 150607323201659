import { createAction } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const logActions = {
  sendEvent: createAction('Send event', (payload) => payload),
  sendPresentationLog: createAction('Send presentation log'),
};
