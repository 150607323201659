import { createAction, createReducer } from 'redux-act';

import {
  setIsLoadingResults,
  selectItem,
  deselectItems,
  selectAllPage,
  setSelectedItems,
  deselectAll,
  pushSearchUrlToHistory,
  isFileExporting,
  exportFileFailed,
  updateOpportunitiesWithNotes,
  setSelectAllContext,
} from './common.js';
import { opportunityNoteModalActions } from './opportunityNoteModal.js';
import { selectAllActions } from './selectAll.js';

export const publicGroupActions = {
  fetchGroupOpportunities: createAction('fetch opportunities group', (payload) => payload),

  setIsLoadingResults: createAction('set loading results'),
  setCurrentPage: createAction('set current page', (payload) => payload),
  setSelectedSortOption: createAction('set sort option', (payload) => payload),
  resetSearchQuery: createAction('reset SearchQuery'),

  loadOpportunities: createAction('Load opportunities'),

  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results', (payload) => payload),
  setSelectedItems: createAction('set selected Items'),
  setSelectAllContext: createAction('Set select all context'),

  pushSearchUrlToHistory: createAction('push search url to history', (payload) => payload),

  exportFile: createAction('exporting file'),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),

  setIsLoadingOpportunityGroups: createAction('set is loading groups for opportunity'),
  setOpportunitySelectedGroups: createAction('set selected group list for opportunity'),
  fetchOpportunitySelectedGroups: createAction('fetch group list for opportunity'),

  setGroup: createAction('set group'),
  setNewGroupName: createAction('set new group name'),
  setIsSettingNewGroupName: createAction('set is loading when setting new name for group'),
  setRenameGroupStatus: createAction('set status of setting new name for group'),

  setShareGroupModalIsOpen: createAction('set share group modal open'),
  setIsSharingInProgress: createAction('set is shring group'),
  setSharedList: createAction('set shared email list for the group'),
  shareGroup: createAction('share the group'),
  setSharingStatus: createAction('share the group'),
  fetchGroupsByOppId: createAction('fetch groups by opp id'),
  setGroupsByOppId: createAction('fetch groups by opp id'),
};

const RECENT_TRACKED_SORTING = {
  label: 'Recent tracked',
  value: 'recent',
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
  sortOptions: [
    RECENT_TRACKED_SORTING,
    {
      label: 'Deadline (soonest first)',
      value: 'deadline',
    },
    {
      label: 'Deadline (latest first)',
      value: '-deadline',
    },
    {
      label: 'Amount (highest first)',
      value: '-amount',
    },
  ],
  selectedSortOption: RECENT_TRACKED_SORTING,
};

const loadOpportunitiesReducer = (state, payload) => {
  const results = payload.hits.hits.map((item) => {
    return {
      ...item._source,
      score: item._score,
    };
  });
  return Object.assign({}, state, {
    totalResults: payload.hits.total.value,
    results,
  });
};

const setSelectedSortOption = (state, selectedSortOption) => ({
  ...state,
  searchQuery: {
    ...state.searchQuery,
    selectedSortOption,
  },
});

const resetSearchQuery = (state) => ({
  ...state,
  searchQuery: SEARCH_QUERY_DEFAULT,
});

const setTrackStatus = (state, trackStatus) => ({
  ...state,
  trackStatus,
});

const setTrackedOpp = (state, { trackedOpportunities, totalResults }) => ({
  ...state,
  trackedList: trackedOpportunities,
  totalResults,
});

const editTrackedByOpportunityId = (state, editingTrackByOppId) => ({
  ...state,
  editingTrackByOppId,
});

const setGroup = (state, group) => ({
  ...state,
  group,
});

const setIsSettingNewGroupName = (state, isSettingNewGroupName) => ({
  ...state,
  isSettingNewGroupName,
});

const setRenameGroupStatus = (state, renameGroupStatus) => ({
  ...state,
  renameGroupStatus,
});

const setShareGroupModalIsOpen = (state, isModalOpen) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    isModalOpen,
  },
});

const setIsSharingInProgress = (state, isSharingInProgress) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    isSharingInProgress,
  },
});

const setSharedList = (state, sharedList) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    sharedList,
  },
});

const setSharingStatus = (state, sharingStatus) => ({
  ...state,
  shareGroupModal: {
    ...state.shareGroupModal,
    sharingStatus,
  },
});

const setGroupsByOppId = (state, groupList) => ({
  ...state,
  groupList,
});

const setCurrentPage = (state, currentPage) => ({
  ...state,
  currentPage: currentPage,
});

/* Reducers */
export const publicGroupReducer = createReducer(
  {
    [publicGroupActions.loadOpportunities]: loadOpportunitiesReducer,
    [publicGroupActions.setIsLoadingResults]: setIsLoadingResults,
    [publicGroupActions.setCurrentPage]: setCurrentPage,
    [publicGroupActions.setSelectAllContext]: setSelectAllContext,
    [publicGroupActions.setSelectedSortOption]: setSelectedSortOption,
    [publicGroupActions.resetSearchQuery]: resetSearchQuery,

    [publicGroupActions.selectItem]: selectItem,
    [publicGroupActions.deselectItems]: deselectItems,
    [publicGroupActions.deselectAll]: deselectAll,
    [publicGroupActions.selectAllPage]: selectAllPage,
    [publicGroupActions.setSelectedItems]: setSelectedItems,

    [publicGroupActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,

    [publicGroupActions.setTrackedOpp]: setTrackedOpp,
    [publicGroupActions.setTrackStatus]: setTrackStatus,
    [publicGroupActions.editTrackedByOpportunityId]: editTrackedByOpportunityId,
    [publicGroupActions.isFileExporting]: isFileExporting,
    [publicGroupActions.exportFileFailed]: exportFileFailed,

    [publicGroupActions.setGroup]: setGroup,
    [publicGroupActions.setIsSettingNewGroupName]: setIsSettingNewGroupName,
    [publicGroupActions.setRenameGroupStatus]: setRenameGroupStatus,

    [publicGroupActions.setShareGroupModalIsOpen]: setShareGroupModalIsOpen,
    [publicGroupActions.setIsSharingInProgress]: setIsSharingInProgress,
    [publicGroupActions.setSharedList]: setSharedList,
    [publicGroupActions.setSharingStatus]: setSharingStatus,
    [publicGroupActions.setGroupsByOppId]: setGroupsByOppId,

    [opportunityNoteModalActions.updateOpportunitiesWithNotes]: updateOpportunitiesWithNotes,
    [selectAllActions.deselectAllOpportunities]: deselectAll,
  },
  {
    searchHistory: '',
    isLoadingResults: false,
    results: [],
    totalResults: 0,
    selectedItems: {},
    selectAllContext: 'all',
    searchQuery: SEARCH_QUERY_DEFAULT,
    group: null,
    groupList: [],
    shareGroupModal: {
      isModalOpen: false,
      isSharingInProgress: false,
      sharedList: [],
      sharingStatus: null,
    },
    trackedList: [],
    currentPage: 0,
    trackStatus: null,
    exportFailed: null,
    isSettingNewGroupName: false,
    renameGroupStatus: null,
  },
);
