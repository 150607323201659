import { takeEvery, takeLatest, call, put, select } from 'redux-saga/lib/effects.js';
import download from 'downloadjs';

import { recommendedOpportunitiesActions } from '../dispatchers/recommendedOpportunities.js';
import { pageActions } from '../dispatchers/page.js';
import { logActions } from '../dispatchers/log.js';

import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { apiPost, apiDelete } from '../../services/requests.ts';
import { getRecommendationsForResearcher } from '../../services/api/recommendations.ts';

import { LOCALE, SEARCH_CATEGORIES, EVENT_NAMES, MAX_SELECTED_ITEMS } from '@fi/constants';
import { exportFile } from '../../services/api/exportToFile.js';
import { createOpportunitySearchQueryObject } from '../../query/opportunitySearchQuery.ts';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

const RECOMMENDATIONERRORTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 0,
};

const TRACKALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchRecommendedOpportunities({ researcherId, setLoaderOnPage = true, clearOpportunities = true }) {
  try {
    if (setLoaderOnPage) {
      yield put(recommendedOpportunitiesActions.setIsLoadingResults(true));
    }
    const data = yield getRecommendationsForResearcher(researcherId);

    const pageInfo = {
      entityId: data.entity.id,
      authorId: data.entity.author.id,
      title: data.entity.author.name,
      subjects: data.entity.author.subjectAreas.join(', '),
    };

    if (data.statusCode !== 'OK') {
      if (clearOpportunities) {
        yield put(recommendedOpportunitiesActions.loadOpportunities(null));
      }
      yield put(
        recommendedOpportunitiesActions.setPageInfo({
          ...pageInfo,
          recommendationError: true,
          authorNotFound: data.statusCode === 'NOT_FOUND',
        }),
      );
      yield put(pageActions.createAlert(RECOMMENDATIONERRORTOPTIONS));
    } else {
      yield put(recommendedOpportunitiesActions.setPageInfo(pageInfo));

      yield put(recommendedOpportunitiesActions.loadOpportunities(data.opportunities));
    }
  } catch (e) {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
  }
}

function* doFetchOpportunities(action) {
  const recommendationId = action && action.payload ? action.payload.recommendationId : null;
  yield call(doFetchRecommendedOpportunities, {
    researcherId: recommendationId,
    setLoaderOnPage: action.payload.setLoaderOnPage,
    clearOpportunities: action.payload.clearOpportunities,
  });
}

function* doUntrackOpportunity(action) {
  const { opportunityId } = action.payload;
  try {
    yield call(apiDelete, '/api/user/opportunities/', [opportunityId]);
    yield put(
      recommendedOpportunitiesActions.setUntrackStatus({
        status: true,
        opportunityId,
      }),
    );
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.REMOVE_FROM_MY_LIST,
        opportunityIds: [opportunityId],
      }),
    );
  } catch (e) {
    yield put(
      recommendedOpportunitiesActions.setUntrackStatus({
        status: false,
      }),
    );
    yield put(recommendedOpportunitiesActions.setIsModalOpen(false));
  }
}

function* doUntrackOpportunities(action) {
  const { ids } = action.payload;
  try {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(true));
    yield call(apiDelete, '/api/user/opportunities/', ids);
    yield put(recommendedOpportunitiesActions.deselectAll());
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
    yield put(
      recommendedOpportunitiesActions.setUntrackStatus({
        status: true,
        ids,
      }),
    );
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.REMOVE_FROM_MY_LIST,
        opportunityIds: ids,
      }),
    );
  } catch (e) {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
    yield put(
      recommendedOpportunitiesActions.setUntrackStatus({
        status: false,
      }),
    );
  }
}

function* doSelectAllSearchResult() {
  // const groupId = action && action.payload ? action.payload.groupId : null;
  try {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(true));
    const searchQuery = yield select((state) => state.recommendedOpportunities.searchQuery);
    const apiUrl = '/api/opportunities/ids';
    const apiQueryOptions = {
      status: null,
      scope: SEARCH_CATEGORIES.OPPORTUNITIES,
    };
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(recommendedOpportunitiesActions.setSelectedItems(ids));
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(recommendedOpportunitiesActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(TRACKALERTOPTIONS));
  }
}

function* doExportFile(action) {
  try {
    yield put(recommendedOpportunitiesActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);
    yield put(recommendedOpportunitiesActions.exportFileFailed(false));
    yield put(recommendedOpportunitiesActions.isFileExporting(false));
  } catch (e) {
    yield put(recommendedOpportunitiesActions.exportFileFailed(true));
    yield put(recommendedOpportunitiesActions.isFileExporting(false));
  }
}

function* doRemoveRecommendedOpportunity(action) {
  try {
    const page = yield select((state) => state.recommendedOpportunities.page);
    const url = `/api/user/recommendation-entities/${page.entityId}/recommendations/${action.payload.id}`;
    yield call(apiDelete, url);

    yield doFetchOpportunities({
      payload: {
        recommendationId: page.entityId,
        setLoaderOnPage: false,
        clearOpportunities: false,
      },
    });
  } catch (e) {
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

export default function* recommendedOpportunitiesSagas() {
  yield takeLatest(recommendedOpportunitiesActions.fetchOpportunities, doFetchOpportunities);
  yield takeEvery(recommendedOpportunitiesActions.untrackOpportunity, doUntrackOpportunity);
  yield takeEvery(recommendedOpportunitiesActions.untrackOpportunities, doUntrackOpportunities);
  yield takeEvery(recommendedOpportunitiesActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(recommendedOpportunitiesActions.exportFile, doExportFile);
  yield takeLatest(recommendedOpportunitiesActions.removeRecommendedOpportunity, doRemoveRecommendedOpportunity);
}
