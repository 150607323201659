import { CountryCodeMapping } from '../opportunities/opportunityMetaTypes.ts';

export const getSanitizedCountryItems = (codesMap: CountryCodeMapping) => {
  return Object.keys(codesMap).map((key) => {
    const item = codesMap[key as keyof CountryCodeMapping][0];
    const keywords = Object.values(item).filter((value) => typeof value === 'string');
    return {
      key,
      value: item.name,
      keywords,
      flag: item.alpha2,
    };
  });
};
