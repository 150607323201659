import { takeEvery, call, put } from 'redux-saga/lib/effects.js';

import { trackModalActions } from '../dispatchers/trackModal.js';
import { opportunitiesActions } from '../dispatchers/opportunities.js';
import { groupsActions } from '../dispatchers/groups.js';
import { pageActions } from '../dispatchers/page.js';
import { logActions } from '../dispatchers/log.js';

import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';

import { LOCALE, EVENT_NAMES, EXTERNAL_ROUTES } from '@fi/constants';

import { apiGet, apiPost, apiDelete } from '../../services/requests.ts';
import { showNotification } from '@fi/components/Notify';
import en from '../../refreshed/text/en.ts';
import Link from '@fi/libs/rt-ui/Link/Link';

const TRACKALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doOpenModal(action) {
  const { opportunitiesIds, isNewModal } = action.payload;
  try {
    yield put(trackModalActions.setSelectedOpportunities(opportunitiesIds));
    let selectedGroups = [];
    let alerts = [];
    let deadlines = [];
    let isReceivingUpdateAlerts = true;
    yield put(trackModalActions.setFetching(true));
    // start fetching all resources needed for the modal
    yield put(groupsActions.fetchList());
    deadlines = yield call(apiPost, '/api/opportunities/deadlines', opportunitiesIds);
    if (opportunitiesIds.length < 2) {
      const { items: opportunityDetails } = yield call(
        apiGet,
        `/api/user/opportunities?pageSize=1&page=0&opportunityId=${opportunitiesIds[0]}`,
      );
      isReceivingUpdateAlerts =
        opportunityDetails.length < 2 && opportunityDetails[0] ? opportunityDetails[0].savedOpportunity.tracked : true;
      alerts = opportunityDetails[0] ? opportunityDetails[0].savedOpportunity.deadlineAlertTypes : [];
      selectedGroups = yield call(apiGet, `/api/opportunities/${opportunitiesIds[0]}/groups`);
    }
    yield put(trackModalActions.setSelectedGroups(selectedGroups));
    yield put(trackModalActions.setReceivingUpdateAlerts(isReceivingUpdateAlerts));
    yield put(trackModalActions.setDeadlines(deadlines));
    yield put(trackModalActions.setAlerts(alerts));
    if (isNewModal) {
      yield put(trackModalActions.setIsNewModalOpen(true));
    } else {
      yield put(trackModalActions.setIsModalOpen(true));
    }
    // all fetch are done correctly, open the modal
  } catch (e) {
    // if something went wrong show the error and reset the modal
    yield put(pageActions.createAlert(TRACKALERTOPTIONS));
  } finally {
    yield put(trackModalActions.setFetching(false));
  }
}

function doOpenNewModal(action) {
  return doOpenModal({
    ...action,
    payload: {
      ...action.payload,
      isNewModal: true,
    },
  });
}

function* doCloseModal() {
  yield put(trackModalActions.resetModalState());
}

function* doTrackOpportunities(action) {
  const { ids, alertOptions, updateAlerts, selectedGroups } = action.payload;
  try {
    yield put(trackModalActions.setTracking(true));
    // track opportunities
    yield call(apiPost, '/api/user/opportunities', {
      opportunityIds: ids,
      tracked: updateAlerts,
      deadlineAlertTypes: alertOptions,
    });
    // if it's only one opportunity, unassign from previous group
    if (ids.length === 1) {
      yield call(apiDelete, `/api/opportunities/${ids[0]}/groups`);
    }
    // create new groups and Assign opportunities to groups
    if (selectedGroups) {
      yield call(apiPost, '/api/groups/_assign_opportunities', {
        opportunityIds: ids,
        groups: selectedGroups,
      });
    }
    yield put(
      trackModalActions.setTrackStatus({
        status: true,
        ids,
      }),
    );
    yield put(groupsActions.fetchList());
    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.SAVE_TO_LIST,
        opportunityIds: ids,
      }),
    );
    yield put(opportunitiesActions.setOpportunityIsTracked(ids));

    // Confirm successful flow of `track opportunity` API calls
    showNotification({
      title: en.components.opportunitiesToolbar.track.success,
    });
  } catch (e) {
    // Give feedback of error in API calls:
    showNotification({
      title: en.components.opportunitiesToolbar.track.error.title,
      message: (
        <>
          {en.components.opportunitiesToolbar.track.error.message}
          <Link href={EXTERNAL_ROUTES.SUPPORT_CENTER} isExternal size="small">
            {en.components.opportunitiesToolbar.track.error.linkText}
          </Link>
        </>
      ),
      errorNotification: true,
    });
    yield put(
      trackModalActions.setTrackStatus({
        status: false,
        ids,
      }),
    );
  } finally {
    yield put(trackModalActions.setTracking(false));
    yield put(trackModalActions.resetModalState());
  }
}

export default function* trackModalSagas() {
  yield takeEvery(trackModalActions.openModal, doOpenModal);
  yield takeEvery(trackModalActions.openNewModal, doOpenNewModal);
  yield takeEvery(trackModalActions.closeModal, doCloseModal);
  yield takeEvery(trackModalActions.trackOpportunities, doTrackOpportunities);
}
