import React, { ReactElement } from 'react';
import SnackBar from '@fi/libs/rt-ui/SnackBar/SnackBar';
import CheckIcon from '@fi/libs/rt-ui/SVGIcons/Check';
import DeleteIcon from '@fi/libs/rt-ui/SVGIcons/Delete';
import { Typography } from '@fi/typography';

interface NotificationProps {
  message?: string | React.ReactNode;
  additionalContent?: ReactElement;
  title?: string;
  /** In case of an error, we also want to notify our  */
  errorNotification?: boolean;
}

export const showNotification = ({ message, additionalContent, title, errorNotification }: NotificationProps) => {
  const NotificationIcon = () => (errorNotification ? <DeleteIcon /> : <CheckIcon />);
  const notificationType = errorNotification ? 'error' : 'success';

  return SnackBar.Toast(
    <SnackBar.ToastContent msg="" icon={<NotificationIcon />} type={notificationType}>
      {title && (
        <Typography renderAs="span" className="inline-block font-bold pb-3">
          {title}
        </Typography>
      )}
      <Typography renderAs="span" className="inline-block pr-1">
        {message}
      </Typography>
      {additionalContent}
    </SnackBar.ToastContent>,
    { containerId: 'app' },
  );
};

const Notify = () => {
  return (
    <div aria-live="polite">
      <SnackBar.Container closeOnClick={false} enableMultiContainer containerId="app" position="top-right" />
    </div>
  );
};

export default Notify;
