import { createAction, createReducer } from 'redux-act';

export const authActions = {
  sendAdminInvitation: createAction('send admin activation email'),
  setAdminInvitationIsLoading: createAction('set admin activation isLoading'),

  setAdminInvitationStatus: createAction('set admin activation status'),
  acceptAdminInvitation: createAction('accept admin activation request'),
};

const setAdminInvitationIsLoading = (state, isLoading) => ({
  ...state,
  adminInvitation: {
    ...state.adminInvitation,
    isLoading,
  },
});

const setAdminInvitationStatus = (state, status) => ({
  ...state,
  adminInvitation: {
    ...state.adminInvitation,
    status,
  },
});

/* Reducers */
export const initialState = {
  adminInvitation: {
    isLoading: false,
    status: null,
  },
};
export const authReducer = createReducer(
  {
    [authActions.setAdminInvitationIsLoading]: setAdminInvitationIsLoading,
    [authActions.setAdminInvitationStatus]: setAdminInvitationStatus,
  },
  initialState,
);
