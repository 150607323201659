import { createContext, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ActionCreator, userActions } from '@fi/redux/dispatchers/user';
import * as userApi from '../../services/api/user.ts';
import InitialLoadingScreen from '@fi/components/InitialLoadingScreen';
import { UserIdentityColor, USER_ROLES, DEFAULT_USER_ROLE, INTEGRATION_PRODUCTS, EDITING_ROLES, ADMIN_ROLES } from '@fi/constants';

import { User, FeaturePermission } from './types.ts';

interface ProviderStore {
  user: User | null;
  role: USER_ROLES;
  identityColor: UserIdentityColor;
  isUserGreen: boolean;
  isUserBlue: boolean;
  isUserGrey: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  logout: () => void;
  isIntegrated: (product: INTEGRATION_PRODUCTS) => boolean;
}

// For using in class components
export const AuthContext = createContext<ProviderStore | undefined>(undefined);

interface AuthProviderProps {
  setUserInStore: ActionCreator;
  children: React.ReactNode;
}

const AuthProvider = (props: AuthProviderProps) => {
  const { setUserInStore, children } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  async function fetchUser() {
    let userData;

    try {
      setIsLoading(true);
      userData = await userApi.getUser();
      userData.featurePermissions = new Map(userData.featurePermissions.map((e: FeaturePermission) => [e.feature, e.permission]));
    } catch (e) {
      userData = null;
    } finally {
      setUserInStore(userData);
      setUser(userData);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  if (isLoading) {
    return <InitialLoadingScreen />;
  }

  const store = buildAuth(user);

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

export const buildAuth = (user: User | null) => {
  const identityColor = user ? user.color : UserIdentityColor.GREY;

  // Currently no extra actions (e.g., cleaning store) are required, as logout triggers full application reload
  const logout = () => userApi.logout();

  const accountIntegrations = user?.integrations ?? [];

  const isUserGreen = identityColor === UserIdentityColor.GREEN;
  const isUserBlue = identityColor === UserIdentityColor.BLUE;
  const isUserGrey = identityColor === UserIdentityColor.GREY;
  const role = isUserGreen && user ? user.role : DEFAULT_USER_ROLE;

  const store: ProviderStore = {
    user,
    role,
    identityColor,
    isUserGreen,
    isUserBlue,
    isUserGrey,
    isAdmin: ADMIN_ROLES.includes(role),
    isEditor: EDITING_ROLES.includes(role),
    logout,
    isIntegrated: (product) => accountIntegrations.includes(product),
  };

  return store;
};

const ConnectedAuthProvider = connect(null, (dispatch) =>
  bindActionCreators(
    {
      setUserInStore: userActions.setUserData,
    },
    dispatch,
  ),
)(AuthProvider);

export default ConnectedAuthProvider;
