import { createAction, createReducer } from 'redux-act';

import { pushSearchUrlToHistory, setIsLoadingResults, setCurrentPage } from './common.js';

export const recommendationsActions = {
  fetch: createAction('fetch recommendations'),
  setIsLoadingResults: createAction('set loading results'),
  resetSearchQuery: createAction('reset SearchQuery'),
  loadRecommendations: createAction('Load recommendations'),
  setRecommendations: createAction('set recommendations'),
  pushSearchUrlToHistory: createAction('push search url to history'),
  setCurrentPage: createAction('set current page'),
  setSearchKey: createAction('set search key'),

  /* delete confirmation modal */
  deleteRecommendation: createAction('delete recommendation'),
  openDeleteConfirmationModal: createAction('open delete recommendation confirmation modal'),
  closeDeleteConfirmationModal: createAction('close delete recommendation confirmation modal'),
  setStatusDeleteConfirmationModal: createAction('open deleting status for recommendation confirmation modal'),
  setIsDeletingConfirmationModal: createAction('set loading status while recommendation is deleting'),
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
};

const setSearchKey = (state, searchKey) => {
  return {
    ...state,
    searchKey,
  };
};

const setRecommendations = (state, results) => {
  return {
    ...state,
    results,
  };
};

const loadRecommendations = (state, results) => {
  return {
    ...state,
    results,
    originalResults: results,
  };
};

const resetSearchQuery = (state) => ({
  ...state,
  searchQuery: SEARCH_QUERY_DEFAULT,
});

/* delete confirmation modal */

const openDeleteConfirmationModal = (state, selectedRecommendation) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    selectedRecommendation,
    isOpen: true,
  },
});

const closeDeleteConfirmationModal = (state) => ({
  ...state,
  deleteConfirmationModal: {
    selectedRecommendation: null,
    status: null,
    isDeleting: false,
    isOpen: false,
  },
});

const setStatusDeleteConfirmationModal = (state, status) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    status,
  },
});

const setIsDeletingConfirmationModal = (state, isDeleting) => ({
  ...state,
  deleteConfirmationModal: {
    ...state.deleteConfirmationModal,
    isDeleting,
  },
});

/* Reducers */
export const recommendationsReducer = createReducer(
  {
    [recommendationsActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,
    [recommendationsActions.setIsLoadingResults]: setIsLoadingResults,
    [recommendationsActions.loadRecommendations]: loadRecommendations,
    [recommendationsActions.setRecommendations]: setRecommendations,
    [recommendationsActions.resetSearchQuery]: resetSearchQuery,
    [recommendationsActions.setCurrentPage]: setCurrentPage,
    [recommendationsActions.setSearchKey]: setSearchKey,

    /* delete confirmation modal */
    [recommendationsActions.openDeleteConfirmationModal]: openDeleteConfirmationModal,
    [recommendationsActions.closeDeleteConfirmationModal]: closeDeleteConfirmationModal,
    [recommendationsActions.setStatusDeleteConfirmationModal]: setStatusDeleteConfirmationModal,
    [recommendationsActions.setIsDeletingConfirmationModal]: setIsDeletingConfirmationModal,
  },
  {
    searchHistory: '',
    isLoadingResults: false,
    results: [],
    originalResults: [],
    totalResults: 1,
    searchKey: '',
    searchQuery: SEARCH_QUERY_DEFAULT,
    currentPage: 1,
    deleteConfirmationModal: {
      selectedRecommendation: null,
      isDeleting: false,
      status: null,
      isOpen: false,
    },
  },
);
