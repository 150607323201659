import axios, { AxiosRequestConfig, AxiosError } from 'axios';

import { AxiosDefaults } from '@fi/hooks/useRequest';

const request = axios.create(AxiosDefaults);

request.interceptors.response.use(
  (response) => response.data,
  (error: AxiosError) => Promise.reject(error.response),
);

export const apiPost = <R>(url: string, data?: unknown, options?: AxiosRequestConfig) => request.post<void, R>(url, data, options);

export const apiGet = <R>(url: string, options?: AxiosRequestConfig) => request.get<void, R>(url, options);

export const apiPut = <R>(url: string, data?: unknown) => request.put<void, R>(url, data);

export const apiPatch = <R>(url: string, data: unknown) => request.patch<void, R>(url, data);

export const apiDelete = <R>(url: string, payload?: unknown) => {
  // it is mandatory to define data key for axios.
  const val = { data: payload };
  return request.delete<void, R>(url, val);
};
