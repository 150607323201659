import { createAction, createReducer } from 'redux-act';
import { buildAuth } from '@fi/context/AuthContext/AuthContext';

export const userActions = {
  fetchUserData: createAction('fetch user data'),
  setUserData: createAction('Set user data'),
  clearUserData: createAction('Set error loading user data'),
  setLoadingUserData: createAction('set loading user data'),
  fetchUserPreferences: createAction('fetch user preferences'),
  setLoadingUserPreferences: createAction('set loading user preferences'),
  saveUserPreferences: createAction('save user preferences'),
  setSavingUserPreferences: createAction('save user preferences'),
  setUserPreferences: createAction('set loading user preferences'),
  clearUserPreferences: createAction('clear user preferences'),
};

const setIsLoadingUserData = (state, isLoadingUserData) => ({
  ...state,
  isLoadingUserData,
});

const setUserData = (state, userData) => ({
  ...state,
  auth: buildAuth(userData),
  isLoadingUserData: false,
  userData: {
    ...state.userData,
    ...userData,
  },
});

const clearUserData = (state) => ({
  ...state,
  isLoadingUserData: false,
  userData: null,
});

const setUserPreferences = (state, userPreferences) => ({
  ...state,
  userPreferences,
});

const setLoadingUserPreferences = (state, isLoadingUserPreferences) => ({
  ...state,
  isLoadingUserPreferences,
});

const setSavingUserPreferences = (state, isSavingUserPreferences) => ({
  ...state,
  isSavingUserPreferences,
});

const clearUserPreferences = (state) => ({
  ...state,
  isLoadingUserPreferences: false,
  isSavingUserPreferences: false,
  userPreferences: null,
});

/* Reducers */
export const userReducer = createReducer(
  {
    [userActions.setUserData]: setUserData,
    [userActions.clearUserData]: clearUserData,
    [userActions.setLoadingUserData]: setIsLoadingUserData,
    [userActions.setSavingUserPreferences]: setSavingUserPreferences,
    [userActions.setUserPreferences]: setUserPreferences,
    [userActions.setLoadingUserPreferences]: setLoadingUserPreferences,
    [userActions.clearUserPreferences]: clearUserPreferences,
  },
  {
    isLoadingUserData: false,
    userData: null,
    isLoadingUserPreferences: false,
    isSavingUserPreferences: false,
    userPreferences: null,
  },
);
