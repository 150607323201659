import { createRoot } from 'react-dom/client';

import { bootstrapFI } from './envConfig.ts';
import AppProviders from './context/AppProviders';
import Routes from './routes';
import './assets/styles/app.scss';

bootstrapFI().catch(console.error);
window.FI_BUILD_INFO = __FI_BUILD_INFO__;

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <AppProviders>
    <Routes />
  </AppProviders>,
);
