import { NumberUtils } from './NumberUtils.js';
import { getFunders } from '../services/api/autocompletion.ts';

export class FacetUtils {
  static getResearchAreaCounts(payload, researchAreas) {
    const countMap = {};
    const { subLevelDisciplines, topLevelDisciplines } = payload;

    subLevelDisciplines.forEach((discipline) => {
      countMap[discipline.code] = NumberUtils.formatToFixed(discipline.count);
    });

    topLevelDisciplines.forEach((discipline) => {
      countMap[discipline.code] = NumberUtils.formatToFixed(discipline.count);
    });

    return Object.assign({}, researchAreas, {
      countMap,
    });
  }

  static getAggregationItemCounts(aggregations, itemsObject) {
    const countMap = {};

    aggregations.forEach((item) => {
      countMap[item.code] = item.count;
    });

    const newItems = itemsObject.items.map((el) => ({
      ...el,
      count: countMap[el.id] ? countMap[el.id] : 0,
    }));

    return {
      items: newItems,
    };
  }

  static getItemCountsFromFiltersBuckets(buckets, itemsObject) {
    const newItems = itemsObject.items.map((el) => ({
      ...el,
      count: buckets[el.id] ? buckets[el.id].doc_count : 0,
    }));
    return {
      items: newItems,
    };
  }

  static getFundingOrganizationsWithCount(funders, fundingOrganizations) {
    const { countMap } = fundingOrganizations;

    return funders.map((funder) => ({
      key: String(funder.orgDbId),
      value: funder.preferredOrgName,
      displayValue: funder.preferredOrgName,
      count: countMap[funder.orgDbId] ? countMap[funder.orgDbId] : undefined,
    }));
  }

  static getFunderAutoCompleteMatches(phrase, fundingOrganizations) {
    return getFunders(phrase).then((response) => {
      return this.getFundingOrganizationsWithCount(response.suggestions, fundingOrganizations);
    });
  }

  static fundersFunderTypeGetter(payload) {
    return payload.aggregations && payload.aggregations['global#all_items']['filters#funder_types_filtered'].buckets;
  }

  static getFunderTypeWithCount(payload, funderType, funderTypeGetter) {
    const countMap = {};
    const buckets = funderTypeGetter(payload);

    funderType.items.forEach((item) => {
      countMap[item.key] = NumberUtils.formatToFixed(buckets[item.key].doc_count);
    });

    return Object.assign(
      {},
      funderType,
      {
        countMap,
      },
      this.getItemCountsFromFiltersBuckets(buckets, funderType),
    );
  }

  static getFunderTypeCounts(payload, funderType) {
    const countMap = {};
    const funderTypeMap = this.toFunderTypeMap(payload);

    funderType.items.forEach((item) => {
      countMap[item.key] = NumberUtils.formatToFixed(funderTypeMap[item.key]);
    });

    return Object.assign(
      {},
      funderType,
      {
        countMap,
      },
      this.getFunderTypeCountItems(funderTypeMap, funderType),
    );
  }

  static toFunderTypeMap(payload) {
    return payload.funderTypes
      .map((funderType) => {
        return {
          [funderType.groupId]: funderType.count,
        };
      })
      .reduce((previousValue, currentValue) => {
        return { ...previousValue, ...currentValue };
      }, {});
  }

  static getFunderTypeCountItems(funderTypeMap, funderType) {
    const newItems = funderType.items.map((el) => ({
      ...el,
      count: funderTypeMap[el.id] ?? 0,
    }));
    return {
      items: newItems,
    };
  }

  static getStartYearsWithCount(payload, startYear) {
    const countMap = new Map();
    payload.years.forEach((item) => {
      countMap.set(item.year, NumberUtils.formatToFixed(item.count));
    });

    return Object.assign({}, startYear, {
      countMap,
    });
  }

  static getGrantTypesWithCount(payload, grantTypes) {
    const countMap = {};
    payload.grantTypes.forEach((grantType) => {
      countMap[grantType.typeId] = grantType.count;
    });

    const items = [...grantTypes.items];
    items.sort((x1, x2) => {
      return countMap[x2.key] - countMap[x1.key];
    });

    Object.keys(countMap).forEach((key) => {
      countMap[key] = NumberUtils.formatToFixed(countMap[key]);
    });
    return Object.assign({}, grantTypes, {
      items,
      countMap,
    });
  }

  static getAwardeeCountriesWithCounter(payload, awardeeCountries) {
    const countMap = {};
    payload.countries.forEach((country) => {
      countMap[country.countryCode] = NumberUtils.formatToFixed(country.count);
    });

    return Object.assign({}, awardeeCountries, {
      countMap,
    });
  }
}
