import { call, put, all, takeLatest, takeEvery, select } from 'redux-saga/lib/effects.js';

import download from 'downloadjs';
import { fundersActions } from '../dispatchers/funders.js';
import { fixturesActions } from '../dispatchers/fixtures.js';
import { pageActions } from '../dispatchers/page.js';
import { apiGet, apiPost } from '../../services/requests.ts';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { getSanitizedCountryItems } from '@fi/util/getSanitizedCountryItems';
import { SEARCH_CATEGORIES, LOCALE, MAX_SELECTED_ITEMS } from '@fi/constants';
import { exportFile } from '../../services/api/exportToFile.js';
import { createFunderSearchQueryObject } from '../../query/funderSearchQuery.ts';
import { getAggregations } from '../../services/api/opportunities.ts';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchMetadata() {
  try {
    const [metadata, funderTypes] = yield all([
      call(apiGet, '/api/opportunities/.meta'),
      call(apiGet, '/api/descriptions/fundingOrgTypes'),
    ]);
    yield put(fundersActions.loadFunderType(funderTypes));
    const items = getSanitizedCountryItems(metadata.countryCodeMapping);
    const countries = {
      items,
    };
    yield put(fixturesActions.setCountries(countries));
    yield put(fixturesActions.setCountriesMap(metadata.countryCodeMapping));
    yield put(fundersActions.setIsLoadingResults(false));
    yield put(fundersActions.setMetadataIsFetched());
  } catch (e) {
    yield put(fundersActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doExportFile(action) {
  try {
    yield put(fundersActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);
    yield put(fundersActions.exportFileFailed(false));
    yield put(fundersActions.isFileExporting(false));
  } catch (e) {
    yield put(fundersActions.exportFileFailed(true));
    yield put(fundersActions.isFileExporting(false));
  }
}

function* doRunSearch(action) {
  const { searchQuery } = action.payload;
  const apiQueryOptions = {
    scope: SEARCH_CATEGORIES.FUNDERS,
  };
  try {
    yield put(fundersActions.updateSearch(searchQuery));
    const queryObject = createFunderSearchQueryObject(searchQuery, apiQueryOptions);
    yield put(fundersActions.fetchFunders(queryObject, apiQueryOptions));
  } catch (e) {
    yield put(fundersActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doFetchFunders(action) {
  const searchQuery = action.payload;
  const apiUrl = '/api/funders';
  try {
    yield put(pageActions.resetAlerts());
    yield put(fundersActions.setIsLoadingResults(true));
    const funders = yield call(apiPost, apiUrl, searchQuery);
    yield put(fundersActions.loadFunders(funders));
    yield put(fundersActions.setIsLoadingResults(false));
    yield put(fundersActions.setMetadataIsFetched());
  } catch (e) {
    yield put(fundersActions.setIsLoadingResults(false));
    if (e.status === 400) {
      var options = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.SEARCH_ERROR + ' ' + e.data.errorDescription),
      };
      yield put(pageActions.createAlert(options));
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  }
}

function* doSelectAllSearchResult() {
  try {
    yield put(fundersActions.setIsLoadingResults(true));
    yield put(fundersActions.deselectAll());
    const searchQuery = yield select((state) => state.funders.searchQuery);
    const apiUrl = '/api/funders/ids';
    const apiQueryOptions = {
      scope: SEARCH_CATEGORIES.FUNDERS,
    };
    const queryObject = createFunderSearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(fundersActions.setSelectedItems(ids));
    yield put(fundersActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(fundersActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doFetchPublicFunderDetails(action) {
  const { funderId } = action.payload;
  const funderApiUrl = `/api/funders/${funderId}`;
  const awardsApiUrl = `/api/awards/stats?funder=${funderId}`;
  const opportunitiesMetadataApiUrl = `/api/opportunities/.meta`;

  try {
    yield put(fundersActions.setIsLoadingPublicFunderDetails(true));

    // 3 queries below do not require authentication
    const [funderDetails, awards, metadata] = yield all([
      call(apiGet, funderApiUrl),
      call(apiGet, awardsApiUrl),
      call(apiGet, opportunitiesMetadataApiUrl),
    ]);

    const publicFunderDetails = {
      data: funderDetails,
      barChartData: awards.aggregations,
      totalGrants: awards.hits.total.value,
      countryCodeMapping: metadata.countryCodeMapping,
      grantTypeDescriptions: metadata.grantTypeDescriptions,
    };
    yield put(fundersActions.setPublicFunderDetails(publicFunderDetails));
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(pageActions.set404());
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  } finally {
    yield put(fundersActions.setIsLoadingPublicFunderDetails(false));
  }
}

/**
 * Run after `doFetchPublicFunderDetails()`, because it needs data from that call
 */
function* doFetchPrivateFunderDetails(action) {
  const { funderId, funderDetails, grantTypeDescriptions } = action.payload;
  const funderTypesApiUrl = '/api/classifications/asjc';

  try {
    yield put(fundersActions.setIsLoadingPrivateFunderDetails(true));

    const opportunityQuery = {
      status: ['active'],
      funder: funderId,
    };

    // Below query does not require authentication
    const funderTypes = yield call(apiGet, funderTypesApiUrl);

    // Below 2 queries need authentication
    const [grantTypes, topLevelDisciplines] = yield all([
      call(getAggregations, 'grant-type', opportunityQuery),
      call(getAggregations, 'top-level-discipline', opportunityQuery),
    ]);

    const treeMapChartData = {
      counts: topLevelDisciplines.aggregations,
      topLevelCategories: funderTypes,
      totalCount: topLevelDisciplines.totalCount,
      funderName: funderDetails.preferredOrgName,
    };
    const pieChartData = {
      typeDescription: grantTypeDescriptions,
      counts: grantTypes,
      funderName: funderDetails.preferredOrgName,
    };

    const privateFunderDetails = {
      treeMapChartData,
      pieChartData,
    };

    yield put(fundersActions.setPrivateFunderDetails(privateFunderDetails));
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(pageActions.set404());
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  } finally {
    yield put(fundersActions.setIsLoadingPrivateFunderDetails(false));
  }
}

export default function* fundersSaga() {
  yield takeLatest(fundersActions.fetchFunders, doFetchFunders);
  yield takeEvery(fundersActions.fetchPublicFunderDetails, doFetchPublicFunderDetails);
  yield takeEvery(fundersActions.fetchPrivateFunderDetails, doFetchPrivateFunderDetails);
  yield takeEvery(fundersActions.fetchFundersMetadata, doFetchMetadata);
  yield takeEvery(fundersActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(fundersActions.runSearch, doRunSearch);
  yield takeLatest(fundersActions.exportFile, doExportFile);
}
