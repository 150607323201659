import { takeEvery, call, put } from 'redux-saga/lib/effects.js';

import { userActions } from '../dispatchers/user.js';
import { apiGet, apiPost } from '../../services/requests.ts';
import { doGetAccountContactPerson } from './usersRoles.js';
import { pageActions } from '../dispatchers/page.js';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { LOCALE } from '@fi/constants';

const ERROR_ALERT_OPTIONS = {
  ...getDefaultAlertOptions('user', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchUserData() {
  try {
    yield put(userActions.setLoadingUserData(true));
    const data = yield call(apiGet, '/user/profile');
    yield call(doGetAccountContactPerson);
    yield put(userActions.setUserData(data));
  } catch (e) {
    yield put(userActions.clearUserData());
  } finally {
    yield put(userActions.setLoadingUserData(false));
  }
}

function* fetchUserPreferences() {
  try {
    yield put(userActions.setLoadingUserPreferences(true));
    const userPreferences = yield call(apiGet, '/api/user/preferences');
    yield put(userActions.setUserPreferences(userPreferences));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
    yield put(userActions.clearUserPreferences());
  } finally {
    yield put(userActions.setLoadingUserPreferences(false));
  }
}

function* saveUserPreferences(action) {
  try {
    const userPreferences = action.payload;
    yield put(userActions.setSavingUserPreferences(true));
    yield call(apiPost, '/api/user/preferences', userPreferences);
    yield put(userActions.setUserPreferences(userPreferences));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
    yield put(userActions.clearUserPreferences());
  } finally {
    yield put(userActions.setSavingUserPreferences(false));
  }
}

export default function* userSaga() {
  yield takeEvery(userActions.fetchUserData, doFetchUserData);
  yield takeEvery(userActions.fetchUserPreferences, fetchUserPreferences);
  yield takeEvery(userActions.saveUserPreferences, saveUserPreferences);
}
