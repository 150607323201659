import { createAction, createReducer } from 'redux-act';

import {
  selectItem,
  deselectItems,
  setIsLoadingResults,
  deselectAll,
  selectAllPage,
  setSelectedItems,
  paginateSearch,
  loadFunderType,
  updateSearchQueryReducer,
  setMetadataIsFetched,
  pushSearchUrlToHistory,
  isFileExporting,
  exportFileFailed,
  setSelectAllContext,
} from './common.js';
import { FUNDERS_SORT_OPTIONS } from '@fi/constants';
import { FacetUtils } from '@fi/util/FacetUtils';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const fundersActions = {
  runSearch: createAction('run search'),
  fetchFunders: createAction('fetch funders'),
  fetchPublicFunderDetails: createAction('fetch public funder details'),
  fetchPrivateFunderDetails: createAction('fetch private funder details'),
  fetchFundersMetadata: createAction('fetch funders metadata'),
  setIsLoadingResults: createAction('set is loading results'),
  setIsLoadingFunderDetails: createAction('set is loading details'),
  setIsLoadingPublicFunderDetails: createAction('set is loading public details'),
  setIsLoadingPrivateFunderDetails: createAction('set is loading private details'),
  setFunderDetails: createAction('set funder details'),
  setPublicFunderDetails: createAction('set public funder details'),
  setPrivateFunderDetails: createAction('set private funder details'),
  loadFundingOrganizations: createAction('Load funding organizations'),
  setMetadataIsFetched: createAction('set is fetched metadata'),
  loadFunders: createAction('Load funders'),
  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  updateSearch: createAction('Update funders search state'),
  resetFilters: createAction('Reset filters'),
  paginateSearch: createAction('Paginate funders search'),
  loadFunderType: createAction('Load funder type'),
  pushSearchUrlToHistory: createAction('push search url to history'),
  setSelectAllContext: createAction('Set select all context'),

  exportFile: createAction('exporting file'),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),
  deleteFunderNote: createAction('delete funder note'),
  setSingleFunderNote: createAction('set single funder note'),
};

const SEARCH_QUERY_DEFAULT = {
  keyword: '',
  sortOptions: FUNDERS_SORT_OPTIONS,
  selectedSortOption: FUNDERS_SORT_OPTIONS[0],
  currentPage: 1,
  funderCountries: {
    selectedItems: [],
  },
  funderType: {
    selectedItems: [],
  },
  funderData: {
    id: '',
  },
};

/* Reducers */
const loadFunders = (state, data) => {
  const funderType = FacetUtils.getFunderTypeWithCount(data, state.funderType, FacetUtils.fundersFunderTypeGetter);
  return {
    ...state,
    funderType,
    results: data.hits.hits,
    totalResults: data.hits.total.value,
    currentPage: state.currentPage,
    isLoading: false,
  };
};
const resetFiltersReducer = (state) => {
  return {
    ...state,
    isLoadingResults: true,
    isMetadataFetched: false,
    searchQuery: SEARCH_QUERY_DEFAULT,
  };
};
const setIsLoadingFunderDetails = (state, isLoadingFunderDetails) => ({
  ...state,
  isLoadingFunderDetails,
});
const setIsLoadingPublicFunderDetails = (state, isLoadingPublicFunderDetails) => ({
  ...state,
  isLoadingPublicFunderDetails,
});
const setIsLoadingPrivateFunderDetails = (state, isLoadingPrivateFunderDetails) => ({
  ...state,
  isLoadingPrivateFunderDetails,
});
const setFunderDetails = (state, funderDetails) => ({
  ...state,
  funderDetails,
});
const setPublicFunderDetails = (state, publicFunderDetails) => ({
  ...state,
  publicFunderDetails,
});
const setPrivateFunderDetails = (state, privateFunderDetails) => ({
  ...state,
  privateFunderDetails,
});

const setSingleFunderNote = (state, payload) => {
  const { funderId, funderNote } = payload;

  return {
    ...state,
    funderNotes: {
      ...state.funderNotes,
      [funderId]: funderNote,
    },
  };
};

const INITIAL_FUNDERS_STATE = {
  publicFunderDetails: {
    data: {},
    barChartData: {},
    totalGrants: 0,
    countryCodeMapping: {},
    grantTypeDescriptions: {},
  },
  privateFunderDetails: {
    treeMapChartData: {},
    pieChartData: {},
  },
};

export const fundersReducer = createReducer(
  {
    [fundersActions.setIsLoadingResults]: setIsLoadingResults,
    [fundersActions.setMetadataIsFetched]: setMetadataIsFetched,
    [fundersActions.loadFunders]: loadFunders,
    [fundersActions.updateSearch]: updateSearchQueryReducer,
    [fundersActions.selectItem]: selectItem,
    [fundersActions.deselectItems]: deselectItems,
    [fundersActions.deselectAll]: deselectAll,
    [fundersActions.selectAllPage]: selectAllPage,
    [fundersActions.setSelectedItems]: setSelectedItems,
    [fundersActions.resetFilters]: resetFiltersReducer,
    [fundersActions.paginateSearch]: paginateSearch,
    [fundersActions.loadFunderType]: loadFunderType,
    [fundersActions.setIsLoadingFunderDetails]: setIsLoadingFunderDetails,
    [fundersActions.setIsLoadingPublicFunderDetails]: setIsLoadingPublicFunderDetails,
    [fundersActions.setIsLoadingPrivateFunderDetails]: setIsLoadingPrivateFunderDetails,
    [fundersActions.setFunderDetails]: setFunderDetails,
    [fundersActions.setPublicFunderDetails]: setPublicFunderDetails,
    [fundersActions.setPrivateFunderDetails]: setPrivateFunderDetails,
    [fundersActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,
    [fundersActions.setSelectAllContext]: setSelectAllContext,
    [fundersActions.isFileExporting]: isFileExporting,
    [fundersActions.exportFileFailed]: exportFileFailed,
    [fundersActions.setSingleFunderNote]: setSingleFunderNote,
  },
  {
    searchHistory: '', // latest search query
    searchQuery: SEARCH_QUERY_DEFAULT,
    results: [],
    selectedItems: {
      // the model is: [pageNumber]: new Set(),
    },
    funderType: {
      items: [],
      countMap: {},
    },
    totalResults: 0,
    isLoadingResults: true,
    isLoadingFunderDetails: true,
    isLoadingPrivateFunderDetails: true,
    isLoadingPublicFunderDetails: true,
    isMetadataFetched: false,
    currentKeywordInputValue: '',
    publicFunderDetails: INITIAL_FUNDERS_STATE.publicFunderDetails,
    privateFunderDetails: INITIAL_FUNDERS_STATE.privateFunderDetails,
    selectAllContext: 'all',
    exportFailed: null,
    funderNotes: {},
  },
);
