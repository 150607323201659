import { PAGE_SIZE } from '@fi/constants';
import { compareAlphabetically } from '@fi/util/compareAlphabetically';
import { createResearchAreas } from '@fi/util/createResearchAreas';
import { deleteKeyValuePairByValue } from '@fi/util/deleteKeyValuePairByValue';
import { FacetUtils } from '@fi/util/FacetUtils';
import { NumberUtils } from '@fi/util/NumberUtils';

/* -------------------- Reusable reducers --------------------- */
export function updateSearchQueryReducer(state, data) {
  const hasPayload = Object.prototype.hasOwnProperty.call(data, 'payload');
  const searchQuery = hasPayload ? data.payload : data;
  const newState = {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      ...searchQuery,
    },
  };

  // don't reset the selection if the keyword hasn't changed (it happens when you blur the search field)
  if (data.keyword !== state.searchQuery.keyword) {
    Object.assign(newState, {
      selectedItems: {},
    });
  }

  return newState;
}
export function exportFileFailed(state, failed) {
  return {
    ...state,
    exportFailed: failed,
    isFileExporting: false,
  };
}

export function isFileExporting(state, exporting) {
  return {
    ...state,
    isFileExporting: exporting,
  };
}

export function loadFunderType(state, data) {
  const items = data.map((item) => {
    return {
      key: item.groupId.toString(),
      value: item.description,
      searchValues: item.orgTypes,
    };
  });
  const funderType = Object.assign({}, { items, countMap: {} });
  return {
    ...state,
    funderType,
  };
}

export const setIsLoadingResults = (state, isLoadingResults) => {
  return {
    ...state,
    isLoadingResults,
  };
};

export function paginateSearch(state, page) {
  const searchQuery = Object.assign({}, state.searchQuery, {
    currentPage: page,
  });
  return Object.assign({}, state, {
    searchQuery,
  });
}

export const pushSearchUrlToHistory = (state, searchUrl) => ({
  ...state,
  searchHistory: searchUrl,
});

/**
 * @typedef {Object} PayloadObject - parameters required to sort opportunities for export
 * @property {number} id - item: opportunity / award / funder
 * @property {number} page - page in the search results, from the URL
 * @property {number} index - position of the item on the page
 */

/**
 * @param {Object} state
 * @param {PayloadObject} payload - the ID of the opportunity/award/funder
 */
export const selectItem = (state, payload) => {
  const { id, page, index } = payload;
  const position = (page - 1) * PAGE_SIZE + index;

  const updatedSelectedItems = Object.assign({}, state.selectedItems);
  Object.assign(updatedSelectedItems, {
    [position]: id,
  });
  return {
    ...state,
    selectedItems: updatedSelectedItems,
  };
};

export const deselectItems = (state, deselectedIds) => {
  const updatedSelectedItems = Object.assign({}, state.selectedItems);
  deselectedIds.forEach((deselectedId) => {
    deleteKeyValuePairByValue(updatedSelectedItems, deselectedId);
  });
  return {
    ...state,
    selectedItems: updatedSelectedItems,
  };
};

/**
 * @param {Object} state
 * @param {number} page - the page in the search results, from the URL
 */
export const selectAllPage = (state, page) => {
  const position = (page - 1) * PAGE_SIZE;

  const updatedSelectedItems = Object.assign({}, state.selectedItems);
  state.results.forEach((result, index) => {
    // first is opportunity, then funder, then award
    const id = result.id || result._source.orgDbId || result._source.id;
    Object.assign(updatedSelectedItems, {
      [position + index]: id,
    });
  });
  return {
    ...state,
    selectedItems: updatedSelectedItems,
  };
};

export const setSelectedItems = (state, ids) => {
  const updatedSelectedItems = Object.assign({}, state.selectedItems);
  ids.forEach((id, index) => {
    Object.assign(updatedSelectedItems, {
      [index]: id,
    });
  });
  return {
    ...state,
    selectedItems: updatedSelectedItems,
  };
};

export const deselectAll = (state) => {
  return {
    ...state,
    selectedItems: {},
  };
};

export function researchAreasReducer(state, classifications) {
  const items = createResearchAreas(classifications);
  return {
    ...state,
    researchAreas: {
      ...state.researchAreas,
      items,
    },
  };
}
export const setInitialCountMap = (state, initialCountMap) => ({
  ...state,
  researchAreas: {
    ...state.researchAreas,
    initialCountMap,
  },
});
export const setMetadataIsFetched = (state, flag) => {
  return {
    ...state,
    isMetadataFetched: typeof flag === 'undefined' ? true : flag,
  };
};

export const setLoadingSimilarOpportunities = (state, isLoading) => ({
  ...state,
  similarOpportunities: {
    ...state.similarOpportunities,
    isLoading,
  },
});

export const setSimilarOpportunities = (state, payload) => {
  const items = payload.hits.hits.map((item) => {
    const opportunity = item._source;
    opportunity.score = item._score;
    opportunity.missingWords = item.missingPhrases;
    return opportunity;
  });
  return {
    ...state,
    similarOpportunities: {
      ...state.similarOpportunities,
      items,
    },
  };
};

export function acceptOpportunitiesFunderType(state, payload) {
  const items = [];
  Object.keys(payload).forEach((key) => {
    const item = payload[key];
    const id = item.groupId.toString();
    items.push({
      id,
      key: id,
      value: item.description,
      types: item.groupId,
    });
  });

  items.sort((a, b) => compareAlphabetically(a.value, b.value));

  const funderType = Object.assign({}, state.funderType, { items });

  return Object.assign({}, state, {
    funderType,
  });
}

export function acceptFundingTypesReducer(state, payload) {
  const items = [];
  Object.keys(payload).forEach((key) => {
    const item = payload[key];
    const id = item.typeId.toString();
    items.push({
      id,
      key: id,
      description: item.description,
      value: item.description,
    });
  });

  items.sort((a, b) => compareAlphabetically(a.value, b.value));

  const fundingTypes = Object.assign({}, state.fundingTypes, { items });

  return Object.assign({}, state, {
    fundingTypes,
  });
}

export function acceptFundingOrganizationsReducer(state, payload) {
  const { funders } = payload;
  const {
    fundingOrganizations: { itemsMap },
  } = state;

  funders.forEach((item) => (itemsMap[item.key] = item));

  return Object.assign({}, state, {
    fundingOrganizations: {
      ...state.fundingOrganizations,
      items: Object.values(itemsMap),
      itemsMap,
    },
  });
}

export function acceptFundingOrganizationsCountsReducer(state, payload) {
  const { funders } = payload;
  const countMap = {};

  if (funders) {
    funders.forEach((item) => {
      countMap[item.code] = NumberUtils.formatToFixed(item.count);
    });
  }

  return Object.assign({}, state, {
    fundingOrganizations: {
      ...state.fundingOrganizations,
      countMap: {
        ...state.fundingOrganizations.countMap,
        ...countMap,
      },
    },
  });
}

export function acceptFundingOrganizationsSearchResponseReducer(state, response) {
  const { fundingOrganizations } = state;
  const hits = response.hits.hits.map((hit) => hit._source);
  const funders = FacetUtils.getFundingOrganizationsWithCount(hits, fundingOrganizations);

  return acceptFundingOrganizationsReducer(state, { funders });
}

export function setIndividualApplicantTypesReducer(state, payload) {
  return Object.assign({}, state, {
    individualApplicantTypes: {
      items: payload.map((el) => ({
        ...el,
        key: el.id,
        value: el.description,
      })),
    },
  });
}

export function setOrganizationalApplicantTypesReducer(state, payload) {
  return Object.assign({}, state, {
    organizationalApplicantTypes: {
      items: payload.map((el) => ({
        ...el,
        key: el.id,
        value: el.description,
      })),
    },
  });
}

export function setDegreeRequirementsReducer(state, payload) {
  return Object.assign({}, state, {
    degreeRequirements: {
      items: payload.map((el) => ({
        ...el,
        key: el.id,
        value: el.description,
      })),
    },
  });
}

export function setRestrictionsReducer(state, payload) {
  return Object.assign({}, state, {
    restrictions: {
      items: payload.map((el) => ({
        ...el,
        key: el.id,
        value: el.description,
      })),
    },
  });
}

export function updateOpportunitiesWithNotes(state, ids) {
  const results = state.results.map((item) => {
    if (ids.includes(item.id)) {
      return {
        ...item,
        hasNotes: true,
      };
    }
    return item;
  });
  return {
    ...state,
    results,
  };
}

export const setCurrentPage = (state, currentPage) => ({
  ...state,
  searchQuery: {
    ...state.searchQuery,
    currentPage,
  },
});

export const setSelectAllContext = (state, context) => ({
  ...state,
  selectAllContext: context,
});
