import { call, all, put, takeLatest, takeEvery, select } from 'redux-saga/lib/effects.js';
import download from 'downloadjs';

import { opportunitiesActions } from '../dispatchers/opportunities.js';
import { fixturesActions } from '../dispatchers/fixtures.js';
import { pageActions } from '../dispatchers/page.js';
import { apiPost } from '../../services/requests.ts';
import { getAggregations, getOpportunities } from '../../services/api/opportunities.ts';
import { SEARCH_CATEGORIES, LOCALE, MAX_SELECTED_ITEMS } from '@fi/constants';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { getSanitizedCountryItems } from '@fi/util/getSanitizedCountryItems';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { AppStore } from '../store.ts';
import { exportFile } from '../../services/api/exportToFile.js';
import {
  getAsjcClassifications,
  getFundingOrgTypes,
  getOpportunitiesMeta,
  getIndividualApplicantTypes,
  getOrganizationalApplicantTypes,
  getDegreeRequirements,
  getRestrictions,
} from '../../services/api/meta.ts';
import { createOpportunitySearchQueryObject } from '../../query/opportunitySearchQuery.ts';
import { getClassificationCounts } from '@fi/util/getInitialCountMap';
import { getFunders } from '../../services/api/funders.ts';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchMetadata(action) {
  const { ids } = action.payload ?? {};

  try {
    const [
      researchAreas,
      funderTypes,
      metadata,
      applicantTypes,
      organizationalApplicantTypes,
      degreeRequirements,
      restrictions,
      funders,
      subLevelDisciplines,
    ] = yield all([
      call(getAsjcClassifications),
      call(getFundingOrgTypes),
      call(getOpportunitiesMeta),
      call(getIndividualApplicantTypes),
      call(getOrganizationalApplicantTypes),
      call(getDegreeRequirements),
      call(getRestrictions),
      call(getAggregations, 'funder'),
      call(getAggregations, 'sub-level-discipline'),
    ]);
    yield put(opportunitiesActions.loadResearchAreas(researchAreas));
    yield put(
      opportunitiesActions.loadFundingOrganizationsCounts({
        funders,
      }),
    );
    if (ids?.length) {
      const response = yield getFunders({ id: ids, pageSize: 100 });
      yield put(opportunitiesActions.loadFundingOrganizationsSearchResponse(response));
    }
    yield put(opportunitiesActions.loadFunderType(funderTypes));
    const items = getSanitizedCountryItems(metadata.countryCodeMapping);
    const countries = {
      items,
    };
    const initialCountMap = getClassificationCounts(subLevelDisciplines);
    yield put(opportunitiesActions.setInitialCountMap(initialCountMap));
    yield put(fixturesActions.setCountries(countries));
    yield put(fixturesActions.setCountriesMap(metadata.countryCodeMapping));
    yield put(opportunitiesActions.loadFundingTypes(metadata.grantTypeDescriptions));
    yield put(opportunitiesActions.setIndividualApplicantTypes(applicantTypes));
    yield put(opportunitiesActions.setOrganizationalApplicantTypes(organizationalApplicantTypes));
    yield put(opportunitiesActions.setDegreeRequirements(degreeRequirements));
    yield put(opportunitiesActions.setRestrictions(restrictions));
    yield put(opportunitiesActions.setMetadataIsFetched());

    yield put(opportunitiesActions.orderResearchAreas());
  } catch (e) {
    yield put(opportunitiesActions.setIsLoadingResults(false));
  }
}

function* doExportFile(action) {
  try {
    yield put(opportunitiesActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    download(response, `${target}.${format}`);
    yield put(opportunitiesActions.exportFileFailed(false));
    yield put(opportunitiesActions.isFileExporting(false));
  } catch (e) {
    yield put(opportunitiesActions.exportFileFailed(true));
    yield put(opportunitiesActions.isFileExporting(false));
  }
}

function* doFetchOpportunities(action) {
  const { payload: searchQuery = {} } = action;

  try {
    yield put(pageActions.resetAlerts());
    yield put(opportunitiesActions.setIsLoadingResults(true));

    const opportunities = yield getOpportunities(searchQuery);
    // adobe analytics call
    yield put(opportunitiesActions.loadOpportunities(opportunities));

    yield put(opportunitiesActions.setIsLoadingResults(false));
    yield put(opportunitiesActions.setIsInvalidQuery(false));
  } catch (e) {
    yield put(opportunitiesActions.setIsLoadingResults(false));
    if (e.status === 400) {
      var options = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.SEARCH_ERROR + ' ' + e.data.errorDescription),
      };
      yield put(opportunitiesActions.setIsInvalidQuery(true));
      yield put(pageActions.createAlert(options));
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  }
}

function* doSelectAllSearchResult() {
  try {
    yield put(opportunitiesActions.setIsLoadingResults(true));
    yield put(opportunitiesActions.deselectAll());
    const searchQuery = yield select((state) => state.opportunities.searchQuery);
    const urlParams = getUrlParameters(window.location.search);
    const apiUrl = '/api/opportunities/ids';
    const state = AppStore.getState();
    const newSavedSearchOptions = {
      ...searchQuery.savedSearchOption,
      selected: urlParams.savedSearchOption || 'last',
    };
    const apiQueryOptions = {
      scope: SEARCH_CATEGORIES.OPPORTUNITIES,
      researchAreas: state.opportunities.researchAreas,
      savedSearchOptions: newSavedSearchOptions,
    };
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(opportunitiesActions.setSelectedItems(ids));
    yield put(opportunitiesActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(opportunitiesActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doRunSearch(action) {
  const { searchQuery, researchAreas, savedSearch } = action.payload;
  const apiQueryOptions = {
    scope: SEARCH_CATEGORIES.OPPORTUNITIES,
    researchAreas,
  };
  try {
    yield put(opportunitiesActions.updateSearch(searchQuery));
    if (savedSearch.newDataCount > 0) {
      apiQueryOptions.savedSearchOptions = {
        lastVisitedDate: savedSearch.lastVisitedDate,
      };
    }
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    yield put(opportunitiesActions.fetchOpportunities(queryObject));
    yield put(opportunitiesActions.fetchAggregations(queryObject));
    yield put(opportunitiesActions.setApiQuery(queryObject));
  } catch (e) {
    yield put(opportunitiesActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doFetchAggregations(action) {
  const searchQuery = action.payload;
  try {
    const [
      fundingTypes,
      topLevelDisciplines,
      subLevelDisciplines,
      individualApplicantTypes,
      organizationalApplicantTypes,
      degreeRequirements,
      restrictions,
      funderTypes,
    ] = yield all([
      call(getAggregations, 'grant-type', searchQuery),
      call(getAggregations, 'top-level-discipline', searchQuery),
      call(getAggregations, 'sub-level-discipline', searchQuery),
      call(getAggregations, 'individual-applicant-type', searchQuery),
      call(getAggregations, 'organizational-applicant-type', searchQuery),
      call(getAggregations, 'degree-requirement', searchQuery),
      call(getAggregations, 'restriction', searchQuery),
      call(getAggregations, 'funder-type', searchQuery),
    ]);
    yield put(
      opportunitiesActions.loadAggregations({
        fundingTypes,
        topLevelDisciplines: topLevelDisciplines.aggregations,
        subLevelDisciplines,
        individualApplicantTypes,
        organizationalApplicantTypes,
        degreeRequirements,
        restrictions,
        funderTypes,
      }),
    );
    yield put(opportunitiesActions.setIsInvalidQuery(false));
  } catch (e) {
    if (e.status === 400) {
      var options = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.SEARCH_ERROR + ' ' + e.data.errorDescription),
      };
      yield put(opportunitiesActions.setIsInvalidQuery(true));
      yield put(pageActions.createAlert(options));
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  }
}

function* doFetchSimilarOpportunities(action) {
  try {
    const { id } = action.payload;
    const searchQuery = yield select((state) => state.opportunities.searchQuery);
    const apiUrl = `/api/opportunities/${id}/suggestions`;
    const apiQueryOptions = {
      status: 'active',
      scope: SEARCH_CATEGORIES.OPPORTUNITIES,
    };
    const queryObject = createOpportunitySearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = 20;
    queryObject['page'] = 0;
    yield put(opportunitiesActions.setLoadingSimilarOpportunities(true));
    const similarOpportunities = yield call(apiPost, `${apiUrl}`, queryObject);
    yield put(opportunitiesActions.setSimilarOpportunities(similarOpportunities));
  } catch (e) {
    const alertOptions = getDefaultAlertOptions('homepage', LOCALE.ERRORS.ERROR_OCCURRED_REFRESH);
    yield put(pageActions.createAlert(alertOptions));
  } finally {
    yield put(opportunitiesActions.setLoadingSimilarOpportunities(false));
  }
}

export default function* opportunitiesSaga() {
  yield takeLatest(opportunitiesActions.fetchMetadata, doFetchMetadata);
  yield takeLatest(opportunitiesActions.fetchOpportunities, doFetchOpportunities);
  yield takeLatest(opportunitiesActions.fetchAggregations, doFetchAggregations);
  yield takeLatest(opportunitiesActions.fetchSimilarOpportunities, doFetchSimilarOpportunities);
  yield takeEvery(opportunitiesActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(opportunitiesActions.runSearch, doRunSearch);
  yield takeLatest(opportunitiesActions.exportFile, doExportFile);
}
