import React from 'react';

import { LinkProps, LinkDefaultProps } from './LinkInterfaces';

import LinkContent from './LinkContent';

import getLinkProps from './getLinkProps.ts';

const Link = (props: LinkProps) => {
  const { children, isExternal, showExternalLinkIcon, isSelected, icon, iconClassName, iconPosition, type } = props;

  const linkProps = getLinkProps(props);

  return (
    <a {...linkProps}>
      <LinkContent
        icon={icon}
        iconPosition={iconPosition}
        iconClassName={iconClassName}
        type={type}
        isExternal={isExternal}
        showExternalLinkIcon={showExternalLinkIcon}
        isSelected={isSelected}
      >
        {children}
      </LinkContent>
    </a>
  );
};

Link.defaultProps = LinkDefaultProps;

Link.LinkContent = LinkContent;

export default Link;
