import React from 'react';

type LinkType = 'primary' | 'secondary' | 'black';

export interface LinkProps {
  className?: string;
  href: string;
  title?: string;
  children?: React.ReactNode;
  isExternal?: boolean;
  isSelected?: boolean;
  showExternalLinkIcon?: boolean;
  icon?: React.ReactNode;
  iconClassName?: string;
  iconPosition?: 'left' | 'right';
  type: LinkType;
  size?: 'large' | 'small';
  onMouseEnter?: (event: React.SyntheticEvent) => void;
  onMouseLeave?: (event: React.SyntheticEvent) => void;
  testId?: string;
}

export const LinkDefaultProps = {
  iconPosition: 'left',
  showExternalLinkIcon: 'true',
  type: 'primary',
  title: '',
  isSelected: false,
};

export interface LinkContentProps {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  iconClassName?: string;
  type: LinkType;
  children?: React.ReactNode;
  isExternal?: boolean;
  showExternalLinkIcon?: boolean;
  isSelected?: boolean;
}
