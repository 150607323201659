import { call, put, all, takeLatest, takeEvery, select } from 'redux-saga/lib/effects.js';

import download from 'downloadjs';
import { AppStore } from '../store.ts';
import { awardsActions } from '../dispatchers/awards.js';
import { fixturesActions } from '../dispatchers/fixtures.js';
import { apiGet, apiPost } from '../../services/requests.ts';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { getSanitizedCountryItems } from '@fi/util/getSanitizedCountryItems';
import { pageActions } from '../dispatchers/page.js';
import { SEARCH_CATEGORIES, LOCALE, MAX_SELECTED_ITEMS } from '@fi/constants';
import { exportFile } from '../../services/api/exportToFile.js';
import { createAwardSearchQueryObject } from '../../query/awardSearchQuery.ts';
import { getClassificationCounts } from '@fi/util/getInitialCountMap';
import { getFunders } from '../../services/api/funders.ts';

const SEARCHALERTOPTIONS = {
  ...getDefaultAlertOptions('search', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchMetadata(action) {
  const { awardedAuthors, awardedInstitutions, ids } = action.payload;
  try {
    if (awardedInstitutions.length) {
      const institutionsQuery = awardedInstitutions.join('&id=');
      yield put(awardsActions.fetchAwardedInstitutionsMeta(`?id=${institutionsQuery}`));
    }
    if (awardedAuthors.length) {
      const authorsQuery = awardedAuthors.join('&id=');
      yield put(awardsActions.fetchAwardedAuthors(`?id=${authorsQuery}`));
    }
    const [researchAreas, funderTypes, grantTypes, metadata] = yield all([
      call(apiGet, '/api/classifications/asjc'),
      call(apiGet, '/api/descriptions/fundingOrgTypes'),
      call(apiGet, '/api/descriptions/award-grant-types'),
      call(apiGet, '/api/awards/.meta'),
    ]);

    const { years, subLevelDisciplines, funders } = metadata.globalAggregations;

    yield put(
      awardsActions.loadFundingOrganizationsCounts({
        funders,
      }),
    );
    if (ids?.length) {
      const response = yield getFunders({ id: ids, pageSize: 100 });
      yield put(awardsActions.loadFundingOrganizationsSearchResponse(response));
    }
    yield put(awardsActions.loadResearchAreas(researchAreas));
    yield put(awardsActions.loadFunderType(funderTypes));
    yield put(awardsActions.loadGrantTypes(grantTypes));
    yield put(awardsActions.loadStartYears(years));
    const initialCountMap = getClassificationCounts(subLevelDisciplines);
    yield put(awardsActions.setInitialCountMap(initialCountMap));

    const items = getSanitizedCountryItems(metadata.countryCodeMapping);
    const countries = {
      items,
    };
    yield put(fixturesActions.setCountries(countries));
    yield put(fixturesActions.setCountriesMap(metadata.countryCodeMapping));
    yield put(awardsActions.setIsLoadingResults(false));
    yield put(awardsActions.setMetadataIsFetched());
  } catch (e) {
    yield put(awardsActions.setIsLoadingResults(false));
  }
}

function* doExportFile(action) {
  try {
    yield put(awardsActions.isFileExporting(true));
    const { ids, target, format } = action.payload;
    const response = yield exportFile(ids, format, target);
    const name = target === 'awards' ? 'grants' : target;
    download(response, `${name}.${format}`);
    yield put(awardsActions.exportFileFailed(false));
    yield put(awardsActions.isFileExporting(false));
  } catch (e) {
    yield put(awardsActions.exportFileFailed(true));
    yield put(awardsActions.isFileExporting(false));
  }
}

function* doRunSearch(action) {
  const { searchQuery, researchAreas } = action.payload;
  const apiQueryOptions = {
    scope: SEARCH_CATEGORIES.AWARDS,
    researchAreas,
  };
  try {
    yield put(awardsActions.updateSearch(searchQuery));
    const queryObject = createAwardSearchQueryObject(searchQuery, apiQueryOptions);
    yield put(awardsActions.fetchAwardAggregations(queryObject, apiQueryOptions));
    yield put(awardsActions.fetchAwardResults(queryObject, apiQueryOptions));
  } catch (e) {
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doFetchAwardAggregations(action) {
  const searchQuery = action.payload;
  try {
    yield put(pageActions.resetAlerts());
    const [countries, grantTypes, topLevelDisciplines, subLevelDisciplines, years, funderTypes] = yield all([
      call(apiPost, '/api/awards/aggregations/recipient-country-search', searchQuery),
      call(apiPost, '/api/awards/aggregations/grant-type-search', searchQuery),
      call(apiPost, '/api/awards/aggregations/top-level-discipline-search', searchQuery),
      call(apiPost, '/api/awards/aggregations/sub-level-discipline-search', searchQuery),
      call(apiPost, '/api/awards/aggregations/per-year-search', searchQuery),
      call(apiPost, '/api/awards/aggregations/funder-type', searchQuery),
    ]);
    yield put(
      awardsActions.loadAwardAggregations({
        countries,
        grantTypes,
        topLevelDisciplines,
        subLevelDisciplines,
        years,
        funderTypes,
      }),
    );

    yield put(awardsActions.setMetadataIsFetched());
  } catch (e) {
    if (e.status === 400) {
      var options = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.SEARCH_ERROR + ' ' + e.data.errorDescription),
      };
      yield put(pageActions.createAlert(options, e.data.err));
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  }
}

function* doFetchAwardResults(action) {
  const searchQuery = action.payload;
  const apiUrl = '/api/awards';
  try {
    yield put(pageActions.resetAlerts());
    yield put(awardsActions.setIsLoadingResults(true));
    const awardHits = yield call(apiPost, apiUrl, searchQuery);
    yield put(awardsActions.loadAwardResults(awardHits));
  } catch (e) {
    if (e.status === 400) {
      var options = {
        ...getDefaultAlertOptions('search', LOCALE.ERRORS.SEARCH_ERROR + ' ' + e.data.errorDescription),
      };
      yield put(pageActions.createAlert(options));
    } else {
      yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
    }
  } finally {
    yield put(awardsActions.setIsLoadingResults(false));
  }
}

function* doFetchAwardedInstitutions(action) {
  try {
    yield put(awardsActions.setIsAutoCompleteRequested(true));
    yield put(awardsActions.setIsFetchingAwardedInstitutions(true));
    const awardedInstitutions = yield call(fetchAwardedInstitutions, action);
    yield put(awardsActions.setAwardedInstitutions(awardedInstitutions));
  } catch (e) {
    yield put(awardsActions.setIsAutoCompleteRequested(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    yield put(awardsActions.setIsFetchingAwardedInstitutions(false));
  }
}

function* doFetchAwardedInstitutionsMeta(action) {
  try {
    yield put(awardsActions.setIsFetchingAwardedInstitutionsMeta(true));
    const awardedInstitutions = yield call(fetchAwardedInstitutions, action);
    yield put(awardsActions.setAwardedInstitutions(awardedInstitutions));
  } catch (e) {
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  } finally {
    yield put(awardsActions.setIsFetchingAwardedInstitutionsMeta(false));
  }
}

async function fetchAwardedInstitutions(action) {
  const searchQuery = action.payload;
  let apiUrl = '/api/autocompletion/awardedInstitutions';
  if (typeof searchQuery === 'string') {
    apiUrl += searchQuery;
  }

  const response = await apiGet(apiUrl);

  return response.map((item) => ({
    key: item.id,
    value: item.text,
    count: item.count,
  }));
}

function* doClearAwardedInstitutions() {
  yield put(awardsActions.setIsAutoCompleteRequested(false));
  yield put(awardsActions.setAwardedInstitutions({}));
}

function* doFetchAwardedAuthors(action) {
  const searchQuery = action.payload;
  let apiUrl = '/api/autocompletion/awardedAuthors';
  if (typeof searchQuery === 'string') {
    apiUrl += searchQuery;
  }
  try {
    yield put(awardsActions.setIsFetchingAwardedAuthors(true));
    const response = yield call(apiGet, apiUrl);
    const awardedAuthors = response.map((item) => ({
      key: item.id,
      value: item.text,
    }));
    yield put(awardsActions.setAwardedAuthors(awardedAuthors));
    yield put(awardsActions.setIsFetchingAwardedAuthors(false));
  } catch (e) {
    yield put(awardsActions.setIsFetchingAwardedAuthors(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doFetchAwardDetails(action) {
  const awardId = action.payload;
  const apiUrl = '/api/awards';
  const queryObject = {
    id: awardId,
  };
  const awardTypesApiUrl = '/api/descriptions/award-grant-types';
  try {
    yield put(awardsActions.setIsLoadingDetails(true));

    const [award, researchAreas] = yield all([call(apiPost, apiUrl, queryObject), call(apiGet, '/api/classifications/asjc')]);

    if (award.hits && award.hits.hits && award.hits.hits[0] && award.hits.hits[0]._source) {
      const awardDetails = award.hits.hits[0]._source;
      const awardTypes = yield call(apiGet, awardTypesApiUrl);

      yield put(awardsActions.setAwardDetails(awardDetails));
      yield put(awardsActions.setAwardMap(awardTypes));
      yield put(awardsActions.loadResearchAreas(researchAreas));
    } else {
      yield put(pageActions.set404());
    }

    yield put(awardsActions.setIsLoadingDetails(false));
  } catch (e) {
    yield put(awardsActions.setIsLoadingDetails(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

function* doSelectAllSearchResult() {
  try {
    yield put(awardsActions.setIsLoadingResults(true));
    yield put(awardsActions.deselectAll());
    const searchQuery = yield select((state) => state.awards.searchQuery);
    const apiUrl = '/api/awards/ids';
    const state = AppStore.getState();
    const apiQueryOptions = {
      scope: SEARCH_CATEGORIES.AWARDS,
      researchAreas: state.awards.researchAreas,
    };
    const queryObject = createAwardSearchQueryObject(searchQuery, apiQueryOptions);
    queryObject['pageSize'] = MAX_SELECTED_ITEMS;
    queryObject['page'] = 0;
    const ids = yield call(apiPost, apiUrl, queryObject);
    yield put(awardsActions.setSelectedItems(ids));
    yield put(awardsActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(awardsActions.setIsLoadingResults(false));
    yield put(pageActions.createAlert(SEARCHALERTOPTIONS));
  }
}

export default function* awardsSaga() {
  yield takeLatest(awardsActions.fetchAwardAggregations, doFetchAwardAggregations);
  yield takeLatest(awardsActions.fetchAwardResults, doFetchAwardResults);
  yield takeEvery(awardsActions.fetchAwardDetails, doFetchAwardDetails);
  yield takeEvery(awardsActions.fetchMetadata, doFetchMetadata);
  yield takeEvery(awardsActions.fetchAwardedInstitutions, doFetchAwardedInstitutions);
  yield takeEvery(awardsActions.fetchAwardedInstitutionsMeta, doFetchAwardedInstitutionsMeta);
  yield takeEvery(awardsActions.clearAwardedInstitutions, doClearAwardedInstitutions);
  yield takeEvery(awardsActions.fetchAwardedAuthors, doFetchAwardedAuthors);
  yield takeEvery(awardsActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeLatest(awardsActions.runSearch, doRunSearch);
  yield takeLatest(awardsActions.exportFile, doExportFile);
}
