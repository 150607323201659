import React from 'react';
import classNames from 'classnames';

interface ToastContentProps {
  icon: React.ReactNode;
  type?: 'success' | 'info' | 'error';
  title?: string;
  msg: string;
  children?: React.ReactNode;
  testId?: string;
}

const ToastContent: React.FC<ToastContentProps> = ({ icon, type = 'success', title, msg, children, testId }: ToastContentProps) => {
  return (
    <div
      className={classNames('fca-snackbar__content', {
        'fca-snackbar__content--success': type === 'success',
        'fca-snackbar__content--error': type === 'error',
        'fca-snackbar__content--info': type === 'info',
      })}
      data-testid={testId}
    >
      <div className="fca-snackbar__notification-icon">
        <span className="fca-icon">{icon}</span>
      </div>
      <div className="fca-snackbar__content-body">
        {title && <h2>{title}</h2>}
        <p>{msg}</p>
        {children}
      </div>
    </div>
  );
};

export default ToastContent;
