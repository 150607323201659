export const alphabeticalSortingCompare = <T>(key: keyof T) => {
  return (a: T, b: T) => {
    const nameA = (a[key] as string).toUpperCase();
    const nameB = (b[key] as string).toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };
};
