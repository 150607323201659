import { createAction, createReducer } from 'redux-act';

export const funderNoteModalActions = {
  openFunderNoteModal: createAction('open funder note modal'),
  setFunderId: createAction('store selected funder id for modal'),
  setFetchingFunderNoteId: createAction('set data is fetching for funder note modal'),
  setIsSaving: createAction('set funder note isSaving'),
  setNoteWasSavedSuccessfully: createAction('set funder note was saved successfully', (payload) => payload),
  closeModal: createAction('clear funder note modal'),
  setOpportunitiesWithNotesAmount: createAction('set amount of opportunities with notes for funder'),
};

const initialState = {
  funderId: null,
  fetchingFunderNoteId: null,
  isSaving: false,
  noteWasSavedSuccessfully: false,
  opportunitiesWithNotesAmount: null,
};

const setFunderId = (state, funderId) => ({
  ...state,
  funderId,
});

const setFetchingFunderNoteId = (state, fetchingFunderNoteId) => ({
  ...state,
  fetchingFunderNoteId,
});

const setIsSaving = (state, isSaving) => ({
  ...state,
  isSaving,
});

const setOpportunitiesWithNotesAmount = (state, opportunitiesWithNotesAmount) => ({
  ...state,
  opportunitiesWithNotesAmount,
});

const setNoteWasSavedSuccessfully = (state, noteWasSavedSuccessfully) => ({
  ...state,
  noteWasSavedSuccessfully,
});

const closeModal = () => initialState;

export const funderNoteModalReducer = createReducer(
  {
    [funderNoteModalActions.setFunderId]: setFunderId,
    [funderNoteModalActions.setIsSaving]: setIsSaving,
    [funderNoteModalActions.setNoteWasSavedSuccessfully]: setNoteWasSavedSuccessfully,
    [funderNoteModalActions.closeModal]: closeModal,
    [funderNoteModalActions.setFetchingFunderNoteId]: setFetchingFunderNoteId,
    [funderNoteModalActions.setOpportunitiesWithNotesAmount]: setOpportunitiesWithNotesAmount,
  },
  initialState,
);
