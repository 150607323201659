import { createSearchQueryObject } from './apiSearchQuery.ts';
import {
  createAdvancedSearchQueryObject,
  createListQueryObject,
  createPageQueryObject,
  createPageSizeQueryObject,
  createSortQueryObject,
  createBooleanQueryObject,
} from './commonSearchQuery.ts';

export function createFunderTypesQueryObject(searchQuery: any) {
  if (!searchQuery.funderType) {
    return {};
  }

  const ids = searchQuery.funderType.selectedItems.map((item: any) => item.key);

  if (ids.length === 0) {
    return {};
  }

  return {
    funderTypeGroupId: ids,
  };
}

const createSearchQueryObjectFn = createSearchQueryObject(
  createPageSizeQueryObject,
  createPageQueryObject,
  createSortQueryObject,
  createAdvancedSearchQueryObject,
  createListQueryObject('funderCountries', 'countryCodes'),
  createFunderTypesQueryObject,
  createBooleanQueryObject('xfnCn', 'xfnCn'),
);

export function createFunderSearchQueryObject(searchQuery: any, options: any) {
  return createSearchQueryObjectFn(searchQuery, options);
}
