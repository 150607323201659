import React from 'react';
import classNames from 'classnames';
import { FontFamily, FontSize } from './font.ts';
import * as styles from './styles/typography.module.scss';

type TypographyElement = HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement;
type RenderAs = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'article' | 'span';
type FontFamilyType = FontFamily;
type size = FontSize;

interface TypograhyProps extends React.HTMLAttributes<TypographyElement> {
  renderAs: RenderAs;
  children?: React.ReactNode | React.ReactNodeArray;
  family?: FontFamilyType;
  size?: size;
  isDefaultColor?: boolean;
  isGray?: boolean;
  lang?: string;
}

/**
 * Example usage:
 * ```tsx
 * import { Body, FontSize, FontFamily } from '@fi/typography';
 *  default
 *  <Typography renderAs="h1"> heading....... </Typography>
 *  Define font family
 *  <Typography renderAs="h1" family={FontFamily.NEXUS_SANS_REGULAR}> heading....... </Typography>
 *  Define font size
 *  <Typography renderAs="h1" family={FontFamily.NEXUS_SANS_REGULAR} size={FontSize.H1_MD}> heading....... </Typography>
 * ```
 */
const Typography = ({
  children,
  renderAs,
  family = FontFamily.NEXUS_SANS_REGULAR,
  size = FontSize.DEFAULT,
  isDefaultColor = true,
  isGray = false,
  lang = 'en',
  ...props
}: TypograhyProps): React.ReactElement => {
  const Component = renderAs;
  const css = [styles[family], styles[size], props.className].join(' ');
  return (
    <Component
      {...props}
      lang={lang}
      className={classNames(css, {
        [styles['secondary-color']]: isDefaultColor,
        [styles['gray']]: isGray,
      })}
    >
      {children}
    </Component>
  );
};

export default React.memo(Typography);
