import { fork, all } from 'redux-saga/lib/effects.js';

import authSagas from './sagas/auth.js';
import userSagas from './sagas/user.js';
import fixturesSagas from './sagas/fixtures.js';
import opportunitiesSagas from './sagas/opportunities.js';
import awardsSagas from './sagas/awards.js';
import fundersSagas from './sagas/funders.js';
import savedSearchesSagas from './sagas/savedSearches.jsx';
import trackOpportunitySagas from './sagas/trackOpportunity.js';
import logSaga from './sagas/logs/log.js';
import opportunitiesAlertSagas from './sagas/opportunitiesAlert.js';
import shareSaga from './sagas/share.js';
import groupsSagas from './sagas/groups.js';
import labelsSagas from './sagas/labels.js';
import publicGroup from './sagas/publicGroup.js';
import trackModalSagas from './sagas/trackModal.js';
import recommendationsSagas from './sagas/recommendations.js';
import recommendedOpportunitiesSagas from './sagas/recommendedOpportunities.js';
import recommendationModalSagas from './sagas/recommendationModal.js';
import usersRolesSagas from './sagas/usersRoles.js';
import opportunityNotesSagas from './sagas/opportunityNotes.js';
import funderNotesSagas from './sagas/funderNotes.js';
import internalOpportunitiesSagas from './sagas/internalOpportunities.js';

export default function* sagas() {
  yield all([
    fork(authSagas),
    fork(userSagas),
    fork(fixturesSagas),
    fork(opportunitiesSagas),
    fork(awardsSagas),
    fork(fundersSagas),
    fork(savedSearchesSagas),
    fork(trackOpportunitySagas),
    fork(logSaga),
    fork(opportunitiesAlertSagas),
    fork(shareSaga),
    fork(groupsSagas),
    fork(labelsSagas),
    fork(publicGroup),
    fork(trackModalSagas),
    fork(recommendationsSagas),
    fork(recommendationModalSagas),
    fork(recommendedOpportunitiesSagas),
    fork(usersRolesSagas),
    fork(opportunityNotesSagas),
    fork(funderNotesSagas),
    fork(internalOpportunitiesSagas),
  ]);
}
