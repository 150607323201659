import { call, put, select, takeEvery, takeLatest } from 'redux-saga/lib/effects.js';

import { internalOpportunitiesActions } from '../dispatchers/internalOpportunities.js';
import { QueryManager } from '@fi/util/QueryManager';
import { replaceUrlParam } from '@fi/util/replaceUrlParam';
import { apiDelete, apiGet, apiPost } from '../../services/requests.ts';
import { getInternalOpportunities } from '../../services/api/internal-opportunities.ts';
import { displayGeneralErrorAlert } from './alerts.js';
import { showNotification } from '@fi/components/Notify';
import { LOCALE, MAX_SELECTED_ITEMS, OPPORTUNITY_ACTIVE, OPPORTUNITY_INACTIVE, MODALS } from '@fi/constants';
import { modalActions } from '../dispatchers/modal.js';

function* fetchInternalOpportunitiesPage() {
  const searchQuery = yield select((state) => state.internalOpportunities.searchQuery);

  const query = QueryManager.generateApiQuery(searchQuery, { status: null });

  const opportunities = yield getInternalOpportunities(query);

  yield put(internalOpportunitiesActions.loadOpportunities(opportunities));
}

function* doFetchInternalOpportunities() {
  try {
    yield put(internalOpportunitiesActions.setIsLoadingResults(true));

    yield call(fetchInternalOpportunitiesPage);
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'internal-opportunities');
  } finally {
    yield put(internalOpportunitiesActions.setIsLoadingResults(false));
  }
}

function* doSelectAllSearchResult() {
  try {
    yield put(internalOpportunitiesActions.setIsLoadingResults(true));
    yield put(internalOpportunitiesActions.deselectAll());

    const apiUrl = '/api/account/internal-opportunities/ids';
    const searchQuery = yield select((state) => state.internalOpportunities.searchQuery);
    let queryString = QueryManager.generateApiQuery(searchQuery, {
      status: null,
    });
    queryString = replaceUrlParam(queryString, 'pageSize', MAX_SELECTED_ITEMS);
    queryString = replaceUrlParam(queryString, 'page', 0);

    const { idList } = yield call(apiGet, `${apiUrl}${queryString}`);

    yield put(internalOpportunitiesActions.setSelectedItems(idList));

    yield put(internalOpportunitiesActions.setIsLoadingResults(false));
  } catch (e) {
    yield put(internalOpportunitiesActions.setIsLoadingResults(false));
    yield call(displayGeneralErrorAlert, 'internal-opportunities');
  }
}

function* publishOpportunity(opportunityId) {
  yield call(apiPost, `/api/account/internal-opportunities/${opportunityId}/_publish`);
}

function isPublished(extensions) {
  return extensions && extensions.published;
}

function* doUpdateStatus(action) {
  const {
    payload: { id: opportunityId, status },
  } = action;

  try {
    yield put(internalOpportunitiesActions.addPendingStatusRequest(opportunityId));

    if (![OPPORTUNITY_ACTIVE, OPPORTUNITY_INACTIVE].includes(status)) {
      throw new Error('Wrong opportunity status');
    }

    const opportunity = yield select((state) => state.internalOpportunities.results.find((opp) => opp.id === opportunityId));

    if (!opportunity) {
      throw new Error('Opportunity was not found');
    }

    if (!isPublished(opportunity.extensions)) {
      yield call(publishOpportunity, opportunityId);
    }

    yield call(apiPost, `/api/account/internal-opportunities/${opportunityId}/_updateStatus?status=${status}`);

    let message;
    if (status === OPPORTUNITY_ACTIVE) {
      message = 'An internal opportunity has been published and made active.';
    } else if (status === OPPORTUNITY_INACTIVE) {
      message = 'An internal opportunity has been published and made inactive.';
    }

    yield call(showNotification, { message: message });

    yield put(
      internalOpportunitiesActions.updateActivationStatus({
        opportunityId,
        status,
      }),
    );
    yield put(internalOpportunitiesActions.updatePublishedStatus(opportunityId));
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'internal-opportunities');
  } finally {
    yield put(internalOpportunitiesActions.removePendingStatusRequest(opportunityId));
  }
}

function* doDeleteOpportunity(action) {
  const {
    payload: { opportunityId },
  } = action;

  try {
    yield put(internalOpportunitiesActions.setIsDeletingConfirmationModal(true));
    yield call(apiDelete, `/api/account/internal-opportunities/${opportunityId}`);
    yield put(internalOpportunitiesActions.removeOpportunityFromStore(opportunityId));
    yield call(showNotification, { message: 'An internal opportunity was deleted.' });
    yield put(internalOpportunitiesActions.opportunityHasBeenDeleted(true));
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.DELETE_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield call(displayGeneralErrorAlert, 'internal-opportunities');
    }
  } finally {
    yield put(internalOpportunitiesActions.setIsDeletingConfirmationModal(false));
  }
}

export default function* internalOpportunitiesSagas() {
  yield takeLatest(internalOpportunitiesActions.fetchOpportunities, doFetchInternalOpportunities);

  yield takeEvery(internalOpportunitiesActions.selectAllSearchResult, doSelectAllSearchResult);
  yield takeEvery(internalOpportunitiesActions.updateStatus, doUpdateStatus);
  yield takeEvery(internalOpportunitiesActions.deleteOpportunity, doDeleteOpportunity);
}
