import { call, put } from 'redux-saga/lib/effects.js';

import { pageActions } from '../dispatchers/page.js';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { LOCALE } from '@fi/constants';

export function* displayErrorAlert(context, message = LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER) {
  const alertOptions = {
    ...getDefaultAlertOptions(context, message),
    timeout: 4000,
  };
  yield put(pageActions.createAlert(alertOptions));
}

export function* displayGeneralErrorAlert(context) {
  yield call(displayErrorAlert, context);
}

export function* displayInfoAlert(context, content, type = 'info', timeout = 4000) {
  if (!context || !content) {
    return;
  }

  const alertOptions = {
    type,
    context,
    content,
    timeout,
  };

  yield put(pageActions.createAlert(alertOptions));
}
