import * as styles from './LoadingFallback.module.scss';

/** Full screen loading indicator for use in `<Suspense />` */
function LoadingFallback() {
  return (
    <div data-testid="LoadingFallback" className={styles.layout}>
      Loading...
    </div>
  );
}

export default LoadingFallback;
