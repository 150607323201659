import { API_ROUTES } from '@fi/constants';
import { apiGet, apiPost } from '../requests.ts';

function getOpportunities(searchQuery: any = {}): Promise<any> {
  return apiPost(API_ROUTES.opportunities_search, searchQuery);
}

function getOpportunityNote(opportunityId: string): Promise<any> {
  return apiGet(`/api/opportunities/${opportunityId}/note`);
}

function getOpportunityNotes(searchQuery = ''): Promise<any> {
  return apiGet(`/api/account/opportunity-notes${searchQuery}`);
}

function getAggregations(type: string, searchQuery: any = {}): Promise<any> {
  return apiPost(`/api/opportunities/aggregations/${type}`, { ...searchQuery, status: 'active' });
}

export { getOpportunities, getOpportunityNote, getOpportunityNotes, getAggregations };
