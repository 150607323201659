import { apiPost } from '../requests.ts';

export function exportFile(ids, format, target = 'opportunities') {
  return apiPost(`/api/${target}/_export?format=${format}`, ids, {
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json; charset=UTF-8',
    },
    responseType: 'blob',
    timeout: 0,
  });
}
