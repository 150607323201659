import { takeEvery, put, call } from 'redux-saga/lib/effects.js';

import { opportunitiesAlertActions } from '../dispatchers/opportunitiesAlert.js';
import { pageActions } from '../dispatchers/page.js';
import { apiDelete } from '../../services/requests.ts';

import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';

function* unsubscribeFromAlert(action) {
  const { type, user, opportunityId } = action.payload;
  const apiUrl = `/api/user/alerts/${type}?userId=${user}&opportunityId=${opportunityId}`;
  try {
    yield put(opportunitiesAlertActions.setLoading(true));
    yield call(apiDelete, apiUrl);
    yield put(opportunitiesAlertActions.unsubscribeFromAlertSuccess(true));
  } catch (e) {
    if (e.status && e.status === 500) {
      const message = 'An unknown error occurred. Please refresh the page to try again.';
      const alertOptions = getDefaultAlertOptions('unsubscribe', message);
      yield put(pageActions.createAlert(alertOptions));
    } else {
      yield put(opportunitiesAlertActions.unsubscribeFromAlertSuccess(true));
    }
  } finally {
    yield put(opportunitiesAlertActions.setLoading(false));
    yield put(opportunitiesAlertActions.unsubscribeFromAlertSuccess(false));
  }
}

export default function* opportunitiesAlert() {
  yield takeEvery(opportunitiesAlertActions.unsubscribeFromAlert, unsubscribeFromAlert);
}
