export enum FontFamily {
  NEXUS_SANS_REGULAR = 'nexus-sans-regular',
  NEXUS_SANS_BOLD = 'nexus-sans-bold',
  NEXUS_SANS_ITALIC = 'nexus-sans-italic',
  NEXUS_SANS_BOLD_ITALIC = 'nexus-sans-bolditalic',
}

export enum FontSize {
  XXL = 'xxl',
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XSM = 'xsm',
  DEFAULT = 'normal',
  BASE_SIZE = 'base',
}
