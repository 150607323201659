import { apiGet } from '../requests.ts';

function getResearchersList() {
  return apiGet('/api/user/recommendation-entities/');
}

function getRecommendationsForResearcher(researcherId: string) {
  return apiGet(`/api/user/recommendation-entities/${researcherId}/recommendations`);
}

export { getResearchersList, getRecommendationsForResearcher };
