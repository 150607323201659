import { createAction, createReducer } from 'redux-act';

import {
  setIsLoadingResults,
  selectItem,
  deselectItems,
  selectAllPage,
  setSelectedItems,
  deselectAll,
  pushSearchUrlToHistory,
  isFileExporting,
  exportFileFailed,
  updateOpportunitiesWithNotes,
  setCurrentPage,
  setSelectAllContext,
} from './common.js';
import { opportunityNoteModalActions } from './opportunityNoteModal.js';
import { selectAllActions } from './selectAll.js';
import { RELEVANCE_SORTING } from '@fi/constants';

export const recommendedOpportunitiesActions = {
  fetchOpportunities: createAction('fetch tracked opportunities'),
  untrackOpportunity: createAction('untrack opportunity'),
  untrackOpportunities: createAction('untrack opportunities'),

  setIsLoadingResults: createAction('set loading results'),
  setCurrentPage: createAction('set current page'),
  setSelectedSortOption: createAction('set sort option'),
  resetSearchQuery: createAction('reset SearchQuery'),

  loadOpportunities: createAction('Load recommended opportunities'),

  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  setSelectAllContext: createAction('Set select all context'),

  pushSearchUrlToHistory: createAction('push search url to history'),

  setTrackedOpp: createAction('set tracked opportunities'),

  exportFile: createAction('exporting file'),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),

  setPageInfo: createAction('set title and subject areas'),
  setOpportunityIsTracked: createAction('set recommended opportunity isTracked'),
  setOpportunityIsUnTracked: createAction('set recommended opportunity isUnTracked'),

  removeRecommendedOpportunity: createAction('remove recommended opportunity'),
  reset: createAction('reset to initial state'),
};

const SEARCH_QUERY_DEFAULT = {
  currentPage: 1,
  sortOptions: null,
  selectedSortOption: RELEVANCE_SORTING,
};

const initialState = {
  searchHistory: '',
  isLoadingResults: false,
  results: [],
  totalResults: 0,
  selectedItems: {},
  selectAllContext: 'page',
  searchQuery: SEARCH_QUERY_DEFAULT,
  trackedList: [],
  currentPage: 0,
  trackStatus: null,
  exportFailed: null,
  page: {
    title: '',
    subjects: '',
    authorNotFound: false,
  },
};

const reset = () => initialState;

const loadOpportunitiesReducer = (state, payload) => {
  if (payload) {
    const results = payload.map((item) => {
      return {
        ...item._source,
        score: item._score,
      };
    });
    return Object.assign({}, state, {
      totalResults: results.length,
      results,
    });
  }
  return Object.assign({}, state, {
    totalResults: 0,
    results: [],
  });
};

const setSelectedSortOption = (state, selectedSortOption) => ({
  ...state,
  searchQuery: {
    ...state.searchQuery,
    selectedSortOption,
  },
});

const resetSearchQuery = (state) => ({
  ...state,
  searchQuery: SEARCH_QUERY_DEFAULT,
});

const setTrackStatus = (state, trackStatus) => ({
  ...state,
  trackStatus,
});

const setTrackedOpp = (state, { trackedOpportunities, totalResults }) => ({
  ...state,
  trackedList: trackedOpportunities,
  totalResults,
});

const setPageInfo = (state, page) => ({
  ...state,
  page,
});

const setIsTrackedOnOppsList = (oppsList, ids, value) => {
  if (oppsList) {
    return oppsList.map((item) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          isTracked: value,
        };
      }
      return item;
    });
  }

  return [];
};

const updateOpportunityIsTracked = (state, ids, value) => {
  const updateRecommendedOpportunityItems = setIsTrackedOnOppsList(state.results, ids, value);

  return {
    ...state,
    results: updateRecommendedOpportunityItems,
  };
};

const setOpportunityIsTracked = (state, ids) => {
  return updateOpportunityIsTracked(state, ids, true);
};

const setOpportunityIsUnTracked = (state, ids) => {
  return updateOpportunityIsTracked(state, ids, false);
};

/* Reducers */
export const recommendedOpportunitiesReducer = createReducer(
  {
    [recommendedOpportunitiesActions.loadOpportunities]: loadOpportunitiesReducer,
    [recommendedOpportunitiesActions.setIsLoadingResults]: setIsLoadingResults,
    [recommendedOpportunitiesActions.setCurrentPage]: setCurrentPage,
    [recommendedOpportunitiesActions.setSelectAllContext]: setSelectAllContext,
    [recommendedOpportunitiesActions.setSelectedSortOption]: setSelectedSortOption,
    [recommendedOpportunitiesActions.resetSearchQuery]: resetSearchQuery,

    [recommendedOpportunitiesActions.selectItem]: selectItem,
    [recommendedOpportunitiesActions.deselectItems]: deselectItems,
    [recommendedOpportunitiesActions.deselectAll]: deselectAll,
    [recommendedOpportunitiesActions.selectAllPage]: selectAllPage,
    [recommendedOpportunitiesActions.setSelectedItems]: setSelectedItems,

    [recommendedOpportunitiesActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,

    [recommendedOpportunitiesActions.setTrackedOpp]: setTrackedOpp,
    [recommendedOpportunitiesActions.setTrackStatus]: setTrackStatus,
    [recommendedOpportunitiesActions.isFileExporting]: isFileExporting,
    [recommendedOpportunitiesActions.exportFileFailed]: exportFileFailed,

    [recommendedOpportunitiesActions.setPageInfo]: setPageInfo,
    [recommendedOpportunitiesActions.reset]: reset,

    [opportunityNoteModalActions.updateOpportunitiesWithNotes]: updateOpportunitiesWithNotes,
    [selectAllActions.deselectAllOpportunities]: deselectAll,
    [recommendedOpportunitiesActions.setOpportunityIsTracked]: setOpportunityIsTracked,
    [recommendedOpportunitiesActions.setOpportunityIsUnTracked]: setOpportunityIsUnTracked,
  },
  initialState,
);
