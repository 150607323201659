import { Route, Redirect, Switch } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import LoadingFallback from './refreshed/components/LoadingFallback/LoadingFallback';
import { ROUTES } from './constants.ts';

// lazy load components
const OpportunityDetailPage = lazy(() => import(/* webpackChunkName: "OpportunityDetailPage"*/ './opportunities/details'));
const UnsubscribeSavedSearch = lazy(
  () =>
    import(/* webpackChunkName: "UnsubscribeSavedSearch"*/ './connectors/Pages/Unsubscribe/UnsubscribeSavedSearch/UnsubscribeSavedSearch'),
);
const SuccessfullyUnsubscribeSavedSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "SuccessfullyUnsubscribeSavedSearch"*/ './connectors/Pages/Unsubscribe/UnsubscribeSavedSearch/SuccessfullyUnsubscribeSavedSearch'
    ),
);
const UnsubscribeAlertsPage = lazy(
  () => import(/* webpackChunkName: "UnsubscribeAlertsPage"*/ './connectors/Pages/Unsubscribe/UnsubscribeAlerts/UnsubscribeAlertsPage'),
);
const SuccessfullyUnsubscribeAlert = lazy(
  () =>
    import(
      /* webpackChunkName: "SuccessfullyUnsubscribeAlert"*/ './connectors/Pages/Unsubscribe/UnsubscribeAlerts/SuccessfullyUnsubscribeAlert'
    ),
);
const RemoteLogin = lazy(() => import(/* webpackChunkName: "RemoteLogin"*/ './connectors/Pages/Register/RemoteLogin'));
const PublicGroup = lazy(() => import(/* webpackChunkName: "PublicGroup"*/ './connectors/Pages/PublicGroup/PublicGroup'));
const AdminInvitation = lazy(
  () => import(/* webpackChunkName: "AdminInvitation"*/ './connectors/Pages/Register/AdminAccountActivation/AdminInvitation'),
);
const NoPromptSimulationPage = lazy(
  () => import(/* webpackChunkName: "NoPromptSimulationPage"*/ './connectors/Pages/Testing/NoPromptSimulationPage'),
);
const LoginPromptSimulationPage = lazy(
  () => import(/* webpackChunkName: "LoginPromptSimulationPage"*/ './connectors/Pages/Testing/LoginPromptSimulationPage'),
);
const UserIdSimulationPage = lazy(
  () => import(/* webpackChunkName: "UserIdSimulationPage"*/ './connectors/Pages/Testing/UserIdSimulationPage'),
);
const Editors = lazy(() => import(/* webpackChunkName: "Editors"*/ './connectors/Pages/Editors/Editors'));
const AcceptAdminInvitation = lazy(
  () => import('./connectors/Pages/Register/AdminAccountActivation/AcceptAdminInvitation/AcceptAdminInvitation'),
);
const MyFunding = lazy(() => import(/* webpackChunkName: "MyFunding"*/ './connectors/Pages/MyFunding/MyFunding'));
const FunderSearchPage = lazy(() => import(/* webpackChunkName: "SearchFunders"*/ './connectors/Pages/Search/Funders/FunderSearchPage'));
const AwardSearchPage = lazy(() => import(/* webpackChunkName: "SearchAwards"*/ './connectors/Pages/Search/Awards/AwardSearchPage'));
const OpportunitiesSearchPage = lazy(
  () => import(/* webpackChunkName: "SearchOpportunities"*/ './opportunities/search/OpportunitiesSearchPage'),
);
const Account = lazy(() => import(/* webpackChunkName: "Account"*/ './connectors/Pages/Profile/Account/Account'));
const Roles = lazy(() => import(/* webpackChunkName: "Roles"*/ './connectors/Pages/Profile/Roles/Roles'));
const AwardDetail = lazy(() => import(/* webpackChunkName: "AwardDetail"*/ './connectors/Pages/Details/Awards/AwardDetail'));

const Suggestions = lazy(() => import(/* webpackChunkName: "Suggestions"*/ './connectors/Pages/Suggestions/Suggestions'));
const FunderDetailPage = lazy(() => import(/* webpackChunkName: "FunderDetailPage"*/ './connectors/Pages/Details/Funder/FunderDetailPage'));
const Acknowledgements = lazy(
  () => import(/* webpackChunkName: "Acknowledgements"*/ './connectors/Pages/Acknowledgements/Acknowledgements'),
);
const Page404 = lazy(() => import(/* webpackChunkName: "Page404"*/ './connectors/Pages/Errors/Page404'));
const DEPRECATED_Root = lazy(() => import(/* webpackChunkName: "DEPRECATED_Root"*/ './connectors/DEPRECATED_Root'));
const AuthenticatedRoutesWrapper = lazy(
  () => import(/* webpackChunkName: "AuthenticatedRoutesWrapper"*/ './connectors/AuthenticatedRoutesWrapper'),
);
const PrivateRoute = lazy(() => import(/* webpackChunkName: "PrivateRoute"*/ './connectors/PrivateRoute'));

/** Graphene components below */
const Layout = lazy(() => import(/* webpackChunkName: "Layout"*/ './refreshed/components/Layout/Layout'));
const Root = lazy(
  () =>
    import(
      /* webpackChunkName: "Root"*/
      './refreshed/pages/Root/Root'
    ),
);

// Graphene routes - to be moved
const PRIVATE_GRAPHENE_ROUTES: string[] | [] = [];
const PUBLIC_GRAPHENE_ROUTES = ['/'];

const Routes = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        {/* REFRESHED GRAPHENE_ROUTES (2023+)*/}
        <Route exact path={[...PRIVATE_GRAPHENE_ROUTES, ...PUBLIC_GRAPHENE_ROUTES]}>
          <Layout>
            <Suspense fallback={<LoadingFallback />}>
              <PrivateRoute exact path={PRIVATE_GRAPHENE_ROUTES}>
                {/* Currently, there are no private graphene routes except the New Homepage, served via `/` */}
              </PrivateRoute>
              {/* The Root component handles authentication itself, because the `/` route can serve 2 different components */}
              <Route exact path={'/'}>
                <Root />
              </Route>
            </Suspense>
          </Layout>
        </Route>

        {/* ALL OTHER ROUTES (pre 2023) */}
        <DEPRECATED_Root>
          <Switch>
            <Redirect exact from={ROUTES.FUNDER_DETAILS_PAGE} to={ROUTES.FUNDER_DETAILS_PAGE_TAB_DETAILS} />
            <Redirect exact from={ROUTES.OPPORTUNITY_DETAILS} to={ROUTES.OPPORTUNITY_DETAILS_PAGE_TAB_DETAILS} />
            <Route exact path={ROUTES.OPPORTUNITY_DETAILS_PAGE_TAB} component={OpportunityDetailPage} />
            <Route exact path={ROUTES.UNSUBSCRIBE_SAVED_SEARCH} component={UnsubscribeSavedSearch} />
            <Route exact path={ROUTES.SUCCESSFULLY_UNSUBSCRIBED_SAVED_SEARCH} component={SuccessfullyUnsubscribeSavedSearch} />
            <Route exact path={ROUTES.UNSUBSCRIBE_ALERTS} component={UnsubscribeAlertsPage} />
            <Route exact path={ROUTES.SUCCESSFULLY_UNSUBSCRIBE_ALERTS} component={SuccessfullyUnsubscribeAlert} />

            {/** currently the Java server redirects these 4 routes to the login status */}
            <Route exact path={ROUTES.REMOTE_LOGIN} component={RemoteLogin} />
            <Route exact path={ROUTES.PUBLIC_GROUP} component={PublicGroup} />
            <Route exact path={ROUTES.PUBLIC_ADMIN_INVITATION} component={AdminInvitation} />

            {/* Mock ID+ pages */}
            <Route exact path={ROUTES.NO_PROMPT_SIMULATION} component={NoPromptSimulationPage} />
            <Route exact path={ROUTES.LOGIN_PROMPT_SIMULATION} component={LoginPromptSimulationPage} />
            <Route exact path={ROUTES.USER_ID_SIMULATION} component={UserIdSimulationPage} />

            <AuthenticatedRoutesWrapper>
              <Switch>
                {/* Editors routes */}
                <Route path={ROUTES.EDITORS_PAGE} component={Editors} />
                <Redirect from={ROUTES.EDITORS} exact to={ROUTES.EDITOR_OPPORTUNITY_NOTES} />

                {/* 1st admin activation */}
                <Route exact path={ROUTES.ACCEPT_ADMIN_INVITATION} component={AcceptAdminInvitation} />
                {/* My Funding routes */}
                <Route path={ROUTES.MYFUNDING_PAGE} component={MyFunding} />
                <Redirect from={ROUTES.MYFUNDING} exact to={ROUTES.MYFUNDING_SAVED_SEARCHES} />

                {/* Search routes redirects */}
                <Redirect from={ROUTES.OPPORTUNITIES} exact to={ROUTES.SEARCH_OPPORTUNITIES} />
                <Redirect from={ROUTES.FUNDERS} exact to={ROUTES.SEARCH_FUNDERS} />
                <Redirect from={ROUTES.AWARDS} exact to={ROUTES.SEARCH_AWARDS} />
                <Redirect from={ROUTES.SEARCH} exact to={ROUTES.SEARCH_OPPORTUNITIES} />

                {/* Search routes */}
                <Route exact path={ROUTES.SEARCH_FUNDERS} component={FunderSearchPage} />
                <Route exact path={ROUTES.SEARCH_AWARDS} component={AwardSearchPage} />
                <Route exact path={ROUTES.SEARCH_OPPORTUNITIES} component={OpportunitiesSearchPage} />

                {/* Analytics routes */}
                <Route exact path={ROUTES.AWARDS_ANALYTICS} component={AwardSearchPage} />

                {/* User profile routes */}
                <Route exact path={ROUTES.PROFILE_ACCOUNT} component={Account} />
                <Route exact path={ROUTES.PROFILE_ROLES} component={Roles} />
                <Redirect from={ROUTES.PROFILE} exact to={ROUTES.PROFILE_ACCOUNT} />

                {/* Detail pages for awards, funders & opportunities */}
                <Route exact path={ROUTES.AWARD_DETAILS} component={AwardDetail} />

                <Route exact path={ROUTES.SUGGESTIONS} component={Suggestions} />
                <Route exact path={ROUTES.SUGGESTIONS_DETAILS_PAGE} component={Suggestions} />

                <Route exact path={ROUTES.FUNDER_DETAILS_PAGE_TAB} component={FunderDetailPage} />
                <Route exact path={ROUTES.ACKNOWLEDGEMENTS} component={Acknowledgements} />

                {/* If no matches display a 404 page */}
                <Route path="*" component={Page404} />
              </Switch>
            </AuthenticatedRoutesWrapper>
            {/* If no matches display a 404 page */}
          </Switch>
        </DEPRECATED_Root>
        <Route path="*" component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
