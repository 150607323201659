// extracted by mini-css-extract-plugin
var _1 = "yWNrYH3_TdTpCawLGJ0X";
var _2 = "AXpn7GgB8nDObOqYFCn_";
var _3 = "fYsc2Br9t3gKSR8mfaz5";
var _4 = "JrtTXyWw67x9n_g8o1Vb";
var _5 = "W4N1HjGpGkphBbKjBnxu";
var _6 = "CQn0M4mQ1fpvLkZe1pJb";
var _7 = "TKW5qhL5eFWE4y7scy1g";
var _8 = "E5N4M6LyDH3HMh1DT0If";
var _9 = "pk8SyzG3QNecoeMWAWs3";
var _a = "t2t2du18wSHy6twVb5Ik";
var _b = "SQd1p2efJoq1EdMutCyC";
var _c = "N5usIs_xWmvOa3gb7NW_";
var _d = "pSegcqlVXGmz51bZmodp";
var _e = "Rj2qBJTZWywkruy5ZZqE";
export { _1 as "base", _2 as "gray", _3 as "lg", _4 as "md", _5 as "nexus-sans-bold", _6 as "nexus-sans-bolditalic", _7 as "nexus-sans-italic", _8 as "nexus-sans-regular", _9 as "normal", _a as "secondary-color", _b as "sm", _c as "xl", _d as "xsm", _e as "xxl" }
