import { createAction, createReducer } from 'redux-act';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const savedSearchesActions = {
  fetchSavedSearches: createAction('fetch saved searches'),
  unsubscribeFromAlert: createAction('unsubscribe from alert'),
  setIsUnsubscribingFromAlert: createAction('set is unsubscribing'),
  setIsUnsubscribedFromAlert: createAction('set is unsubscribed'),
  loadResultsData: createAction('load saved searches'),
  updateResultsData: createAction('update saved searches'),
  setIsLoadingResults: createAction('set is loading results'),
  setDeleteModalOpen: createAction('set delete modal open'),
  setEditModalOpen: createAction('set edit modal open'),
  setSaveModalOpen: createAction('set save modal open'),
  setShareModalOpen: createAction('set share modal open'),
  setOverwriteConfirmModalOpen: createAction('set delete modal open'),
  setCurrentPage: createAction('set current page'),
  saveSearch: createAction('save search'),
  updateSearch: createAction('update search'),
  deleteSavedSearch: createAction('delete saved search'),
  removeItemFromResults: createAction('remove item from results'),
  fetchSavedSearchesResult: createAction('fetch saved search result'),
  setActiveSavedSearch: createAction('set saved search results'),
  clearActiveSavedSearch: createAction('clear all the saved search results'),
  fetchSavedSearch: createAction('fetch specific saved search'),
  setIsSaving: createAction('set saving flag'),
  setSaveStatus: createAction('set save operation status'),
  setDeleteStatus: createAction('set delete operation status'),
};

const setActiveSavedSearch = (state, savedSearchResult) => ({
  ...state,
  savedSearchResult,
});
const clearActiveSavedSearch = (state) => ({
  ...state,
  savedSearchResult: {},
});
const setIsLoadingResults = (state, isLoadingResults) => ({
  ...state,
  isLoadingResults,
});
const setDeleteModalOpen = (state, isDeleteModalOpen) => ({
  ...state,
  isDeleteModalOpen,
});
const setEditModalOpen = (state, isEditModalOpen) => ({
  ...state,
  isEditModalOpen,
});
const setSaveModalOpen = (state, isSaveModalOpen) => ({
  ...state,
  isSaveModalOpen,
});
const setShareModalOpen = (state, isShareModalOpen) => ({
  ...state,
  isShareModalOpen,
});
const setOverwriteConfirmModalOpen = (state, isOverwriteConfirmModalOpen) => ({
  ...state,
  isOverwriteConfirmModalOpen,
});
const setIsUnsubscribingFromAlert = (state, isUnsubscribingFromAlert) => ({
  ...state,
  isUnsubscribingFromAlert,
});
const setIsUnsubscribedFromAlert = (state, isUnsubscribedFromAlert) => ({
  ...state,
  isUnsubscribedFromAlert,
});
const setCurrentPage = (state, currentPage) => ({
  ...state,
  currentPage,
});
const loadResultsData = (state, data) => ({
  ...state,
  results: data.items,
  totalResults: data.total,
});
const updateResultsData = (state, savedSearch) => ({
  ...state,
  results: state.results.map((item) => {
    if (item.id === savedSearch.id) {
      return savedSearch;
    }
    return item;
  }),
});
const removeItemFromResults = (state, id) => {
  return {
    ...state,
    results: state.results.filter((item) => item.id !== id),
    totalResults: state.totalResults - 1,
  };
};
const setIsSaving = (state, isSaving) => ({
  ...state,
  isSaving,
});
const setSaveStatus = (state, saveStatus) => ({
  ...state,
  saveStatus,
});
const setDeleteStatus = (state, deleteStatus) => ({
  ...state,
  deleteStatus,
});

const initialState = {
  isDeleteModalOpen: false,
  isSaveModalOpen: false,
  isEditModalOpen: false,
  isOverwriteConfirmModalOpen: false,
  isLoadingResults: true,
  isUnsubscribingFromAlert: false,
  isUnsubscribedFromAlert: false,
  currentPage: 0,
  totalResults: 0,
  results: [],
  savedSearchResult: {},
  isSaving: false,
  saveStatus: null,
  deleteStatus: null,
};

/* Reducers */
export const savedSearchesReducer = createReducer(
  {
    [savedSearchesActions.setIsLoadingResults]: setIsLoadingResults,
    [savedSearchesActions.loadResultsData]: loadResultsData,
    [savedSearchesActions.updateResultsData]: updateResultsData,
    [savedSearchesActions.setDeleteModalOpen]: setDeleteModalOpen,
    [savedSearchesActions.setEditModalOpen]: setEditModalOpen,
    [savedSearchesActions.setSaveModalOpen]: setSaveModalOpen,
    [savedSearchesActions.setShareModalOpen]: setShareModalOpen,
    [savedSearchesActions.setOverwriteConfirmModalOpen]: setOverwriteConfirmModalOpen,
    [savedSearchesActions.setCurrentPage]: setCurrentPage,
    [savedSearchesActions.removeItemFromResults]: removeItemFromResults,
    [savedSearchesActions.setIsUnsubscribingFromAlert]: setIsUnsubscribingFromAlert,
    [savedSearchesActions.setIsUnsubscribedFromAlert]: setIsUnsubscribedFromAlert,
    [savedSearchesActions.setActiveSavedSearch]: setActiveSavedSearch,
    [savedSearchesActions.clearActiveSavedSearch]: clearActiveSavedSearch,
    [savedSearchesActions.setIsSaving]: setIsSaving,
    [savedSearchesActions.setSaveStatus]: setSaveStatus,
    [savedSearchesActions.setDeleteStatus]: setDeleteStatus,
  },
  initialState,
);
