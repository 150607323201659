import { toast } from 'react-toastify';
import ToastContent from './ToastContent';
import SnackBarContainer from './SnackBarContainer';

const SnackBar = {
  Container: SnackBarContainer,
  Toast: toast,
  ToastContent: ToastContent,
};

export default SnackBar;
