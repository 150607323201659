import { call, select, takeEvery, takeLatest, put } from 'redux-saga/lib/effects.js';

import { apiGet, apiDelete, apiPut, apiPost } from '../../services/requests.ts';
import { usersRolesActions } from '../dispatchers/usersRoles.js';
import { pageActions } from '../dispatchers/page.js';
import { getDefaultAlertOptions } from '@fi/util/getDefaultAlertOptions';
import { LOCALE, USER_ROLES_TITLES, DEFAULT_USER_ROLE } from '@fi/constants';
import { getAccounts } from '../../services/api/accounts.ts';
import { showNotification } from '@fi/components/Notify';

const ERROR_ALERT_OPTIONS = {
  ...getDefaultAlertOptions('usersRoles', LOCALE.ERRORS.ERROR_OCCURRED_TRY_LATER),
  timeout: 4000,
};

function* doFetchAccounts() {
  // Request all accounts ONLY for the ALMIGHTY role
  try {
    yield put(usersRolesActions.setIsFetchingAccounts(true));
    const accounts = yield getAccounts();
    // set the selected account the default one
    const currentAccount = yield select((state) => state.user.userData.account);
    yield put(usersRolesActions.selectAccount(currentAccount));
    yield put(usersRolesActions.loadAccounts(accounts));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.setIsFetchingAccounts(false));
  }
}

function* doResetAccount() {
  yield put(usersRolesActions.selectAccount(null));
}

function* loadUsersList() {
  // Request all users from backend
  try {
    yield put(usersRolesActions.setIsLoadingUsers(true));
    const users = yield call(apiGet, '/api/account/user-roles');
    yield put(usersRolesActions.setUsersRolesList(users));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.setIsLoadingUsers(false));
  }
}

function* doRunSearch(action) {
  try {
    const { keyword } = action.payload;

    yield put(usersRolesActions.setIsLoadingSearchResults(true));
    yield put(usersRolesActions.clearSearchResult());
    yield put(usersRolesActions.setNotFound(false));
    yield put(usersRolesActions.setSearchError(false));

    let url = `/api/user/search/${keyword}`;
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    if (selectedAccount) {
      url = `/api/admin/user/search/${keyword}?accountId=${selectedAccount.id}`;
    }

    const result = yield call(apiGet, url);
    yield put(usersRolesActions.setSearchUsersResults(result));

    // Check if found user is in store or not
    const userRole = yield select((state) => state.usersRoles.roles.byUserId[result.id]);
    if (!userRole) {
      const response = {
        id: null,
        accountId: null,
        user: {
          id: result.id,
          firstName: result.firstName,
          lastName: result.lastName,
          email: keyword,
          phone: null,
        },
        role: result.role || DEFAULT_USER_ROLE,
      };

      yield put(usersRolesActions.setUsersRolesList([response]));
    }
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(usersRolesActions.setNotFound(true));
    } else {
      yield put(usersRolesActions.setSearchError(true));
    }
  } finally {
    yield put(usersRolesActions.setIsLoadingSearchResults(false));
  }
}

function* doAssignRole(action) {
  const { userId, role } = action.payload;

  try {
    yield put(usersRolesActions.setUpdatingUserId(userId));

    const userRole = yield select((state) => state.usersRoles.roles.byUserId[userId]);
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    // Check if user has role assigned and make appropriate call (update and assign)
    if (userRole.id && userRole.role !== DEFAULT_USER_ROLE) {
      // If user role exists - update existing record

      if (selectedAccount) {
        // If it's ALMIGHTY, update this role for the selected account
        yield call(apiPut, `/api/admin/account/user-roles/${userRole.id}`, {
          accountId: selectedAccount.id,
          userId,
          role,
        });
      } else {
        yield call(apiPut, `/api/account/user-roles/${userRole.id}`, {
          userId,
          role,
        });
      }
      yield put(usersRolesActions.setUserRole(action.payload));
    } else {
      let response = null;
      // If it's ALMIGHTY, add this role for the selected account
      if (selectedAccount) {
        response = yield call(apiPost, `/api/admin/account/user-roles?accountId=${selectedAccount.id}`, {
          accountId: selectedAccount.id,
          userId,
          role,
        });
      } else {
        response = yield call(apiPost, '/api/account/user-roles', {
          userId,
          role,
        });
      }

      // Add to store as this user role is not stored
      yield put(usersRolesActions.setUsersRolesList([response]));
    }

    const roleTitle = USER_ROLES_TITLES.find((item) => item.value === role);

    yield call(showNotification, {
      message: (
        <>
          {roleTitle.label} role has been assigned to{' '}
          <strong>
            {userRole.user.lastName}, {userRole.user.firstName}
          </strong>
        </>
      ),
    });

    yield put(
      usersRolesActions.setChangeRoleStatus({
        type: 'ASSIGN',
        success: true,
      }),
    );
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.setRedirectAfterUpdate(false));
    yield put(usersRolesActions.removeUpdatingUserId(userId));
  }
}

function* doChangeRole(action) {
  const { accountId, user, newRole } = action.payload;
  yield put(usersRolesActions.resetChangeRoleStates());

  try {
    yield put(usersRolesActions.setIsLoadingChangeRole(true));

    let response = 'No response';
    if (user?.role) {
      response = yield call(apiPut, `/api/account/user-roles/${accountId}`, {
        userId: user.id,
        role: newRole,
      });
      yield put(usersRolesActions.setSuccessChangeRole(response.data));
    }
  } catch (e) {
    yield put(usersRolesActions.setFailureChangeRole(e?.message));
  } finally {
    yield put(usersRolesActions.setIsLoadingChangeRole(false));
  }
}

function* doRemoveRole(action) {
  const { roleId, userId } = action.payload;

  try {
    yield put(usersRolesActions.setUpdatingUserId(userId));

    const user = yield select((state) => state.usersRoles.roles.byUserId[userId]);
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    let url = `/api/account/user-roles/${roleId}`;
    // If it's ALMIGHTY, remove this role for the selected account
    if (selectedAccount) {
      url = `/api/admin/account/user-roles/${roleId}`;
    }
    yield call(apiDelete, url);

    yield put(
      usersRolesActions.setUserRole({
        userId,
        role: DEFAULT_USER_ROLE,
      }),
    );

    yield call(showNotification, {
      message: (
        <>
          Roles have been removed for{' '}
          <strong>
            {user.user.lastName}, {user.user.firstName}
          </strong>
        </>
      ),
    });

    yield put(
      usersRolesActions.setChangeRoleStatus({
        type: 'REMOVE',
        success: true,
      }),
    );
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.setRedirectAfterUpdate(false));
    yield put(usersRolesActions.removeUpdatingUserId(userId));
  }
}

export function* doGetAccountContactPerson() {
  try {
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    let contactPerson = null;
    // If it's ALMIGHTY and account is selected
    // fetch the contact detail for that account
    if (selectedAccount) {
      contactPerson = yield call(apiGet, `/api/admin/account/contact-details?accountId=${selectedAccount.id}`);
    } else {
      contactPerson = yield call(apiGet, '/api/account/contact-details');
    }

    yield put(usersRolesActions.storeContactPerson(contactPerson));
  } catch (e) {
    if (e.status && e.status === 404) {
      // Contact person is not set, clear value in store
      yield put(usersRolesActions.storeContactPerson(null));
    } else {
      yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
    }
  }
}

function* doChangeAccount(action) {
  const { account } = action.payload;
  // change current account ONLY for the ALMIGHTY role
  try {
    yield put(usersRolesActions.setIsLoadingUsers(true));
    yield put(usersRolesActions.selectAccount(account));
    yield call(doGetAccountContactPerson);
    const users = yield call(apiGet, `/api/admin/account/user-roles?accountId=${account.id}`);
    yield put(usersRolesActions.resetUsersRolesList(users));
    yield put(usersRolesActions.setSearchError(false));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.setIsLoadingUsers(false));
  }
}

function* doSaveAccountContactPerson(action) {
  try {
    const { userId, additionalInformation } = action.payload;

    yield put(usersRolesActions.setIsUpdatingContactPerson(true));
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    let contactPerson = null;
    // If it's ALMIGHTY and account is selected
    // assign the contact information for that account
    if (selectedAccount) {
      contactPerson = yield call(apiPost, `/api/admin/account/contact-details?accountId=${selectedAccount.id}`, {
        userId,
        additionalInformation,
      });
    } else {
      contactPerson = yield call(apiPost, '/api/account/contact-details', {
        userId,
        additionalInformation,
      });
    }
    yield put(usersRolesActions.storeContactPerson(contactPerson));
    yield call(showNotification, {
      message: 'Contact person preferences saved.',
    });
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.closeModal());
    yield put(usersRolesActions.setIsUpdatingContactPerson(false));
  }
}

function* doDeleteAccountContactPerson() {
  try {
    yield put(usersRolesActions.setIsUpdatingContactPerson(true));
    const selectedAccount = yield select((state) => state.usersRoles.accounts.selected);
    // If it's ALMIGHTY and account is selected
    // delete the contact information for that account
    if (selectedAccount) {
      yield call(apiDelete, `/api/admin/account/contact-details?accountId=${selectedAccount.id}`);
    } else {
      yield call(apiDelete, '/api/account/contact-details');
    }
    yield put(usersRolesActions.storeContactPerson(null));
    yield call(showNotification, {
      message: 'Contact person preferences saved.',
    });
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.closeModal());
    yield put(usersRolesActions.setIsUpdatingContactPerson(false));
  }
}

function* doChangeContactPersonRole(action) {
  try {
    const { userId, action: roleAction, newRole, roleId } = action.payload;

    if (roleAction === 'CHANGE_ROLE') {
      yield put(
        usersRolesActions.assignRole({
          userId,
          role: newRole,
        }),
      );
    } else if (roleAction === 'REMOVE_ROLE') {
      yield put(
        usersRolesActions.removeRole({
          userId,
          roleId,
        }),
      );
    }
    // remove contact person information in our store
    yield put(usersRolesActions.storeContactPerson(null));
  } catch (e) {
    yield put(pageActions.createAlert(ERROR_ALERT_OPTIONS));
  } finally {
    yield put(usersRolesActions.closeModal());
  }
}

export default function* usersRolesSaga() {
  yield takeLatest(usersRolesActions.runSearch, doRunSearch);
  yield takeLatest(usersRolesActions.loadUsers, loadUsersList);
  yield takeEvery(usersRolesActions.assignRole, doAssignRole);
  yield takeEvery(usersRolesActions.changeRole, doChangeRole);
  yield takeEvery(usersRolesActions.removeRole, doRemoveRole);
  yield takeEvery(usersRolesActions.fetchAccounts, doFetchAccounts);
  yield takeEvery(usersRolesActions.changeAccount, doChangeAccount);
  yield takeEvery(usersRolesActions.resetAccount, doResetAccount);
  yield takeEvery(usersRolesActions.getAccountContactPerson, doGetAccountContactPerson);
  yield takeEvery(usersRolesActions.saveAccountContactPerson, doSaveAccountContactPerson);
  yield takeEvery(usersRolesActions.deleteAccountContactPerson, doDeleteAccountContactPerson);
  yield takeEvery(usersRolesActions.changeContactPersonRole, doChangeContactPersonRole);
}
