import { takeEvery, put, call } from 'redux-saga/lib/effects.js';
import { groupsActions } from '../dispatchers/groups.js';
import { apiDelete, apiGet } from '../../services/requests.ts';

const apiUrl = '/api/groups';

function* doFetchGroupList() {
  try {
    yield put(groupsActions.setIsLoadingList(true));
    const groups = yield call(apiGet, apiUrl);
    yield put(
      groupsActions.setList({
        groups,
      }),
    );
    yield put(
      groupsActions.setFetchingListStatus({
        status: true,
      }),
    );
  } catch (e) {
    yield put(
      groupsActions.setFetchingListStatus({
        status: false,
      }),
    );
  } finally {
    yield put(groupsActions.setIsLoadingList(false));
  }
}

function* deleteGroup(action) {
  const id = action.payload;
  try {
    put(groupsActions.setIsDeletingGroup(true));
    yield call(apiDelete, `${apiUrl}/${id}`);
    yield put(
      groupsActions.setDeleteGroupStatus({
        status: true,
      }),
    );
    put(groupsActions.setIsDeletingGroup(false));
    yield doFetchGroupList();
  } catch (e) {
    yield put(
      groupsActions.setDeleteGroupStatus({
        status: false,
      }),
    );
    put(groupsActions.setIsDeletingGroup(false));
  }
}

export default function* groupSaga() {
  yield takeEvery(groupsActions.fetchList, doFetchGroupList);
  yield takeEvery(groupsActions.deleteGroup, deleteGroup);
}
