import { takeEvery, call, put } from 'redux-saga/lib/effects.js';

import { shareActions } from '../dispatchers/share.js';
import { logActions } from '../dispatchers/log.js';
import { apiPost, apiGet } from '../../services/requests.ts';
import { displayErrorAlert, displayInfoAlert } from './alerts.js';
import { LOCALE, EVENT_NAMES } from '@fi/constants';
import { selectAllActions } from '../dispatchers/selectAll.js';

function* shareByEmail(action) {
  const data = action.payload;

  try {
    yield put(shareActions.setError(false));
    yield put(shareActions.setIsShared(false));
    yield put(shareActions.setIsSharing(true));
    yield call(apiPost, '/api/user/share/opportunities', data);

    yield put(
      logActions.sendEvent({
        eventName: EVENT_NAMES.CONTENT_SHARE,
        opportunityIds: data.opportunityIds,
      }),
    );

    const content = data.opportunityIds.length > 1 ? 'The opportunities have been shared.' : 'The opportunity has been shared.';
    yield call(displayInfoAlert, 'share', content);

    yield put(selectAllActions.deselectAllOpportunities());
    yield put(shareActions.setIsShared(true));

    // close share modal rendered by ModalRoot
    yield put(shareActions.closeModal());
  } catch (e) {
    yield call(displayErrorAlert, 'share', LOCALE.ERRORS.SHARE_ERROR);
    yield put(shareActions.setError(true));
  } finally {
    yield put(shareActions.setIsSharing(false));
  }
}

function* fetchSharedEmailsByOpportunity(action) {
  const opportunityId = action.payload;
  try {
    const sharedList = yield call(apiGet, `/api/user/share/opportunities/${opportunityId}`);
    yield put(shareActions.loadSharedList(sharedList));
  } catch (e) {
    yield put(shareActions.loadSharedList([]));
  }
}

export default function* shareSaga() {
  yield takeEvery(shareActions.shareOpportunitiesByEmail, shareByEmail);
  yield takeEvery(shareActions.fetchSharedList, fetchSharedEmailsByOpportunity);
}
