import { takeLatest, call, put } from 'redux-saga/lib/effects.js';

import { apiGet, apiPost } from '../../services/requests.ts';
import { recommendationModalActions } from '../dispatchers/recommendationModal.js';
import { recommendationsActions } from '../dispatchers/recommendations.js';

import { objectUrlSerialization } from '@fi/util/objectUrlSerialization';

function* doFetchPublications(action) {
  const { id } = action.payload;
  const url = `/api/recommendation-entities/publications?authorid=${id}&start=0&count=5`;
  try {
    yield put(
      recommendationModalActions.setIsFetchingPublications({
        isFetching: true,
        id,
      }),
    );
    const publications = yield call(apiGet, url);
    yield put(
      recommendationModalActions.setPublications({
        id,
        publications: publications.entries,
      }),
    );
    yield put(
      recommendationModalActions.setFetchingStatusPublications({
        status: true,
        id,
      }),
    );
  } catch (e) {
    yield put(
      recommendationModalActions.setFetchingStatusPublications({
        status: false,
      }),
    );
  } finally {
    yield put(
      recommendationModalActions.setIsFetchingPublications({
        isFetching: false,
        id: null,
      }),
    );
  }
}

function getScopusProfileParams(payload) {
  const { affiliation, authorid, firstname, lastname, orcid, searchBy } = payload;

  switch (searchBy) {
    case 'name': {
      return {
        firstname,
        lastname,
        affiliation,
      };
    }
    case 'authorid': {
      return {
        authorid,
      };
    }
    case 'orcid': {
      return {
        orcid,
      };
    }
    default: {
      return null;
    }
  }
}

function* doFetchScopusProfiles(action) {
  const params = getScopusProfileParams(action.payload);
  const url = `/api/recommendation-entities/authors${objectUrlSerialization({
    ...params,
    maxrecords: 20,
  })}`;
  try {
    yield put(recommendationModalActions.setIsFethingProfiles(true));
    yield put(recommendationModalActions.setProfiles(null));
    const scopusProfiles = yield call(apiGet, url);
    const { status } = scopusProfiles;
    if (status && status.code !== 'OK') {
      yield put(
        recommendationModalActions.setFetchingStatus({
          status: false,
          code: status.code,
        }),
      );
    } else {
      yield put(recommendationModalActions.setProfiles(scopusProfiles.entries));
      yield put(
        recommendationModalActions.setFetchingStatus({
          status: true,
        }),
      );
      yield put(recommendationModalActions.setSearchForm(action.payload));
    }
  } catch (e) {
    yield put(
      recommendationModalActions.setFetchingStatus({
        status: false,
        code: 'EMPTY',
      }),
    );
  } finally {
    yield put(recommendationModalActions.setIsFethingProfiles(false));
  }
}

function* doCreateRecommendation(action) {
  const {
    profile: { id },
    filteringCriteria,
    entityId,
  } = action.payload;
  const url = '/api/user/recommendation-entities';
  try {
    yield put(recommendationModalActions.setIsCreatingRecommendation(true));
    // reset the search
    yield put(recommendationsActions.setSearchKey(''));
    const data = yield call(apiPost, url, {
      authorId: id,
      filteringCriteria,
      id: entityId,
    });
    yield put(
      recommendationModalActions.setCreateRecommendationStatus({
        status: true,
        id: data.id,
      }),
    );
    yield put(recommendationsActions.fetch());
  } catch (e) {
    yield put(
      recommendationModalActions.setCreateRecommendationStatus({
        status: false,
      }),
    );
  } finally {
    yield put(recommendationModalActions.setIsCreatingRecommendation(false));
  }
}

function* doFetchAuthorHints(action) {
  try {
    const url = `/api/recommendation-entities/authors/${action.payload}/hints`;
    const authorHints = yield call(apiGet, url);
    yield put(
      recommendationModalActions.setAuthorHints({
        locations: authorHints.locations,
      }),
    );
  } catch (e) {
    yield put(
      recommendationModalActions.setAuthorHints({
        locations: [],
      }),
    );
  }
}

export default function* recommendationModalSaga() {
  yield takeLatest(recommendationModalActions.fetchScopusProfiles, doFetchScopusProfiles);
  yield takeLatest(recommendationModalActions.fetchProfilePublications, doFetchPublications);
  yield takeLatest(recommendationModalActions.createRecommendation, doCreateRecommendation);
  yield takeLatest(recommendationModalActions.fetchAuthorHints, doFetchAuthorHints);
}
