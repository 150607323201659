import React from 'react';
import classnames from 'classnames';
import { LinkContentProps } from './LinkInterfaces';
import { Icon } from '@elsevier/graphene';

const LinkContent = ({
  icon,
  iconPosition,
  iconClassName,
  type,
  children,
  isExternal,
  showExternalLinkIcon,
  isSelected,
}: LinkContentProps) => (
  <span className="fca-link__content" tabIndex={-1}>
    {!!icon && iconPosition === 'left' && <span className={classnames('fca-link__icon fca-link__icon--left', iconClassName)}>{icon}</span>}

    {React.Children.count(children) > 0 && (
      <span
        className={classnames('fca-link__label', {
          'fca-link__label--secondary': type === 'secondary',
          'fca-link__label--black': type === 'black',
          'fca-link__label--selected': isSelected,
        })}
      >
        {children}
      </span>
    )}

    {!isExternal && !!icon && iconPosition === 'right' && (
      <span className={classnames('fca-link__icon fca-link__icon--right', iconClassName)}>{icon}</span>
    )}
    {isExternal && showExternalLinkIcon && (
      <span className="fca-link__icon fca-link__icon--right">
        <Icon name="up-right" />
      </span>
    )}
  </span>
);

export default LinkContent;
