import { takeEvery, select } from 'redux-saga/lib/effects.js';
import { logActions } from '../../dispatchers/log.js';
import { savedSearchesActions } from '../../dispatchers/savedSearches.js';
import { opportunitiesActions } from '../../dispatchers/opportunities.js';
import { awardsActions } from '../../dispatchers/awards.js';
import { fundersActions } from '../../dispatchers/funders.js';
import { internalOpportunitiesActions } from '../../dispatchers/internalOpportunities.js';

import { EVENT_NAMES, SEARCH_CATEGORIES } from '@fi/constants';
import sendPresentationLog from './sendPresentationLog.js';
import { getRunSearchParams, getSavedSearchParams } from '@fi/util/adobeAnalytics/logsUtils';
import { sendAnalyticsEvent, sendAnalyticsPageView } from '@fi/util/adobeAnalytics/adobeAnalytics';
import { MODALS } from '@fi/util/adobeAnalytics/adobeAnalyticsPageTree';

// this is something we need to move out from redux and sagas and rename the methods.
export const AdobeAnalyticsMapping = {
  SAVED_SEARCH_MODAL: () => {
    sendAnalyticsPageView(MODALS.SEARCH.SAVE_SEARCH);
  },
  EXPORT_FILE: ({ action }) => {
    const { opportunityIds, format, type } = action.payload;
    sendAnalyticsEvent('contentExport', {
      content: [
        {
          id: `[${opportunityIds[0].toString()}]`,
          type: type || 'funding opportunity',
          exportType: 'export_file',
          format: `MIME-${format}`,
          detail: '',
        },
      ],
      export: {
        rows: `${opportunityIds.length}`,
      },
    });
  },
  CLICK_ANNOUNCEMENT_LINK: ({ action }) => {
    sendAnalyticsEvent('linkOut', {
      content: [
        {
          id: action.payload.id,
          linkOut: `external:${action.payload.linkOut}`,
        },
      ],
      link: {
        name: action.payload.name,
        type: action.payload.type,
      },
    });
  },
  /**
   * Called in doFetchOpportunties
   * Whenever a search results page is loaded, this functions logs it to Adobe
   */
  *RUN_SEARCH({ params }) {
    const resource = yield select((state) => state[params.resourceName]);
    const AdobeCatalystParams = getRunSearchParams({
      searchQuery: resource.searchQuery,
      totalResults: resource.totalResults,
      resourceName: params.resourceName,
    });

    sendAnalyticsEvent('searchResultsUpdated', {
      search: AdobeCatalystParams,
    });
  },
  clickAwardsAnalyticsDashboard: (data) => {
    sendAnalyticsEvent('searchResultsUpdated', {
      link: data,
    });
  },
  CLICK_ON_SEARCH_RESULT: ({ action }) => {
    const { id, cardIndex, title } = action.payload;

    sendAnalyticsEvent('searchResultsClick', {
      content: [
        {
          id: id.toString(), // id and title MUST be a string, otherwise AA events are not sent for some reason
          title: title.toString(),
        },
      ],
      search: {
        resultsPosition: cardIndex.toString(),
      },
    });
  },
  *SAVE_SEARCH({ action }) {
    const { searchQuery } = yield select((state) => state[action.payload.resourceName]);
    const AdobeCatalystParams = getSavedSearchParams({
      searchQuery,
    });
    sendAnalyticsEvent('saveSearch', {
      content: [
        {
          id: `searchid:${action.payload.searchid}`,
        },
      ],
      search: AdobeCatalystParams,
    });
  },
  CONTENT_SHARE: ({ action }) => {
    const { opportunityIds } = action.payload;
    const content = opportunityIds.map((opportunityid) => ({
      id: `[opportunityid:${opportunityid}]`,
      sharePlatform: '[email]',
    }));
    sendAnalyticsEvent('contentShare', {
      content,
    });
  },
  SAVE_TO_LIST: ({ action }) => {
    const { opportunityIds } = action.payload;
    const content = opportunityIds.map((opportunityid) => ({
      id: `[opportunityid:${opportunityid}]`,
    }));
    sendAnalyticsEvent('saveToList', {
      content,
    });
  },
  REMOVE_FROM_MY_LIST: ({ action }) => {
    const { opportunityIds } = action.payload;
    const content = opportunityIds.map((opportunityid) => ({
      id: `[opportunityid:${opportunityid}]`,
    }));
    sendAnalyticsEvent('removeFromMyList', {
      content,
    });
  },
  ADD_INTERNAL_OPPORTUNITY: ({ action }) => {
    const { id, status } = action.payload;

    sendAnalyticsEvent('contentAddition', {
      content: [
        {
          id: `internalOpportunity:${id}`,
          status,
        },
      ],
    });
  },
  EDIT_INTERNAL_OPPORTUNITY: ({ action }) => {
    const { id, status } = action.payload;
    sendAnalyticsEvent('contentEdit', {
      content: [
        {
          id: `internalOpportunity:${id}`,
          status,
        },
      ],
    });
  },
  DELETE_INTERNAL_OPPORTUNITY: ({ action }) => {
    const { opportunityId: id } = action.payload;
    sendAnalyticsEvent('contentDeletion', {
      content: [
        {
          id: `internalOpportunity:${id}`,
        },
      ],
    });
  },

  CLICK_SUGGESTION: ({ action }) => {
    // https://confluence.cbsels.com/pages/viewpage.action?pageId=109099208
    const { opportunityId, position, recommendationToken, isFromTitle } = action.payload;

    sendAnalyticsEvent('recommendationClick', {
      content: [
        {
          id: opportunityId.toString(),
          id2: recommendationToken.toString(), // this is the recommendation ID, needed for the feedback loop into recommendation systems
          type: 'fi:suggestions', // e.g. "md:profile" this is the type of the content recommended, e.g. a Mendeley profile
          position: position.toString(), // the position in an ordered list starting with 1
          detail: isFromTitle ? 'fi:method:title' : 'fi:method:readmore',
        },
      ],
    });
  },
  CLICK_SIMILAR_OPPORTUNITY: ({ action }) => {
    // https://confluence.cbsels.com/pages/viewpage.action?pageId=109099208
    const { opportunityId, position, isFromTitle } = action.payload;

    sendAnalyticsEvent('recommendationClick', {
      content: [
        {
          id: opportunityId.toString(),
          type: 'fi:similar-opportunity', // e.g. "md:profile" this is the type of the content recommended, e.g. a Mendeley profile
          position: position.toString(), // the position in an ordered list starting with 1
          detail: isFromTitle ? 'fi:method:title' : 'fi:method:readmore',
        },
      ],
      eventName: 'recommendationClick',
    });
  },
  CONTENT_EXPORT_START_PURE: ({ action }) => {
    const { opportunityIds, isSuggestion } = action.payload;
    sendAnalyticsEvent('contentExportStart', {
      content: [
        {
          id: `[${opportunityIds.toString()}]`,
          type: isSuggestion ? 'fi:suggestions' : 'funding opportunity',
          exportType: 'pure',
          format: 'MIME-CSV',
        },
      ],
      export: {
        rows: `${opportunityIds.length}`,
      },
    });
  },
  CONTENT_EXPORTED_TO_PURE: ({ action }) => {
    const { opportunityIds, isSuggestion } = action.payload;
    sendAnalyticsEvent('contentExport', {
      content: [
        {
          id: `[${opportunityIds.toString()}]`,
          type: isSuggestion ? 'fi:suggestions' : 'funding opportunity',
          exportType: 'pure',
          format: 'MIME-CSV',
        },
      ],
      export: {
        rows: `${opportunityIds.length}`,
      },
    });
  },
};

function* dispatchLogEvent(params, action) {
  const eventName = params ? params.eventName : action.payload.eventName;
  const eventDispatcher = AdobeAnalyticsMapping[eventName];
  if (eventDispatcher) {
    yield eventDispatcher({ params, action });
  }
}

export default function* logSaga() {
  // send event for specific action
  yield takeEvery(logActions.sendEvent, dispatchLogEvent, null);
  yield takeEvery(savedSearchesActions.setSaveModalOpen, dispatchLogEvent, {
    eventName: EVENT_NAMES.SAVED_SEARCH_MODAL,
  });
  yield takeEvery(opportunitiesActions.loadOpportunities, dispatchLogEvent, {
    eventName: EVENT_NAMES.RUN_SEARCH,
    resourceName: SEARCH_CATEGORIES.OPPORTUNITIES,
  });
  yield takeEvery(fundersActions.loadFunders, dispatchLogEvent, {
    eventName: EVENT_NAMES.RUN_SEARCH,
    resourceName: SEARCH_CATEGORIES.FUNDERS,
  });
  yield takeEvery(awardsActions.loadAwardResults, dispatchLogEvent, {
    eventName: EVENT_NAMES.RUN_SEARCH,
    resourceName: SEARCH_CATEGORIES.AWARDS,
  });
  yield takeEvery(logActions.sendPresentationLog, sendPresentationLog);
  yield takeEvery(internalOpportunitiesActions.updateStatus, dispatchLogEvent, {
    eventName: EVENT_NAMES.EDIT_INTERNAL_OPPORTUNITY,
  });
  yield takeEvery(internalOpportunitiesActions.deleteOpportunity, dispatchLogEvent, {
    eventName: EVENT_NAMES.DELETE_INTERNAL_OPPORTUNITY,
  });
}
