import { all, call, put, select, takeLatest } from 'redux-saga/lib/effects.js';

import { replaceUrlParam } from '@fi/util/replaceUrlParam';
import { getUrlParameters } from '@fi/util/getUrlParameters';
import { PAGE_SIZE, LOCALE, MODALS } from '@fi/constants';

import { funderNotesActions } from '../dispatchers/funderNotes.js';
import { funderNoteModalActions } from '../dispatchers/funderNoteModal.js';
import { modalActions } from '../dispatchers/modal.js';
import { pageActions } from '../dispatchers/page.js';
import { apiGet, apiDelete, apiPost } from '../../services/requests.ts';
import { getFunderNote, getFunderNotes } from '../../services/api/funders.ts';
import { displayGeneralErrorAlert } from './alerts.js';
import { fundersActions } from '../dispatchers/funders.js';
import { showNotification } from '@fi/components/Notify';

function* searchFunderNotes(action) {
  try {
    yield put(funderNotesActions.setIsLoadingResults(true));

    const searchQuery = action && action.payload ? action.payload.searchQuery : null;
    const constantParams = `pageSize=${PAGE_SIZE}`;

    let query;
    if (searchQuery && typeof searchQuery === 'string') {
      query = `${searchQuery}&${constantParams}`;
    } else {
      query = `?${constantParams}`;
    }

    const urlParams = getUrlParameters(query);
    query = replaceUrlParam(query, 'page', parseInt(urlParams.page, 10) - 1);

    const funders = yield getFunderNotes(query);

    yield put(funderNotesActions.loadFunders(funders));
  } catch (e) {
    yield put(displayGeneralErrorAlert('search'));
  } finally {
    yield put(funderNotesActions.setIsLoadingResults(false));
  }
}

function* fetchNotesForFunder(funderId, throw404Error) {
  try {
    const funderNote = yield getFunderNote(funderId);

    if (throw404Error && !funderNote) {
      // Any race condition in the editor page is prevented by this error
      const error = new Error('Funder notes do not exist');
      error.name = 'Notes404';

      throw error;
    }

    yield put(
      fundersActions.setSingleFunderNote({
        funderId,
        funderNote,
      }),
    );
  } catch (e) {
    if (e.name === 'Notes404') {
      throw e;
    }

    yield call(displayGeneralErrorAlert, 'notes');
  }
}

function* saveFunderNote(noteData) {
  try {
    yield put(funderNoteModalActions.setIsSaving(true));

    const funderId = yield select((state) => state.funderNoteModal.funderId);

    if (funderId == null) {
      throw new Error('Funder id is not specified');
    }

    const payload = {
      additionalDescription: noteData.payload.additionalDescription,
      files: noteData.payload.fileIds.map((fileId) => ({ id: fileId })),
    };

    yield call(apiPost, `/api/funders/${funderId}/note`, payload);

    yield put(funderNoteModalActions.setNoteWasSavedSuccessfully(true));

    yield put(
      pageActions.createAlert({
        type: 'info',
        timeout: 4000,
        context: 'notes',
        content: 'Funder notes have been saved.',
      }),
    );
  } catch (e) {
    yield call(displayGeneralErrorAlert, 'notes');
  } finally {
    yield put(funderNoteModalActions.closeModal());
  }
}

function* deleteNoteForFunder(funderId) {
  yield call(apiDelete, `/api/funders/${funderId}/note`);

  const alertOptions = {
    type: 'info',
    timeout: 4000,
    context: 'notes',
    content: 'Funder notes have been deleted.',
  };

  yield put(pageActions.createAlert(alertOptions));
}

function* deleteNoteFromList(action) {
  const { funderId } = action.payload;
  try {
    yield put(funderNotesActions.setIsDeletingConfirmationModal(true));
    yield call(deleteNoteForFunder, funderId);
    yield put(funderNotesActions.removeNoteFromStore(funderId));
    yield put(
      funderNotesActions.setDeleteNoteStatus({
        success: true,
      }),
    );
    yield call(showNotification, { message: 'A Funder note has been deleted.' });
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.DELETE_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield call(displayGeneralErrorAlert, 'notes');
    }
  } finally {
    yield put(funderNotesActions.setIsDeletingConfirmationModal(false));
  }
}

function* deleteNoteFromDetailsPage(action) {
  const { payload: funderId } = action;

  try {
    yield put(fundersActions.setIsLoadingFunderDetails(true));

    yield all([
      yield call(deleteNoteForFunder, funderId),
      yield put(
        fundersActions.setSingleFunderNote({
          funderId,
          funderNote: null,
        }),
      ),
    ]);
  } catch (e) {
    if (e.status && e.status === 404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.DELETE_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield call(displayGeneralErrorAlert, 'notes');
    }
  } finally {
    yield put(fundersActions.setIsLoadingFunderDetails(false));
  }
}

function* checkOpportunitiesWithNotes(funderId) {
  if (!funderId) {
    return;
  }

  try {
    const opportunitiesNotesCount = yield call(apiGet, `/api/account/opportunity-notes/_count-for-funder?funderId=${funderId}`);

    const amountOfOpportunitiesWithNotes = opportunitiesNotesCount && opportunitiesNotesCount.count ? opportunitiesNotesCount.count : null;
    yield put(funderNoteModalActions.setOpportunitiesWithNotesAmount(amountOfOpportunitiesWithNotes));
  } catch (e) {
    yield put(funderNoteModalActions.setOpportunitiesWithNotesAmount(null));
  }
}

function* openFunderNoteModal(data) {
  const { funderId, showAlertOn404 = false } = data.payload;

  if (!funderId) {
    yield call(displayGeneralErrorAlert, 'notes');

    return;
  }

  try {
    yield put(funderNoteModalActions.setFetchingFunderNoteId(funderId));

    yield all([
      yield call(fetchNotesForFunder, funderId, showAlertOn404),
      yield call(checkOpportunitiesWithNotes, funderId),
      yield put(funderNoteModalActions.setFunderId(funderId)),
      yield put(
        modalActions.openModal({
          openModalType: MODALS.FUNDER_NOTE_MODAL,
        }),
      ),
    ]);
  } catch (e) {
    if (e.name === 'Notes404' && showAlertOn404) {
      yield put(
        modalActions.openModal({
          openModalType: MODALS.ERROR_MODAL,
          modalProps: {
            text: LOCALE.ERRORS.EDIT_DELETED_ENTITY,
          },
        }),
      );
    } else {
      yield call(displayGeneralErrorAlert, 'notes');
    }
  } finally {
    yield put(funderNoteModalActions.setFetchingFunderNoteId(null));
  }
}

export default function* fundersSaga() {
  yield takeLatest(funderNotesActions.fetchFunderNotes, searchFunderNotes);
  yield takeLatest(funderNotesActions.saveNote, saveFunderNote);
  yield takeLatest(funderNotesActions.deleteNoteFromList, deleteNoteFromList);
  yield takeLatest(funderNotesActions.deleteNoteFromDetailsPage, deleteNoteFromDetailsPage);
  yield takeLatest(funderNoteModalActions.openFunderNoteModal, openFunderNoteModal);
}
